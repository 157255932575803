<i18n>
{
  "en": {
    "title": "Map Summary",
    "summary_1": "This table displays the summary of all {0} maps. These maps combined contain {1} domains from {2} organizations.",
    "summary_2": "On average about {0}% domains and {1}% organizations pass all tests."
  },
  "nl": {
    "title": "Samenvatting kaarten",
    "summary_1": "Deze tabel toont de samenvatting van alle {0} kaarten. Samen bevatten deze kaarten {1} domeinen verdeeld over {2} organisaties.",
    "summary_2": "Gemiddeld slaagt ongeveer {0}% van alle domeinen en {1}% van alle organisaties voor alle tests."
  }
}

</i18n>

<template>
  <!-- Todo: No use to show this table with just one layer. -->
  <!-- todo: only count deduplicate organizations... -->
  <!-- todo: only when stats are enabled -->
  <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white" v-if="simplestats">

    <h2>{{ $t('title') }} </h2>
    <p>

      {{ $t('summary_1', [total_layers, total_domains, total_organizations]) }}<br>
      {{ $t('summary_2', [average_url_percentage_good.toFixed(0), average_organization_percentage_good.toFixed(0)]) }}

    </p>

    <!--
  Domains passing all tests:
      <b-progress class="mt-2 mb-2" show-value height="1rem">
        <b-progress-bar :value="average_url_percentage_good" variant="success">
          {{average_url_percentage_good.toFixed(0) }}%
        </b-progress-bar>
        <b-progress-bar :value="average_url_percentage_medium" variant="warning">
          {{ average_url_percentage_medium.toFixed(0) }}%
        </b-progress-bar>
        <b-progress-bar :value="average_url_percentage_bad"
                        variant="danger">{{
            average_url_percentage_bad.toFixed(0)
          }}%
        </b-progress-bar>
      </b-progress>


  Organizations passing all tests:
      <b-progress class="mt-2 mb-2" show-value height="1rem">
        <b-progress-bar :value="average_organization_percentage_good" variant="success">
          {{average_organization_percentage_good.toFixed(0) }}%
        </b-progress-bar>
        <b-progress-bar :value="average_organization_percentage_medium" variant="warning">
          {{ average_organization_percentage_medium.toFixed(0) }}%
        </b-progress-bar>
        <b-progress-bar :value="average_organization_percentage_bad"
                        variant="danger">{{
            average_organization_percentage_bad.toFixed(0)
          }}%
        </b-progress-bar>
      </b-progress>

-->

    <b-table striped hover responsive="sm" :items="stats_as_table" :fields="fields"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc" class="border-bottom border-left border-right"
    >

      <template #thead-top="">
        <b-tr>
          <b-th colspan="1"><span class="sr-only">&nbsp;</span></b-th>
          <b-th colspan="2" class="text-center border-left">{{ $t('organizations')[0].toUpperCase() + $t('organizations').slice(1) }}</b-th>
          <b-th colspan="2" class="text-center border-left">{{ $t('internet_adresses')[0].toUpperCase() + $t('internet_adresses').slice(1) }}</b-th>
        </b-tr>
      </template>


      <template #head(url_percentage_bad)="">
        {{ $i18n.t('score')[0].toUpperCase() + $i18n.t('score').slice(1) }}
      </template>

      <template #head(organization_percentage_bad)="">
        {{ $i18n.t('score')[0].toUpperCase() + $i18n.t('score').slice(1) }}
      </template>

      <template #head(organizations)="">
        {{ $i18n.t('amount') }}
      </template>

      <template #head(domains)="">
        {{ $i18n.t('amount') }}
      </template>

      <template #cell(country)="data">
        {{ $t(data.item.country) }}
      </template>

      <template #cell(layer)="data">
        <b style="cursor: pointer;"
           @click="navigateToMap(data.item.country, data.item.layer)">{{ $t(data.item.layer) }}</b>
      </template>

      <template #cell(organization_percentage_sort)="data">

        <b-progress class="mt-2 mb-2" show-value height="1rem">
          <b-progress-bar :value="data.item.organization_percentage_good" variant="success">
            {{ data.item.organization_percentage_good.toFixed(0) }}%
          </b-progress-bar>
          <b-progress-bar :value="data.item.organization_percentage_medium" variant="warning">
            {{ data.item.organization_percentage_medium.toFixed(0) }}%
          </b-progress-bar>
          <b-progress-bar :value="data.item.organization_percentage_bad"
                          variant="danger">{{
              data.item.organization_percentage_bad.toFixed(0)
            }}%
          </b-progress-bar>
        </b-progress>
      </template>

      <template #cell(url_percentage_sort)="data">

        <b-progress class="mt-2 mb-2" show-value height="1rem">
          <b-progress-bar :value="data.item.url_percentage_good" variant="success">
            {{ data.item.url_percentage_good.toFixed(0) }}%
          </b-progress-bar>
          <b-progress-bar :value="data.item.url_percentage_medium" variant="warning">
            {{ data.item.url_percentage_medium.toFixed(0) }}%
          </b-progress-bar>
          <b-progress-bar :value="data.item.url_percentage_bad"
                          variant="danger">{{
              data.item.url_percentage_bad.toFixed(0)
            }}%
          </b-progress-bar>
        </b-progress>
      </template>


      <template #head(layer)="">
        <span><b-icon icon="map"/> {{ $i18n.t('map') }}</span>
      </template>


    </b-table>
  </b-container>

</template>

<script>
export default {
  name: "SimpleStatsTable",
  props: {
    simplestats: {type: Object, mandatory: true}
  },
  data() {
    return {
      sortBy: 'organization_percentage_sort',
      sortDesc: true,

      fields: [
        {
          key: 'layer',
          sortable: true,
          label: this.$i18n.t('map'),
        },

        {
          key: 'organizations',
          sortable: true,
          label: this.$i18n.t('amount'),
          class: 'border-left text-center',

          // variant: 'primary',
        },
        {
          key: 'organization_percentage_sort',

          label: this.$i18n.t('score'),
          sortable: true,
          sortDirection: "asc",
          class: 'border-right text-center',
          // variant: 'primary',
        },
                {
          key: 'domains',
          sortable: true,
          label: this.$i18n.t('amount'),
                  class: 'border-left text-center',

          // variant: 'secondary',

        },

                {
          key: 'url_percentage_sort',

          label: this.$i18n.t('score'),
          sortable: true,
          sortDirection: "asc",
          class: 'border-right text-center',
          // variant: 'secondary',
        },




      ],

      total_domains: 0,
      total_organizations: 0,
      total_layers: 0,
      total_countries: 0,
      average_url_percentage_good: 0,
      average_url_percentage_medium: 0,
      average_url_percentage_bad: 0,
      average_organization_percentage_good: 0,
      average_organization_percentage_medium: 0,
      average_organization_percentage_bad: 0,

    }
  },
  methods: {
    average_out() {
      this.average_url_percentage_good = this.average_url_percentage_good / this.total_layers;
      this.average_url_percentage_medium = this.average_url_percentage_medium / this.total_layers;
      this.average_url_percentage_bad = this.average_url_percentage_bad / this.total_layers;
      this.average_organization_percentage_good = this.average_organization_percentage_good / this.total_layers;
      this.average_organization_percentage_medium = this.average_organization_percentage_medium / this.total_layers;
      this.average_organization_percentage_bad = this.average_organization_percentage_bad / this.total_layers;
    },
  },
  computed: {
    countries() {
      if (!this.simplestats)
        return []
      return Object.keys(this.simplestats);
    },
    stats_as_table() {

      if (!this.countries)
        return []

      let z = [];

      this.countries.forEach(country => {
        this.total_countries += 1;

        let layers = Object.keys(this.simplestats[country]);

        layers.forEach(layer => {
          let l = this.simplestats[country][layer];

          z.push({
            country: country,
            layer: layer,
            organizations: l['organizations'],
            domains: l['urls'],
            url_percentage_good: l['good url percentage'],
            url_percentage_medium: l['medium url percentage'],
            url_percentage_bad: l['high url percentage'],
            organization_percentage_good: l['good percentage'],
            organization_percentage_medium: l['medium percentage'],
            organization_percentage_bad: l['high percentage'],

            // a fake value to make sorting easier:
            organization_percentage_sort: l['good percentage'] * 100000 + l['medium percentage'] * 1000 + l['high percentage'],
            url_percentage_sort: l['good url percentage'] * 100000 + l['medium url percentage'] * 1000 + l['high url percentage'],

          });
          this.total_layers += 1

          this.total_organizations += l['organizations'];
          this.total_domains += l['urls'];
          this.average_url_percentage_good += l['good url percentage'];
          this.average_url_percentage_medium += l['medium url percentage'];
          this.average_url_percentage_bad += l['high url percentage'];
          this.average_organization_percentage_good += l['good percentage'];
          this.average_organization_percentage_medium += l['medium percentage'];
          this.average_organization_percentage_bad += l['high percentage'];
        })
      });

      this.average_out();

      return z;
    }
  },

}
</script>

<style>
.progress-bar {
  font-weight: bold;
  text-shadow: 1px 1px 3px var(--dark);
}
</style>
