<script>
import {mapState} from 'vuex'
export default {
  computed: mapState(['country', 'layer', 'week']),
  watch: {
      country: function(){
          this.load();
      },
      layer: function(){
          this.load();
      },
      week: function(){
          this.load();
      },
  }
};
</script>
