<template>
  <div class="pt-1 pb-4" role="main">
    <b-container>

      <h1 class="display-4">
        <b-icon icon="info-circle"/>
        {{ $t('title') }}
      </h1>
      <p class="lead">{{ $t('intro') }}</p>

      <template v-for="metric in metrics">
        <h2 v-html="print_new_header(metric.type)" v-if="change(metric.type)" :key="`header ${metric.id}`" class="mt-4"/>
        <ReportFinding  :key="metric.id"
                       :rating="metric"
                       :url="{'url': 'http://basisbeveiliging.nl', 'id': metric.id}"
                        style="border: 1px solid silver; border-radius: 8px;"
                        :class="`report_url_background_${metric.impact} p-3 mb-4`"
        />
      </template>

    </b-container>
  </div>
</template>
<script>

import TranslationsFromConfigMixin from "@/components/TranslationsFromConfigMixin";
import ReportFinding from "@/components/report/ReportFinding";

export default {
  components: {ReportFinding},
  mixins: [TranslationsFromConfigMixin],

  methods: {
    impact(label) {
      return {
        high: label === "high" ? 1 : 0,
        low: label === "low" ? 1 : 0,
        medium: label === "medium" ? 1 : 0,
        error_in_test: label === "error_in_test" ? 1 : 0,
        not_testable: label === "not_testable" ? 1 : 0,
        ok: label === "ok" ? 1 : 0
      }
    },

    x(label) {
      return Object.assign(this.impact(label), this.standard_data, {'impact': label})
    },

    change(type) {
      if (type !== this.current_header) {
        this.current_header = type;
        return true
      }
    },

    print_new_header(type) {
      return this.$i18n.t(type)
    }
  },

  data() {
    return {
      standard_data: {
        comply_or_explain_valid_at_time_of_report: false,
        comply_or_explain_explanation: 'explanation',
        since: new Date().toISOString(),
        last_scan: new Date().toISOString(),
        evidence: null,
      },
      // shorthand
      z: Object.assign,

      current_header: "",
    }
  },
  computed: {
    metrics() {
      let i = 1;
      return [

        this.z({id: i++, type: "web_privacy_third_party_requests", explanation: "web_privacy_third_party_found"}, this.x('low')),
        this.z({id: i++, type: "web_privacy_third_party_requests", explanation: "web_privacy_third_party_ok"}, this.x('ok')),
        this.z({id: i++, type: "web_privacy_tracking", explanation: "web_privacy_tracking_trackers_found"}, this.x('high')),
        this.z({id: i++, type: "web_privacy_tracking", explanation: "web_privacy_tracking_ok"}, this.x('ok')),


        this.z({id: i++, type: "internet_nl_web_rpki_exists", explanation: "passed", translation: "passed"}, this.x('ok')),
        this.z({id: i++, type: "internet_nl_web_rpki_exists", explanation: "warning", translation: "warning"}, this.x('high')),
        this.z({id: i++, type: "internet_nl_mail_rpki_exists", explanation: "passed", translation: "passed"}, this.x('ok')),
        this.z({id: i++, type: "internet_nl_mail_rpki_exists", explanation: "warning", translation: "warning"}, this.x('medium')),

        this.z({id: i++, type: "tls_qualys_encryption_quality", explanation: "Broken Transport Security, rated F"}, this.x('asdasdasd')),
        this.z({id: i++, type: "tls_qualys_encryption_quality", explanation: "Less than optimal Transport Security, rated C."}, this.x('asdasdasd')),
        this.z({id: i++, type: "tls_qualys_encryption_quality", explanation: "Less than optimal Transport Security, rated B."}, this.x('asdasdasd')),
        this.z({id: i++, type: "tls_qualys_encryption_quality", explanation: "Good Transport Security, rated A-."}, this.x('asdasdasd')),
        this.z({id: i++, type: "tls_qualys_encryption_quality", explanation: "Good Transport Security, rated A."}, this.x('asdasdasd')),
        this.z({id: i++, type: "tls_qualys_encryption_quality", explanation: "Perfect Transport Security, rated A+."}, this.x('asdasdasd')),
        this.z({id: i++, type: "tls_qualys_encryption_quality", explanation: "Error occured during scan. No score assigned."}, this.x('asdasdasd')),

        this.z({id: i++, type: "tls_qualys_certificate_trusted", explanation:"Certificate is not trusted."}, this.x('asdasdasd')),
        this.z({id: i++, type: "tls_qualys_certificate_trusted", explanation:"Certificate is trusted."}, this.x('asdasdasd')),
        this.z({id: i++, type: "tls_qualys_certificate_trusted", explanation:"Error occured during scan. No score assigned."}, this.x('asdasdasd')),

        this.z({id: i++, type: "whois_domain_ownership", explanation:"whois_internal_ownership"}, this.x('ok')),
        this.z({id: i++, type: "whois_domain_ownership", explanation:"whois_external_ownership"}, this.x('medium')),
        this.z({id: i++, type: "whois_domain_ownership", explanation:"whois_unknown_ownership"}, this.x('medium')),

        this.z({id: i++, type: 'dnssec', explanation: 'DNSSEC is incorrectly or not configured (errors found).'}, this.x('high')),
        this.z({id: i++, type: 'dnssec', explanation: 'DNSSEC seems to be implemented sufficiently.'}, this.x('ok')),

        this.z({id: i++, type: "ftp", explanation: "FTP Insecure"}, this.x('high')),
        this.z({id: i++, type: "ftp", explanation: "FTP Server only supports insecure SSL protocol."}, this.x('high')),
        this.z({id: i++, type: "ftp", explanation: "FTP Server does not configured to show if encryption is available."}, this.x('low')),
        this.z({id: i++, type: "ftp", explanation: "FTP Server supports TLS encryption protocol."}, this.x('high')),
        this.z({id: i++, type: "ftp", explanation: "FTP Server does not support encrypted transport or has protocol issues."}, this.x('high')),
        this.z({id: i++, type: "ftp", explanation: "An FTP connection could not be established properly. Not possible to verify encryption."}, this.x('low')),

        this.z({id: i++, type: "bannergrab",  explanation: "service_identity_likely_version_found"}, this.x('high')),
        this.z({id: i++, type: "bannergrab",  explanation: "service_identity_some_identity_found"}, this.x('low')),
        this.z({id: i++, type: "bannergrab",  explanation: "service_identity_nothing_found"}, this.x('ok')),
        this.z({id: i++, type: "bannergrab",  explanation: "service_identity_not_seen_in_a_while"}, this.x('ok')),

        this.z({id: i++, type: "ports",  explanation: "port_should_not_be_open"}, this.x('high')),
        this.z({id: i++, type: "ports",  explanation: "port_could_not_be_found_anymore"}, this.x('ok')),

        this.z({id: i++, type: "http_security_header_strict_transport_security", explanation: "present"}, this.x('adasdasd')),
        this.z({id: i++, type: "http_security_header_strict_transport_security", explanation: "missing"}, this.x('adasdasd')),
        this.z({id: i++, type: "http_security_header_strict_transport_security", explanation: "missing_but_no_insecure_service_offered"}, this.x('adasdasd')),
        this.z({id: i++, type: "http_security_header_strict_transport_security", explanation: "unreachable"}, this.x('adasdasd')),
        this.z({id: i++, type: "http_security_header_strict_transport_security", explanation: "non_http_response"}, this.x('adasdasd')),
        this.z({id: i++, type: "http_security_header_strict_transport_security", explanation: "soap"}, this.x('adasdasd')),
        this.z({id: i++, type: "http_security_header_strict_transport_security", explanation: "access_restricted"}, this.x('adasdasd')),

        this.z({id: i++, type: "http_security_header_x_content_type_options", explanation: "present"}, this.x('asdasdasd')),
        this.z({id: i++, type: "http_security_header_x_content_type_options", explanation: "missing"}, this.x('asdasdasd')),
        this.z({id: i++, type: "http_security_header_x_content_type_options", explanation: "unreachable"}, this.x('asdasdasd')),

        this.z({id: i++, type: "http_security_header_x_frame_options", explanation: "present"}, this.x('ok')),
        this.z({id: i++, type: "http_security_header_x_frame_options", explanation: "missing"}, this.x('low')),
        this.z({id: i++, type: "http_security_header_x_frame_options", explanation: "using_csp"}, this.x('ok')),
        this.z({id: i++, type: "http_security_header_x_frame_options", explanation: "unreachable"}, this.x('error_in_test')),
        this.z({id: i++, type: "http_security_header_x_frame_options", explanation: "non_http_response"}, this.x('error_in_test')),
        this.z({id: i++, type: "http_security_header_x_frame_options", explanation: "soap"}, this.x('ok')),
        this.z({id: i++, type: "http_security_header_x_frame_options", explanation: "access_restricted"}, this.x('error_in_test')),

        this.z({id: i++, type: "plain_http", explanation: "Site does not redirect to secure url, and has no secure alternative on a standard port."}, this.x('high')),
        this.z({id: i++, type: "plain_http", explanation: "Has a secure equivalent, which wasn't so in the past."}, this.x('ok')),
        this.z({id: i++, type: "plain_http", explanation: "Redirects to a secure site, while a secure counterpart on the standard port is missing."}, this.x('ok')),

        this.z({id: i++, type: "internet_nl_wsm_web_appsecpriv_securitytxt", explanation: "passed", translation: "passed"}, this.x('ok')),
        this.z({id: i++, type: "internet_nl_wsm_web_appsecpriv_securitytxt", explanation: "info", translation: "info"}, this.x('low')),
        this.z({id: i++, type: "internet_nl_wsm_web_appsecpriv_securitytxt", explanation: "missing_on_subdomain", translation: "missing_on_subdomain"}, this.x('low')),
        this.z({id: i++, type: "internet_nl_wsm_web_appsecpriv_securitytxt", explanation: "missing_on_domain", translation: "missing_on_domain"}, this.x('high')),

        this.z({id: i++, type: "internet_nl_mail_starttls_tls_available", explanation:"passed", translation: "passed"}, this.x('ok')),
        this.z({id: i++, type: "internet_nl_mail_starttls_tls_available", explanation:"failed", translation: "failed"}, this.x('high')),

        this.z({id: i++, type: "internet_nl_mail_auth_spf_exist", explanation: "passed", translation: "passed"}, this.x('ok')),
        this.z({id: i++, type: "internet_nl_mail_auth_spf_exist", explanation: "failed", translation: "failed"}, this.x('high')),

        this.z({id: i++, type: "internet_nl_mail_auth_dkim_exist", explanation: "passed", translation: "passed"}, this.x('ok')),
        this.z({id: i++, type: "internet_nl_mail_auth_dkim_exist", explanation: "failed", translation: "failed"}, this.x('high')),

        this.z({id: i++, type: "internet_nl_mail_auth_dmarc_exist", explanation: "passed", translation: "passed"}, this.x('ok')),
        this.z({id: i++, type: "internet_nl_mail_auth_dmarc_exist", explanation: "failed", translation: "failed"}, this.x('high')),



      ]
    }
  }
}
</script>
<i18n>
{
  "en": {
    "title": "Metrics",
    "intro": "This is an overview of all supported metrics and values. It is used to translate this software."
  },
  "nl": {
    "title": "Metingen",
    "intro": "Deze pagina geeft een overzicht van alle verschillende meetwaarden. Deze pagina wordt gebruikt voor het controleren van vertalingen."
  }
}

</i18n>
