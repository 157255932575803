// Note that there is a little hate going on with multiple vue instances.
// See here: https://github.com/LinusBorg/portal-vue/issues/201#issuecomment-484452281
// This explains why there are some extra definitions in the bundler thingies.
import Vue from 'vue'
import VueRouter from 'vue-router'

import 'leaflet/dist/leaflet.css';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';


import PortalVue from 'portal-vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {format, formatDistanceToNow, parseISO} from 'date-fns'
import {enGB, nl} from 'date-fns/locale'
import ContentBlock from './components/ContentBlock'
import vSelect from "vue-select";
import AutoRefresh from './components/AutoRefresh'
import LoadingSpinner from './components/LoadingSpinner'
import server_response from './components/ServerResponse'
import App from './App'


import './assets/css/styles.scss';
import VueScrollTo from 'vue-scrollto'
import store from './store';
import i18n from './i18n';
import http from './httpclient';

// Rather do this separately...
import GmapVue from 'gmap-vue'
import router from './router';

import interpolate_translations from "@/locales/merge_wsm_inl";

import Particles from "vue2-particles";

Vue.component('content-block', ContentBlock);
Vue.use(PortalVue);
Vue.use(VueRouter);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import VueSocialSharing from 'vue-social-sharing'
import LandingPageMixin from "@/components/LandingPageMixin";

Vue.use(VueSocialSharing);

Vue.component("v-select", vSelect);


Vue.component('AutoRefresh', AutoRefresh)
Vue.component('LoadingSpinner', LoadingSpinner)
Vue.component('server-response', server_response)
// Vue.component('v-select', VueSelect.VueSelect);

Vue.config.productionTip = false;

Vue.use(VueScrollTo)


// support for week numbers in javascript
// https://stackoverflow.com/questions/7765767/show-week-number-with-javascript
Date.prototype.getWeek = function () {
  let onejan = new Date(this.getFullYear(), 0, 1);
  return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
};

// support for an intuitive timestamp in weeks...
Date.prototype.humanTimeStamp = function () {
  return this.getFullYear() + " " + i18n.t("week") + " " + this.getWeek();
};


Vue.mixin(
  {
    methods: {

      add_to_system_wide_search(item) {
        if (this.$store.state.system_wide_search_keys.includes(item.id))
          return;

        this.$store.state.system_wide_search_keys.push(item.id);
        this.$store.state.system_wide_search.push(item);
      },

      set_and_navigate_to_report: function (organization_id, organization_name) {
        this.$store.commit('change', {
          reported_organization: {
            id: organization_id,
            name: organization_name,
          }
        });
        this.$router.push({path: '/report'})
      },

      humanize_datetime: function (date) {
        return format(parseISO(date), 'PPPPpppp', {locale: this.dateLocales[this.$i18n.locale]});
      },
      humanize_date: function (date) {
        if (!date)
          return ""

        return format(parseISO(date), 'PPPP', {locale: this.dateLocales[this.$i18n.locale]});
      },
      humanize_relative_date: function (date) {
        if (!date)
          return ""


        return formatDistanceToNow(parseISO(date), {addSuffix: true, locale: this.dateLocales[this.$i18n.locale]})
      },

      format_large_number(number) {
        return number.toLocaleString(this.$i18n.locale)
      },
      layers_from_country(country){
          return this.$store.state.config.country_and_layers[country].layers;
      },
      translate: function (string) {
        return this.$i18n.t(string);
      },
      translate_issue_name: function (string) {
        if (string.startsWith('internet_nl'))
          return this.$i18n.t(string + "_label");
        return this.$i18n.t(string);
      },
      categories(issue_name) {
        return this.categories_list(issue_name).join(", ")
      },
      categories_list(issue_name) {
        let translated_categories = []

        if (!this.$store.state.issues[issue_name])
          return ""

        this.$store.state.issues[issue_name].category.forEach(category_name => {
          translated_categories.push(this.$t(`category_${category_name}`))
        })

        return translated_categories
      },
      navigateToMap: function (country, layer) {
        this.$store.commit('change', {country: country, layer: layer});
        this.$router.push('map');
      },
    perc: function (amount, total, digits, percentage_sign) {
        if (digits === undefined) {
          digits = 2;
        }
        if (percentage_sign === undefined) {
          percentage_sign = "%";
        }

      return (!amount || !total) ? "0"+percentage_sign : this.roundTo(amount / total * 100, digits) + percentage_sign;
    },
      percentage(amount, total, digits, percentage_sign) {
        return this.perc(amount, total, digits, percentage_sign)
      },
    // https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
    roundTo: function (n, digits) {
      if (digits === undefined) {
        digits = 0;
      }

      let multiplicator = Math.pow(10, digits);
      n = parseFloat((n * multiplicator).toFixed(11));
      let test = (Math.round(n) / multiplicator);
      return +(test.toFixed(digits));
    },
    },
    computed: {
      dateLocales: function () {
        return {nl: nl, en: enGB}
      },

      reported_organization: () => {
        return store.state.reported_organization;
      },
      layers: () => {
        return store.state.layers;
      },
      organizations: () => {
        return store.state.organizations;
      },
      ordered_visible_issue_names: function () {
        if (this.$store.state.issues === undefined)
          return [];

        // $store.state.config.show.issues[issue['name']]
        let visible_issues = [];
        Object.keys(this.$store.state.issues).forEach((key) => {
          if (this.$store.state.config.show.issues[key]) {
            visible_issues.push(this.$store.state.issues[key])
          }
        })

        return visible_issues;
      },
      countries() {
        return Object.keys(this.$store.state.config.country_and_layers)
      },
      axis_for_issue_graphs() {
        let axis = [];
        if (this.$store.state.config.app.relevant_for_ui.good)
          axis.push("ok")
        if (this.$store.state.config.app.relevant_for_ui.low)
          axis.push("low")
        if (this.$store.state.config.app.relevant_for_ui.medium)
          axis.push("medium")
        if (this.$store.state.config.app.relevant_for_ui.high)
          axis.push("high")
        return axis
      },
      axis_for_org_and_url_graphs() {
        let axis = [];
        if (this.$store.state.config.app.relevant_for_ui.good)
          axis.push("good")
        if (this.$store.state.config.app.relevant_for_ui.low)
          axis.push("low")
        if (this.$store.state.config.app.relevant_for_ui.medium)
          axis.push("medium")
        if (this.$store.state.config.app.relevant_for_ui.high)
          axis.push("high")
        return axis
      }
    },
    watch: {
      state: function () {
        this.load();
      }
    }

  }
);

function inject_custom_styles(css_data) {
  /* https://stackoverflow.com/questions/23050001/insert-multiple-css-rules-into-a-stylesheet */
  let head = document.getElementsByTagName('head')[0];
  let link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'data:text/css,' + escape(css_data);  // IE needs this escaped
  head.appendChild(link);
  // document.getElementById('custom-styles').innerHTML = css_data;
}

function overwrite_favicons(directory) {

  const favicons = {
    "favicon-apple-touch-icon": "apple-touch-icon.png",
    "favicon-icon-small": "favicon-32x32.png",
    "favicon-icon-large": "favicon-16x16.png",
    "favicon-manifest": "site.webmanifest",
    "favicon-mask-icon": "safari-pinned-tab.svg"
  }

  let baseurl = `${process.env.VUE_APP_DJANGO_PATH}/`
  for (const [key, value] of Object.entries(favicons)) {
    document.getElementById(key).href = `${baseurl}/favicons/${directory}/${value}`;
  }

}


/**
 * Use google maps geolocation API to make autocompletion easy.
 * */
Vue.use(GmapVue, {
  load: {
    key: store.state.config.google_maps_api_key,  // , AIzaSyAP8f5d_YksICEXzpUxXS3B9nGRGgQpiCE
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    region: 'NL',
    language: 'NL',

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

// retrieving colors for the graphs is done via this function, which allows the use of css variable names
// inside the graphs at startup.


http.interceptors.response.use((response) => {
  store.commit("set_network_issue", false);
  return response
}, (error) => {

  // todo: also on 404.
  if (error.response === undefined) {
    store.commit("set_network_issue", true);
    return Promise.reject(error.response);
  }

  // all other rest states.
  return error
});

Vue.prototype.$http = http;

// support parameters in comply or explain email templates:
String.prototype.format = function () {
  // store arguments in an array
  let args = arguments;
  // use replace to iterate over the string
  // select the match and check if the related argument is present
  // if yes, replace the match with the argument
  return this.replace(/{([0-9]+)}/g, function (match, index) {
    // check if the argument is present
    return typeof args[index] == 'undefined' ? match : args[index];
  });
};

Vue.prototype.$conf = store.state.config;

Vue.use(Particles);

// Load the config before the application starts. This is one of the earliest requests, this 'god' object contains
// all kinds of app customizations and settings. It might be a bit large, but this is really what makes the app
// tailored towards end users.
http.get(`/data/config/`).then(data => {
  // Support custom style hacks via the admin panel. It should only be used for some
  // easy and straightforward styling rules. When it becomes large/too complex then make your own style.
  // Inject custom CSS as early as possible:

  store.commit('change', {config: data.data})
  store.commit('change', {issues: data.data.scan_types})

  // This works as long as you don't change the config on the frontend...
  Vue.prototype.$conf = data.data;


  // re-painting is slow
  if ('app' in data.data && 'custom_css' in data.data.app) {
    inject_custom_styles(data.data.app.custom_css)
    overwrite_favicons(data.data.app.favicon)
  }




}).catch((error) => {
  console.log(`Could not retrieve config, server not reachable. Using default minimal config. Error: ${error}`)
})


new Vue({
  i18n: {
    silentFallbackWarn: true,
    sharedMessages: interpolate_translations,
  },
  store,
  router,

  mixins: [LandingPageMixin],
  mounted(){
    this.supportLandingArguments();
  },

  watch: {
    '$store.state.config.project.name'() {
      if (!document.title.includes(store.state.config.project.name))
        document.title = `${store.state.config.project.name} - ${document.title}`
    },
  },
  render: h => h(App),
}).$mount('#app')

