<template>
<div class="container">

  <SystemWideSearch />

  <ShareCardReport
    :number_of_high_risk_issues="23"
    :organization_name="'Amsterdam'"
    :report_id="420"
    :twitter_username="'AmsterdamNL'"
  />


</div>
</template>
<script>
import ShareCardReport from "@/components/socials/ShareCardReport";
import SystemWideSearch from "@/components/SystemWideSearch";
export default {
  components: {SystemWideSearch, ShareCardReport},
  methods: {

  }
}
</script>
