import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

// cache for usage in color scheme, retrieving css variable name values
// hope this goes well all the time, and this has no timing issues
const computed_style = getComputedStyle(document.body);

const store = new Vuex.Store({
  state: {
    websecmap_endpoint: process.env.VUE_APP_DJANGO_PATH,

    // login states
    user: {
      is_authenticated: false,
      is_superuser: false,
      is_adminr: false,
    },

    network_issue: false,
    network_issue_at: null,

    // This has to be mutated as one transaction:
    country: 'NL',
    layer: 'municipality',
    week: 0,

    // support historic metrics using the time machine feature.
    // The time machine attempts to get data from a specific date (if there is any)
    time_machine_date: null,

    // map filtering allows to view one specific issue over all maps.
    map_filter: "",

    // report filteron on url when clicking on an url:
    clicked_url: "",

    //
    show_confetti: false,

    supported_locales: ['nl', 'en'],

    reported_organization: {
      id: null,
      name: null,
    },

    report: {
      show_high_in_report: true,
      show_low_in_report: true,
      show_medium_in_report: true,
      show_good_in_report: true,
    },

    // the range of statistics on several pages:
    statistics_amount_of_days: 92,

    // allows for searching over all maps. It has data what map, organization name and direct link to report.
    system_wide_search: [],
    system_wide_search_keys: [],

    // admin editing, moving points
    selected_point: null,

    // products information provided by chatgpt, what could possibly go wrong :)
    technologies: {},

    // allows location sharing, which should not be asked every time the app starts due it being naggy
    // Javascript GeoLocationObject
    // current_geolocation.coords.latitude
    // current_geolocation.coords.longitude
    current_geolocation: undefined,

    // list of object containing the layer name which points to a number of organizations that are nearby.
    // this is created by the individual maps when location sharing is enabled. If location sharing is disabled
    // this list will be empty of course and you should ask for location sharing.
    // eg:
    nearby_organizations: {},

    // application configuration
    config: {
      show: {
        intro: false,
        charts: false,
        comply_or_explain: false,
        scan_schedule: false,
        datasets: false,
        announcement: false,
        statistics: false,
        numbers: false,
        improvements: false,
        graphs: false,
        changes: false,
        ticker: false,
        services: false,
        services_table: false,
        plus_info: false,
        report_numbers: false,
        report_charts: false,
        report_charts_network: false,
        report_risk_summary: false,
        report_content: false,
        report_website_gallery: false,
        disclaimer: false,
        authenticated: false,
        incorrect_finding: false,
        send_in_new_domains: false,
        multi_map_summary_table: false,
        time_machine: false,
        login_plaza: false,
        historic_comparison_reports: false,
        metric_progress: false,
        comply_or_explain_guidelines: false,
        geolocation_results: true,
        snow: false,
        socials: {
          report: false,
          map: false,
          multi_map: false,
          charts: false,
          statistics: false,
        },
        issues: {
          ftp: true,
          plain_http: true,
          dnssec: true,
          http_security_header_strict_transport_security: true,
          http_security_header_x_content_type_options: true,
          http_security_header_x_frame_options: true,
          http_security_header_x_xss_protection: true,
          tls_qualys_certificate_trusted: true,
          tls_qualys_encryption_quality: true,
          internet_nl_mail_starttls_tls_available: true,
          internet_nl_mail_auth_spf_exist: true,
          internet_nl_mail_auth_dkim_exist: true,
          internet_nl_mail_auth_dmarc_exist: true,
          show_internet_nl_v6_web_ipv6: false,
          show_internet_nl_v6_web_ipv6_ready: false,
          show_internet_nl_v6_web_ipv6_proof: false,
          show_internet_nl_v6_web_ipv6_incompatible: false,
          show_internet_nl_v6_mail_ipv6: false,
          show_internet_nl_v6_mail_ipv6_ready: false,
          show_internet_nl_v6_mail_ipv6_proof: false,
          show_internet_nl_v6_mail_ipv6_incompatible: false,
          internet_nl_mail_rpki_exists: false,
          internet_nl_web_rpki_exists: false,
          ports: false,
          internet_nl_wsm_web_appsecpriv_securitytxt: false,
          bannergrab: false,
        }
      },
      announcement: "",
      disclaimer: "",
      responsible_organization: {
        name: "",
        promo_text: "",
        website: "",
        mail: "",
        twitter: "",
        facebook: "",
        linkedin: "",
        whatsapp: "",
        phone: ""
      },
      project: {
        name: "",
        tagline: "",
        country: "NL",
        mail: "",
        issue_mail: "",
        twitter: "",
        facebook: ""
      },
      responsible_disclosure_page: {
        show: false,
        email_address: "",
        pgp_key: "",
        salsa_key: "",
      },
      translations: {
        en: {
          landing: {},
          info: {},
          footer: {}
        },
        nl: {
          landing: {},
          info: {},
          footer: {}
        }
      },
      info: {
        title_1: "Title 1",
        content_1: "Content 1",
        title_2: "Title 2",
        content_2: "Content 2",
        title_3: "Title 3",
        content_3: "Content 3",
        title_4: "Title 4",
        content_4: "Content 4",
        title_5: "Title 5",
        content_5: "Content 5"
      },
      intro: {
        title_html_en: "",
        title_html_nl: "",
        leader_html_en: "",
        leader_html_nl: "",
      },
      footer: {
        content_html_en: "",
        content_html_nl: "",
      },
      comply_or_explain: {email_address: "", email_subject: "", email_body: ""},
      send_in_new_domains: {email_address: "", email_subject: "", email_body: ""},
      incorrect_finding: {email_address: "", email_subject: "", email_body: ""},
      debug: false,
      admin: false,
      country_and_layers: {
        NL: {
          code: "NL",
          name: "Netherlands",
          flag: "/static/flags/nl.gif",
          layers: ["municipality"]
        }
      },
      "app": {
        menu: "single",
        custom_css: "",
        logo_image: "",
        favicon: "websecmap",
        relevant_for_ui:{
          high: true,
          medium: true,
          low: true,
          good: true
        },
        landing_pages: {}
      },
      google_maps_api_key: "",
      grading_policy: "",
    },

    color_scheme: {
      high_background: computed_style.getPropertyValue('--high-map') || '',
      high_border: computed_style.getPropertyValue('--high-dark'),
      medium_background: computed_style.getPropertyValue('--medium-map'),
      medium_border: computed_style.getPropertyValue('--medium-dark'),
      low_background: computed_style.getPropertyValue('--low-map'),
      low_border: computed_style.getPropertyValue('--low-dark'),
      good_background: computed_style.getPropertyValue('--good-map'),
      good_border: computed_style.getPropertyValue('--good-dark'),
      // some use "ok" instead of good...
      ok_background: computed_style.getPropertyValue('--good-map'),
      ok_border: computed_style.getPropertyValue('--good-dark'),
      addresses_background: computed_style.getPropertyValue('--addresses-back'),
      addresses_border: computed_style.getPropertyValue('--addresses-border'),
      services_background: computed_style.getPropertyValue('--services-back'),
      services_border: computed_style.getPropertyValue('--services-border'),
    },

    // loaded at boot
    issues: {}
  },

  mutations: {
    change(state, payload) {
      state = Object.assign(state, payload)
    },
    set_user(state, value) {
      state.user = value;
    },
    set_network_issue(state, value) {
      state.network_issue = value;
    },

    set_selected_point(state, value) {
      state.selected_point = value;
    },

  },

  plugins: [createPersistedState({
    'reducer': function (x){
      let y = Object.assign({}, x);
      delete y['config'];
      delete y['issues'];
      delete y['color_scheme'];
      delete y['system_wide_search'];
      delete y['system_wide_search_keys'];
      delete y['technologies'];
      delete y['current_geolocation'];
      delete y['nearby_organizations'];
      // delete x['config'];
      return y;
    }
  })],
});


export default store;
