<template>
  <div class="pt-4 pb-4">

    <b-container v-cloak class="p-3 mb-3 mt-2 rounded bg-white">

    <h2><b-icon icon="bar-chart-line"/> {{$t('title')}}</h2>

    <loading-spinner :loading="to_load > 0"></loading-spinner>

    <StatisticsSelectRange  class="mb-2" />

    <b-card no-body class="mb-4">
      <b-tabs content-class="mt-0" pills card>
        <b-tab>


          <template #title>
            {{ $t("issues.title") }}
          </template>
          <b-row>
            <b-col>
              <p>{{ $t("issues.intro") }}</p>
            </b-col>
          </b-row>

          <template v-if="issue_stats">
          <template v-if="issue_stats.total">
            <line-chart-with-subcharts
              :axis="axis_for_issue_graphs"
              :chart_data="issue_stats.total"
              :locale="$i18n.locale"
              :amount_of_days="parseInt($store.state.statistics_amount_of_days)"
            ></line-chart-with-subcharts>


           <template v-if="$store.state.config.show.services">
              <hr>
              <div class="page-header mt-4">
                <h3>{{ $t("connectivity") }}</h3>
              </div>
              <b-row>
                <b-col>
                  <div class="chart-container" style="position: relative; height:300px; width:100%">
                    <connectivity-graph :color_scheme="$store.state.color_scheme"
                                        :data="issue_stats.total"
                                        :locale="$i18n.locale"
                                        :amount_of_days="parseInt($store.state.statistics_amount_of_days)"
                    ></connectivity-graph>
                  </div>
                </b-col>
              </b-row>

              <b-row class="mt-4" v-if="$store.state.config.show.services_table">
                <services :ip_versions="[4]"></services>
                <services :ip_versions="[6]"></services>
                <services :ip_versions="[0]"></services>
              </b-row>
            </template>
          </template>

        </template>

        </b-tab>

        <b-tab>
          <template #title>
            {{ $t("internet_addresses.title") }}
          </template>
          <template v-if="url_stats.length > 1">
            <b-row><b-col>
              <p>{{ $t("internet_addresses.intro") }}</p></b-col>
            </b-row>
            <line-chart-with-subcharts
              :axis="axis_for_org_and_url_graphs"
              :chart_data="url_stats"
              :locale="$i18n.locale"
              :amount_of_days="parseInt($store.state.statistics_amount_of_days)"
            ></line-chart-with-subcharts>
          </template>

        </b-tab>

        <b-tab>
          <template #title>
            {{ $t("organizations.title") }}
          </template>

          <template v-if="organization_stats.length > 1">
            <b-row>
              <b-col><p>{{ $t("organizations.intro") }}</p></b-col>
            </b-row>
            <line-chart-with-subcharts
              :axis="axis_for_org_and_url_graphs"
              :chart_data="organization_stats"
              :locale="$i18n.locale"
              :amount_of_days="parseInt($store.state.statistics_amount_of_days)"
            ></line-chart-with-subcharts>
          </template>
        </b-tab>


      </b-tabs>
    </b-card>
      </b-container>


    <b-container class="p-3 mb-3 mt-2 rounded bg-white" v-cloak v-if="issue_stats">

      <template v-if="issue_stats.total">

        <div class="page-header">
          <h3>{{ $t("per_metric") }}</h3>
        </div>

        <b-card no-body class="mb-4">
          <b-tabs content-class="mt-0" pills card lazy vertical>
            <template v-for="issue in $store.state.issues">

              <b-tab :key="issue.name" v-if="issue_stats[issue['name']] && $store.state.config.show.issues[issue['name']]">
                <template #title>
                  {{ translate_issue_name(issue.name) }} <div style="font-size:0.8em">{{categories(issue.name)}}</div>
                </template>

                <b-row :key="issue.name">
                  <div class="col-md-12" v-if="issue_stats[issue['name']]">
                    <h4>{{ $t('Current value') }}</h4>
                    <div class="chart-container" style="position: relative; height:400px; width:100%">
                      <vulnerability-donut
                        :color_scheme="$store.state.color_scheme"
                        :data="issue_stats[issue['name']]"
                        :axis="issue['relevant impacts']"
                        :translation="$i18n.t('vulnerability_donut')"
                      ></vulnerability-donut>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4 pt-4" v-if="issue_stats[issue['name']]">
                    <h4>{{ $t('Historical overview') }}</h4>
                    <div class="chart-container" style="position: relative; height:400px; width:100%">
                      <vulnerability-graph
                        :color_scheme="$store.state.color_scheme"
                        :data="issue_stats[issue['name']]"
                        :axis="issue['relevant impacts']"
                        :amount_of_days="parseInt($store.state.statistics_amount_of_days)"
                        :translation="$i18n.t('vulnerability_donut')"
                      ></vulnerability-graph>
                    </div>
                  </div>

                  <div class="col-md-12 mt-4 pt-4" v-if="issue_stats[issue['name']]">
                    <h4>{{ $t('Where improvements are needed') }}</h4>
                    <what-to-improve :issue_type="issue['name']"></what-to-improve>
                  </div>
                </b-row>

              </b-tab>
            </template>
          </b-tabs>
        </b-card>



      </template>


  </b-container>
    <back-to-top></back-to-top>
  </div>
</template>

<script>

import vulnerabilityGraph from "@/components/charts/VulnerabilityLineChart";
import vulnerabilityDonut from "@/components/charts/VulnerabilityDonutChart";
import connectivityGraph from "@/components/charts/ConnectivityLineChart";
import LineChartWithSubcharts from "@/components/charts/LineChartWithSubcharts";

import WhatToImprove from "@/components/stats/WhatToImprove";
import Services from "@/components/stats/ServicesTable";

import http from "@/httpclient";
import country_layer_switch_mixin from "@/CountryLayerSwitchMixin";

import BackToTop from "@/components/BackToTop";
import TimeMachineMixin from "@/components/TimeMachineMixin";
import StatisticsSelectRange from "@/components/StatisticsSelectRange";

export default {
  mixins: [country_layer_switch_mixin, TimeMachineMixin],
  components: {
    StatisticsSelectRange,

    BackToTop,
    LineChartWithSubcharts,
    WhatToImprove,
    vulnerabilityGraph,
    vulnerabilityDonut,
    connectivityGraph,
    Services
  },

  mounted: function () {
    this.load()
  },

  data: function () {
    return {
      issue_stats: [],
      organization_stats: [],
      url_stats: [],
      to_load: 2,
      stats_days_back: 365,
    }
  },

  methods: {
    load: function () {
      this.to_load = 2;

      let url = ""
      if (this.$store.state.time_machine_date) {
        url = `/data/stats/${this.$store.state.country}/${this.$store.state.layer}/${this.$store.state.time_machine_date}/${this.stats_days_back}/`
      } else {
        url = `/data/stats/${this.$store.state.country}/${this.$store.state.layer}/0/${this.stats_days_back}/`
      }

      http.get(url).then(data => {

        if (Object.keys(data.data).length < 1) {
          this.organization_stats = [];
          this.url_stats = [];
          return;
        }

        this.organization_stats = data.data.organizations.reverse();
        this.url_stats = data.data.urls.reverse();
        this.to_load -= 1;
      })

      let graph_url = ""
      if (this.$store.state.time_machine_date) {
        graph_url = `/data/vulnerability_graphs/${this.$store.state.country}/${this.$store.state.layer}/${this.$store.state.time_machine_date}/${this.stats_days_back}/`
      } else {
        graph_url = `/data/vulnerability_graphs/${this.$store.state.country}/${this.$store.state.layer}/0/${this.stats_days_back}/`
      }

      http.get(graph_url).then(data => {
        this.issue_stats = data.data;
        this.to_load -= 1;
      });
    },

  },
};
</script>
<i18n>
{
  "en": {
    "title": "Statistics",
    "per_metric": "Details per type of measurement",
    "connectivity": "Domains and services",
    "Current value": "Current value",
    "Historical overview": "Historical overview",
    "Where improvements are needed": "Where improvements are needed",

    "vulnerability_donut": {
      "title": "Today's issue in this category",
      "xAxis_label": "Month",
      "yAxis_label": "Risk"
    },
    "issues": {
      "title": "Rating of test results over time",
      "intro": "This graph displays the rating over the absolute number of test results over time."
    },
    "organizations": {
      "title": "Rating of organizations",
      "intro": "This graph shows the rating of organizations over time, up to one year. An organization gets the rating based on the worst performing domain."
    },
    "internet_addresses": {
      "title": "Rating of domains",
      "intro": "This graph shows the rating of domains over time, up to one year. A domain gets the rating based on the worst test result."
    }
  },
  "nl": {
    "title": "Statistieken",
    "per_metric": "Uitsplitsing per soort meting",
    "connectivity": "Domeinen en diensten",
    "Current value": "Huidige staat",
    "Historical overview": "Historisch overzicht",
    "Where improvements are needed": "Waar verbeteringen nodig zijn",
    "vulnerability_donut": {
      "title": "Actuele risico's in deze categorie",
      "xAxis_label": "Maand",
      "yAxis_label": "Risico"
    },
    "issues": {
      "title": "Beoordeling van afzonderlijke testresultaten",
      "intro": "Deze grafiek toont de beoordeling over het absolute aantal meetwaarden over tijd, tot maximaal een jaar terug."
    },
    "organizations": {
      "title": "Beoordeling van organisaties",
      "intro": "Deze grafiek toont de beoordeling va organisaties over de tijd, tot maximaal een jaar terug. Een organisatie krijgt de kleur van het laagst beoordelde onderliggende domeinen."
    },
    "internet_addresses": {
      "title": "Beoordeling van domeinen",
      "intro": "Deze grafiek toont de beoordeling van domeinen over tijd, tot een maximum van een jaar. Een domein krijgt de kleur van de laagst beoordeelde onderliggende meetwaarde."
    }
  }
}
</i18n>
