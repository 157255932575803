<template>
  <div>
      <point-score-badge :value="scores.high" subject="high" v-if="$store.state.config.app.relevant_for_ui.high" />
      <point-score-badge :value="scores.medium" subject="medium" v-if="$store.state.config.app.relevant_for_ui.medium" />
      <point-score-badge :value="scores.low" subject="low" v-if="$store.state.config.app.relevant_for_ui.low" />
  </div>
</template>

<script>
import PointScoreBadge from "@/components/PointScoreBadge";
export default {
  name: "ReportScores",
  components: {PointScoreBadge},
  props: {
    scores: {type: Object, required: true}
  }
}
</script>

<style scoped>

</style>
