<template>
  <div>
      <ask-geolocation-permission style="float: right" v-if="$store.state.current_geolocation !== undefined"/>
      <h4>{{$t('organizations near me')}}</h4>

      <div v-if="$store.state.current_geolocation === undefined">
        <ask-geolocation-permission />
        <p class="mb-2 mt-2" style="font-size: 0.8em;">
        {{$t('location required')}}<br>
        {{$t('location protected')}}
        </p>
      </div>


      <ul style="margin-left: 0; padding-left: 0; max-height: 25em; overflow: scroll;" v-if="rerender_hack > 0">
        <li v-for="nearest in nearest_sorted.slice(0,100)" style="list-style: none; white-space: nowrap" :key="nearest.organization_id">
          <b-badge variant="danger" v-if="nearest.severity === 'high'" style="min-width: 2em; height: 2em;" class="mr-1 pt-1">{{nearest.high}}</b-badge>
          <b-badge variant="warning" v-if="nearest.severity === 'medium'" style="min-width: 2em; height: 2em;" class="mr-1 pt-1">{{nearest.medium}}</b-badge>
          <b-badge variant="success" v-if="nearest.severity === 'low'" style="min-width: 2em; height: 2em;" class="mr-1">&nbsp;</b-badge>
          <b-badge variant="success" v-if="nearest.severity === 'good'" style="min-width: 2em; height: 2em;" class="mr-1">&nbsp;</b-badge>
          <b-badge variant="secondary" v-if="nearest.severity === 'unknown'" style="min-width: 2em; height: 2em;" class="mr-1">&nbsp;</b-badge>
          <router-link :to="`/report/${nearest.organization_id}`">{{nearest.organization_name}}</router-link>
        </li>
      </ul>

  </div>
</template>

<script>
import AskGeolocationPermission from "@/components/map/geolocation/AskGeolocationPermission";
export default {
name: "NearbyOrganizations",
  components: {AskGeolocationPermission},
  methods: {
    update_the_large_list(){
      // make a unique set
      Object.keys(this.$store.state.nearby_organizations).forEach(key => {
        this.$store.state.nearby_organizations[key].forEach(result => {

          let id = result.layer.feature.properties.organization_id
          if (this.nearest_by[id] !== undefined)
            return

          let props = result.layer.feature.properties
          props['distance'] = result.distance;

          this.nearest_by[id] = props

        });
      })

      this.nearest_sorted = Object.values(this.nearest_by)
      this.nearest_sorted.sort(function(first, second) {return second.distance < first.distance});
    },

  },

  data() {
    return {
      nearest_by: {},
      nearest_sorted: [],
      rerender_hack: 0,
    }
  },

  watch: {
    '$store.state.current_geolocation': {
      deep: true,
      handler() {
        setTimeout(() => {this.update_the_large_list(); this.rerender_hack += 1;}, 500);
      }
    }

  }
}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "organizations near me": "Organizations near me",
    "location required": "Share your location to use this function. click below to start sharing your location.",
    "location protected": "Your location is never shared or stored. Permission is only valid this visit."
  },
  "nl": {
    "organizations near me": "Organisaties in mijn buurt",
    "location required": "Om deze functie te kunnen gebruiken is je locatie nodig, klik hieronder om je locatie te delen.",
    "location protected": "Je locatie wordt niet verstuurd of bewaard en je toestemming geldt voor dit bezoek."
  }
}
</i18n>
