<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        {{ $t("select_organization") }}
        <v-select label="name"
                  v-model="selected"
                  :spinner="loading"
                  :options="available_organizations"
                  :placeholder="$t('select_organization')"
        >

          <template v-slot:option="option">
            <div style="display:block;" class="rowline">
              <div style="width: 30em; display:inline-block;">
                <template v-if="selected.id === option.id">
                  &checkmark;
                </template>
                {{ option.name }}
              </div>
              <div style="width: 7em; display:inline-block;">
                <b-badge variant="danger" v-if="$conf.app.relevant_for_ui.high">{{ option.high }}</b-badge><span v-if="$conf.app.relevant_for_ui.high"> &nbsp;</span>
                <b-badge variant="warning" v-if="$conf.app.relevant_for_ui.medium">{{ option.medium }}</b-badge><span v-if="$conf.app.relevant_for_ui.medium"> &nbsp;</span>
                <b-badge variant="success" v-if="$conf.app.relevant_for_ui.low">{{ option.low }}</b-badge><span v-if="$conf.app.relevant_for_ui.low"> &nbsp;</span>
                <b-badge variant="success" v-if="$conf.app.relevant_for_ui.good">{{ option.good }}</b-badge>
              </div>
              <div style="width: 7em; display:inline-block;" v-if="$conf.show.services">
                <b-badge variant="primary">{{ option.urls }}</b-badge> &nbsp;
                <b-badge variant="secondary">{{ option.endpoints }}</b-badge>
              </div>
              <div style="display:inline-block; float: right;">{{ humanize_relative_date(option.at_when) }}</div>
            </div>
          </template>


        </v-select>
        <br/>
      </div>
    </div>
  </div>
</template>


<script>

import http from "@/httpclient";
import country_layer_switch_mixin from "@/CountryLayerSwitchMixin";

export default {
  mixins: [country_layer_switch_mixin],
  data: function () {
    return {
      loading: false,
      selected: {},
      available_organizations: [],
    }
  },

  methods: {
    load: function () {
      this.loading = true;
      http.get(`/data/organizations/list/${this.$store.state.country}/${this.$store.state.layer}/`).then(data => {
        // .filter(item => item.report_available === true);
        this.available_organizations = data.data.filter(item => item.report_available === true);

        // alphabetical ordering: https://stackoverflow.com/questions/1129216/
        this.available_organizations.sort(function (a, b) {
          return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        });

        this.select_row_based_on_state()
        this.loading = false;
      });
    },

    select_row_based_on_state(){
      let select = this.available_organizations.filter(item => item.id === this.$store.state.reported_organization.id);
        if (select.length > 0) {
          this.selected = select[0];
        }
    },
    // todo: deduplicated method.
    parameter_to_app_state: function (url_report_id) {
      if (url_report_id === undefined)
        return;

      if (url_report_id === this.$store.state.reported_organization.id)
        return

      if (url_report_id) {
        this.$store.commit('change', {
          reported_organization: {
            id: parseInt(url_report_id),
            name: null,
          }
        });
      }
    }
  },

  mounted: function () {
    this.load();
  },

  computed: {
    reported_organization: function () {
      return this.$store.state.reported_organization
    },
  },

  watch: {
    reported_organization: function () {
      this.select_row_based_on_state()
    },
    selected(new_id) {
      this.parameter_to_app_state(new_id.id)
    }
  }
};
</script>
<i18n>
{
  "en": {
    "title": "Report",
    "select_organization": "Show report for organization:"
  },
  "nl": {
    "title": "Rapport",
    "select_organization": "Toon rapport van organisatie:"
  }
}
</i18n>
