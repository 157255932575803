<template>
  <div class="pt-4 pb-4">
    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
      <div class="page-header">
        <h2>
          <b-icon icon="hdd-stack"/>
          {{ $t("title") }}
        </h2>
      </div>

      <p v-html="$t('context')"/>
      <p v-html="$t('goals')"/>
      <p v-html="$t('cvd')"/>
      <p v-html="$t('joke')"/>

    </b-container>

    <login-plaza></login-plaza>

    <b-container>
      <back-to-top/>
    </b-container>
  </div>
</template>
<script>

import BackToTop from "@/components/BackToTop";
import LoginPlaza from "@/components/login_plaza/LoginPlaza";

export default {
  components: {LoginPlaza, BackToTop},
}
</script>
<i18n>
{
  "en": {
    "title": "Login Plaza",
    "context": "This is an overview of places where you can log in at various organizations. Some of these places are meant for every person on the internet, while many others are meant specifically for employees, managers and other small user groups. The last category of portals should not be publicly accessible: they should be reachable over a separate network such as a Virtual Private Network. On this page you will also find a lot of web front-ends for such private networks.",
    "goals": "This page aims to reduce the amount of (risky) management portals: they mostly only increase the attack surface and thus increase risk of being hacked. All portals below can be reached by anyone in the world via the free and open internet and anyone can attempt to log in. They should (mostly) be cleaned up.",
    "cvd": "It depends on local legislation if you are allowed to attempt to log in to these places. In the Netherlands it is allowed at every governmental organization, as long as you are following the guidelines of <a href='https://english.ncsc.nl/publications/publications/2019/juni/01/coordinated-vulnerability-disclosure-the-guideline' target='_blank' rel='nofollow noreferrer'>Coordinated Vulnerability Disclosure</a>.",
    "joke": "We hope this page does not become the start page for employees of these organizations :)"
  },
  "nl": {
    "title": "Login Plaza",
    "context": "Dit is een overzicht van verschillende plaatsen waar je kan inloggen bij allerlei organisaties. Sommige van deze plekken zijn bedoeld voor ieder persoon op het internet, terwijl anderen alleen specifiek bedoeld zijn voor medewerkers, beheerders, managers en andere kleine doelgroepen. In het laatste geval zou deze functionaliteit niet publiek beschikbaar moeten zijn, maar alleen via een afgesloten netwerk zoals een Virtueel Prive Netwerk. Op deze pagina vind je dus ook een boel web-voorkanten voor deze privenetwerken.",
    "goals": "Het doel van deze pagina is om zoveel mogelijk van deze (risicovergrotende) beheerders-portalen van het internet af te krijgen. Deze portalen zijn namelijk vooral goed voor het vergroten van het aanvalsoppervlak, wat dus het risico om gehackt te worden vergroot. Alle portalen hieronder kunnen door iedereen worden bereikt via het vrije en open internet. Ze moeten (vaak) worden opgeruimd.",
    "cvd": "Het hangt van lokale wetgeving af of je op deze sites mag inloggen. Bij de gehele Nederlandse overheid mag dat zonder te vragen, zo lang je je houdt aan de regels rondom <a href='https://english.ncsc.nl/publications/publications/2019/juni/01/coordinated-vulnerability-disclosure-the-guideline' target='_blank' rel='nofollow noreferrer'>Coordinated Vulnerability Disclosure</a>.",
    "joke": "We hopen dat deze pagina niet de startpagina wordt van medewerkers voor waar ze moeten inloggen :)"
  }
}
</i18n>
