<template>
<a href="https://websecuritymap.org" target="_blank" rel="nofollow" class="banner">
  <img src="/static_frontend/web-security-map-logo-small.png" alt="wsm logo" width="20px"/>️ {{
    $t("banner")
  }}
</a>
</template>

<script>
export default {
  name: "WsmBanner"
}
</script>

<style scoped>
.banner {
  font-size: 12px;
  background-color: #f2f2f2;
  border-radius: 40px;
  height: 28px;
  display: block;
  text-align: center;
  padding: 5px 9px;
  width: 231px;
  color: #423c3c !important;
  float: right;
}
</style>
<i18n>
{
  "en": {
    "banner": "Made with Web Security Map"
  },
  "nl": {
    "banner": "Gemaakt met Web Security Map"
  }
}
</i18n>
