<style scoped>
li a:any-link {
  color: white;
  text-decoration: none
}

.router-link-exact-active {
  font-weight: normal !important;
  border-bottom: none;
}

</style>

<template>
  <b-container>
    <div class="title_logo" v-if="'app' in $store.state.config && $store.state.config.app.logo_image">
      <router-link to="/maps">
        <div v-html="$store.state.config.app.logo_image" class="title_logo_image"></div>
      </router-link>
    </div>
    <b-navbar toggleable="lg" type="dark" variant="dark" >

      <b-navbar-brand :to="`/map/${country}/${layer}/`" >
        <b-icon icon="map"/>
        {{ $t("menu.map") }}
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse-organization"></b-navbar-toggle>

      <b-collapse id="nav-collapse-organization" is-nav>

        <b-navbar-nav>

          <b-nav-item :to="`/sector-report/${country}/${layer}/`" accesskey="t" exact exact-active-class="active"
                      v-if="$store.state.config.show.historic_comparison_reports">
            <b-icon icon="calendar-range"/>
            {{ $t("menu.sector-report") }}
        </b-nav-item>

          <b-nav-item :to="`/charts/${country}/${layer}/`" accesskey="c" exact exact-active-class="active"
                      v-if="$store.state.config.show.charts">
            <b-icon icon="trophy"/>
            {{ $t("menu.charts") }}
          </b-nav-item>

          <b-nav-item :to="`/login-plaza/${country}/${layer}/`" accesskey="p" exact exact-active-class="active"
                      v-if="$store.state.config.show.login_plaza">
            <b-icon icon="hdd-stack"/>
            {{ $t("menu.login-plaza") }}
          </b-nav-item>

          <b-nav-item :to="`/statistics/${country}/${layer}/`" accesskey="s" exact exact-active-class="active"
                      v-if="$store.state.config.show.statistics">
            <b-icon icon="bar-chart-line"/>
            {{ $t("menu.statistics") }}
          </b-nav-item>

          <b-nav-item :to="`/metric-progress/${country}/${layer}/`" accesskey="s" exact exact-active-class="active"
                      v-if="$store.state.config.show.metric_progress">
            <b-icon icon="emoji-sunglasses"/>
            {{ $t("menu.metric-progress") }}
          </b-nav-item>

        <b-nav-item :to="`/comply-or-explain/${country}/${layer}/`" accesskey="o" exact exact-active-class="active"
                         v-if="$store.state.config.show.comply_or_explain">
          <b-icon icon="chat-left-dots"/>
          {{ $t("menu.comply-or-explain") }}
        </b-nav-item>


          <b-nav-item :to="`/reports/${country}/${layer}/`" accesskey="r" exact exact-active-class="active" >
            <b-icon icon="file-bar-graph"/>
            {{ $t("menu.reports") }}
          </b-nav-item>


        </b-navbar-nav>


      </b-collapse>
    </b-navbar>
  </b-container>
</template>


<script>

export default {
  components: {},
  computed: {
    country() {
      return this.$store.state.country
    },
    layer() {
      return this.$store.state.layer
    }
  }
}

</script>
