<template>
  <l-control position="topright" class="hide_on_small_screens">
    <div class="info table-light" style='max-width: 300px;' v-if="hover_info.properties.organization_name">

      <div>
        <h4>
          <router-link :to="'/report/' + hover_info.properties.organization_id">
            {{ hover_info.properties.organization_name }}
          </router-link>
        </h4>
        <b-button variant="primary" :to="'/report/' + hover_info.properties.organization_id">🔍 {{
            $t("view_report")
          }}
        </b-button>

        <b-progress class="mt-2 mb-2" >
          <b-progress-bar variant="success" :value="perfect"></b-progress-bar>
          <b-progress-bar variant="success" :value="low"></b-progress-bar>
          <b-progress-bar variant="warning" :value="medium"></b-progress-bar>
          <b-progress-bar variant="danger" :value="high"></b-progress-bar>
        </b-progress>
      </div>

      <div>
        <loading-spinner :loading="loading"></loading-spinner>
        <div v-if="domainlist_urls.length > 0">
          <table width='100%'>
            <thead>
            <tr>
              <template v-if="amount_of_visible_levels > 1">
                <th style='min-width: 20px; width: 20px;' v-if="$store.state.config.app.relevant_for_ui.high">{{ this.$i18n.t(this.$store.state.config.grading_policy + '_high') }}</th>
                <th style='min-width: 20px; width: 20px;' v-if="$store.state.config.app.relevant_for_ui.medium">{{ this.$i18n.t(this.$store.state.config.grading_policy + '_medium') }}</th>
                <th style='min-width: 20px; width: 20px;'  v-if="$store.state.config.app.relevant_for_ui.low">{{ this.$i18n.t(this.$store.state.config.grading_policy + '_low') }}</th>
              </template>
              <th>{{ $t("url") }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="url in domainlist_urls" :key="url.id">
              <template v-if="amount_of_visible_levels > 1">
                <td v-if="$store.state.config.app.relevant_for_ui.high"><span :class="colorize(url.high, url.medium, url.low)+'_text'">{{url.high}}</span></td>
                <td v-if="$store.state.config.app.relevant_for_ui.medium"><span :class="colorize(url.high, url.medium, url.low)+'_text'">{{url.medium}}</span></td>
                <td v-if="$store.state.config.app.relevant_for_ui.low"><span :class="colorize(url.high, url.medium, url.low)+'_text'">{{url.low}}</span></td>
              </template>
              <td nowrap><span :class="colorize(url.high, url.medium, url.low)+'_text'">{{url.url}}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </l-control>
</template>

<script>
import {LControl} from 'vue2-leaflet';
import http from "@/httpclient";
import TimeMachineMixin from "@/components/TimeMachineMixin";

export default {
  components: {LControl},
  mixins: [TimeMachineMixin],
  props: {
    hover_info: {type: Object}
  },
  data: function () {
    return {
      domainlist_urls: [],
      loading: false,
    }
  },
  mounted: function () {
    this.issuel = this.$store.state.issues;
  },
  methods: {
    colorize: function (high, medium) {
      if (high > 0) return "high";
      if (medium > 0) return "medium";
      return "good";
    },
    load: function () {
      this.domainlist_urls = [];
      this.loading = true;

      let data_url = ""
      if (this.$store.state.time_machine_date) {
        data_url = `/data/report/${this.$store.state.country}/${this.$store.state.layer}/${this.hover_info.properties.organization_id}/${this.$store.state.time_machine_date}/`;
      } else {
        data_url = `/data/report/${this.$store.state.country}/${this.$store.state.layer}/${this.hover_info.properties.organization_id}/0`;
      }

      http.get(data_url).then(data => {
        this.domainlist_urls = data.data.calculation["organization"]["urls"];
        this.loading = false;
      });
    },


  },
  watch: {
    organization_id: function () {
      this.load();
    }
  },
  computed: {
    // don't show when there is nothing to compare against in a table.
    amount_of_visible_levels() {
        let x = 0
        if (this.$store.state.config.app.relevant_for_ui.high)
          x++
        if (this.$store.state.config.app.relevant_for_ui.medium)
          x++
        if (this.$store.state.config.app.relevant_for_ui.low)
            x++
        return x
    },
    organization_id: function () {
      return this.hover_info.properties.organization_id;
    },
    high: function () {
      return this.perc(this.hover_info.properties.high_urls, this.hover_info.properties.total_urls);
    },
    medium: function () {
      return this.perc(this.hover_info.properties.medium_urls, this.hover_info.properties.total_urls);
    },
    low: function () {
      return this.perc(this.hover_info.properties.low_urls, this.hover_info.properties.total_urls);
    },
    perfect: function () {
      return this.perc(this.hover_info.properties.total_urls -
        (this.hover_info.properties.low_urls + this.hover_info.properties.medium_urls + this.hover_info.properties.high_urls),
        this.hover_info.properties.total_urls);
    },
    unknown: function () {
      return 0;
    },
    total: function () {
      return this.hover_info.properties.high + this.hover_info.properties.medium + this.hover_info.properties.low;
    },
  }
}
</script>

<style scoped>
tbody {
  font-weight: bolder;
}

/* For some reason the text is dark, which has too low of a contrast. */
.btn-primary:link, .btn-primary:visited{
  color: var(--light) !important;
}
</style>
<i18n>
{
  "en": {
    "_high": "H",
    "_medium": "M",
    "_low": "L",
    "ipv6_high": " ",
    "ipv6_medium": " ",
    "ipv6_low": " ",
    "url": "Domain"
  },
  "nl": {
    "_high": "H",
    "_medium": "M",
    "_low": "L",
    "ipv6_high": " ",
    "ipv6_medium": " ",
    "ipv6_low": " ",
    "url": "Domein"
  }
}
</i18n>
