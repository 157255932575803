<template>
  <div class="pt-4 pb-4">


      <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
      <div class="page-header">
        <h2>
          <b-icon icon="chat-left-dots"/> {{ $t("title") }}
        </h2>
      </div>

      <comply-or-explain-guideline-text />

    </b-container>
  </div>
</template>
<style scoped>

</style>
<script>
import ComplyOrExplainGuidelineText from "@/components/report/ComplyOrExplainGuidelineText";
export default {
  components: {ComplyOrExplainGuidelineText}
}
</script>
<i18n>
{
  "en": {
    "ok": "Close",
    "title": "Guidance for a good explanation"
  },
  "nl": {
    "ok": "Sluiten",
    "title": "Handleiding voor een goede verklaring"
  }
}
</i18n>
