<template>
    <b-nav-item-dropdown v-if="shouldBeVisible" class="active">
      <template #text>
        <template v-if="showTitle">
          <b-icon icon="layers"/> {{`${countries.length > 1 ? cl[$store.state.country].name : ''} ${$t($store.state.layer)}`}}
        </template>
        <template v-else>
          <b-icon icon="layers"/> {{ $t($store.state.layer) !== $store.state.layer ? $t($store.state.layer) : niceify($store.state.layer) }}
        </template>
      </template>

      <template v-for="country_code_key in Object.keys(cl)">
          <template v-if="countries.length > 1">
            <b-dropdown-divider :key="country_code_key" v-if="country_code_key !== Object.keys(cl)[0]"></b-dropdown-divider>
            <b-dropdown-item :key="country_code_key" @click="set_state(cl[country_code_key].code, cl[country_code_key].layers[0])">
              <img :src="cl[country_code_key].flag" width="16" height="10">{{ cl[country_code_key].name }}
            </b-dropdown-item>
          </template>

          <b-dropdown-item :key='layer' @click="set_state(country_code_key, layer)" v-for="layer in cl[country_code_key].layers">
            <span v-if="$store.state.country === country_code_key && $store.state.layer === layer">
              &checkmark;&nbsp;&nbsp;
            </span>
            {{ $t(layer) !== layer ? $t(layer) : niceify(layer) }}
          </b-dropdown-item>
        </template>
    </b-nav-item-dropdown>
</template>
<script>

/*
* "country_and_layers": {
    "NL": {
      "code": "NL",
      "name": "Netherlands",
      "flag": "/static/flags/nl.gif",
      "layers": [
        "municipality",
        "healthcare",
        "province",
        "government"
      ]
    }
* */

export default {

  data: function () {
    return {
      // gets set when a country is selected.
      my_layers: [],

      // country and layers
      cl: {},
    }
  },


  methods: {
    set_state: function (country_code, layer_name) {
      this.$store.commit('change', {country: country_code, layer: layer_name});
      this.$store.commit('change', {reported_organization: {id: 0, name: ""}});

      // you only want to navigate away on app pages, not on organization pages. On organization pages just
      // switch context:
      if (this.$router.currentRoute.meta.menu === 'app') {
        this.$router.push('map');
      } else {
        // make the link to this page explicit
        this.$router.push(`/${this.$router.currentRoute.meta.basename}/${country_code}/${layer_name}/`);
      }
    },
    niceify(text){
      text = text.replace(/-/g, ' ');
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  },

  props: {
    showTitle: {type: Boolean, default: true},
    showIfEmptyOrSingle: {type: Boolean, default: true},
  },

  computed: {
    countries() {
      return Object.keys(this.cl);
    },
    shouldBeVisible() {
      if(this.showIfEmptyOrSingle)
        return true

      // If there is more than 1 item to show, this will be shown, otherwise it will be hidden as it adds UI clutter
      if(this.countries.length > 1)
        return true

      let items = 0;

      for (const country in this.cl) {
          items += this.cl[country].layers.length
      }

      return items > 1
    },

  },

  mounted: function () {
    this.cl = this.$store.state.config.country_and_layers;

    let first = Object.keys(this.cl);
    if (first[0] === undefined)
      return;

    this.my_layers = this.cl[first[0]].layers;

    // todo: this is a side effect, factor it out.
    this.$store.commit('change', {layers: this.my_layers});
    // this.selected_country = this.cl[first[0]].code;
  },

  watch: {
    '$store.state.config.country_and_layers': {
      deep: true,
      handler(newVal){
        this.cl = newVal;
      }

    }
  }

};
</script>

<i18n>
{
  "en": {
    "country": "Country",
    "layer": "Change",
    "data_selection": "Data Selection",
    "map": "Map"
  },
  "nl": {
    "country": "Land",
    "layer": "Gegevens",
    "data_selection": "Selecteer gegevens",
    "map": "Kaart"
  }
}
</i18n>

