<template>
  <div class="pt-4 pb-4">



    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
    <div class="page-header">
      <h2><b-icon icon="file-bar-graph" />{{ $t("title") }}</h2>
    </div>
    <report_selection></report_selection>
    </b-container>

    <report_content :url_report_id="url_report_id"></report_content>

    <b-container>
    <back-to-top/>
    </b-container>
  </div>
</template>
<script>

import report_content from "@/components/report/ReportContent";
import report_selection from "@/components/report/ReportSelection";
import BackToTop from "@/components/BackToTop";

export default {
  components: { BackToTop, report_content, report_selection},

  props: {
    url_report_id: String,
  },

  mounted: function () {
    // initialize the current report if there is none yet.
    this.parameter_to_app_state(this.$route.params.url_report_id)
  },

  methods: {
    parameter_to_app_state: function (url_report_id) {
      if (url_report_id === undefined)
        return;

      if (url_report_id === this.$store.state.reported_organization.id)
        return

      if (url_report_id) {
        this.$store.commit('change', {
          reported_organization: {
            id: parseInt(url_report_id),
            name: null,
          }
        });
      }
    }
  },

  watch: {
    $route: function (to) {
      this.parameter_to_app_state(to.params.url_report_id)
    }
  }
}
</script>
<i18n>
{
  "en": {
    "title": "Reports"
  },
  "nl": {
    "title": "Rapporten"
  }
}
</i18n>
