<template>
  <div v-if="anythingtoshow()" class="mb-3 pl-3 pr-3" style="width: 100%; text-align: center;">

    <micro-menu-button :country="country" :layer="layer" page="map"  icon="map" :link="`map/${country}/${layer}/`" /> &nbsp;

    <template v-if="$store.state.config.show.historic_comparison_reports">
      <micro-menu-button :country="country" :layer="layer" page="sector-report" icon="calendar-range" :link="`sector-report/${country}/${layer}/`" /> &nbsp;
    </template>

    <template  v-if="$store.state.config.show.charts">
      <micro-menu-button :country="country" :layer="layer" page="charts" icon="trophy" :link="`charts/${country}/${layer}/`" /> &nbsp;
    </template>

    <template  v-if="$store.state.config.show.login_plaza">
      <micro-menu-button :country="country" :layer="layer" page="login-plaza" icon="hdd-stack" :link="`login-plaza/${country}/${layer}/`" /> &nbsp;
    </template>

    <template v-if="$store.state.config.show.statistics">
      <micro-menu-button :country="country" :layer="layer" page="statistics" icon="bar-chart-line" :link="`statistics/${country}/${layer}/`" /> &nbsp;
    </template>

    <template v-if="$store.state.config.show.metric_progress">
      <micro-menu-button :country="country" :layer="layer" page="metric-progress" icon="emoji-sunglasses" :link="`metric-progress/${country}/${layer}/`" /> &nbsp;
    </template>

    <template v-if="$store.state.config.show.comply_or_explain">
      <micro-menu-button :country="country" :layer="layer" page="comply-or-explain" icon="chat-left-dots" :link="`comply-or-explain/${country}/${layer}/`" /> &nbsp;
    </template>

    <micro-menu-button :country="country" :layer="layer" page="reports" icon="file-bar-graph" :link="`reports/${country}/${layer}/`" />

  </div>
</template>
<script>

import MicroMenuButton from "@/components/map/MicroMenuButton";
export default {
  components: {MicroMenuButton},
  props: {
    country: {type: String, required: true},
    layer: {type: String, required: true}
  },

  methods: {
    navto(pagename) {
      this.$store.commit('change', {country: this.country, layer: this.layer});
      this.$router.push(pagename);
    },

    anythingtoshow() {
      return this.$store.state.config.show.statistics || this.$store.state.config.show.charts || this.$store.state.config.show.comply_or_explain || this.$store.state.config.show.historic_comparison_reports
    }

  }
}


</script>
