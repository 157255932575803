<script>
// todo: get language key, and make sure the correct translations are used in these grahs.
import 'chartjs-adapter-date-fns';

import {nl} from "date-fns/locale";
import {enGB} from 'date-fns/locale';

export default {
    props: {
        data: {type: Array, required: true},
        axis: {type: Array, required: false},
        color_scheme: {type: Object, required: false},
        translation: {type: Object, required: false},
        display_legend: {type: Boolean, required: false, default: true},
        display_title: {type: Boolean, required: false, default: true},
        display_x_scale: {type: Boolean, required: false, default: true},
        display_y_scale: {type: Boolean, required: false, default: true},
        locale: {type: String, required: false, default: "en"},
        amount_of_days: {type: Number, required: false, default: 365},
    },
    data: function() {
        // [Vue warn]: The "data" option should be a function that returns a per-instance value in component definitions.
        // so what should i use then? No suggestion?
        return {
            chart: {}
        }
    },
    render: function(createElement) {
        return createElement(
            'canvas',
            {
                ref: 'canvas'
            },
        )
    },
    methods: {
        translate: function(key) {
            if (this.translation === undefined)
                return "";

            if (Object.keys(this.translation).length === 0)
                return "";

            return this.translation[key];
        }
    },
  computed: {
      locale_text_data() {
        let table = {'en': enGB, 'nl': nl}
        return table[this.locale]
      },
    from_date_when_number_of_days() {
      let d = new Date();

      // take into account the time machine:
      let time_machine_days = 0
      if (this.$store.state.time_machine_date) {
        let timemachine = new Date(this.$store.state.time_machine_date);
        let now = new Date();
        time_machine_days = Math.floor((now.getTime() - timemachine.getTime())/(1000*60*60*24));
      }

      d.setDate(- (this.amount_of_days + time_machine_days) )
      return d.toISOString().split('T')[0]
    }
  },
    mounted: function () {
        this.buildChart();
        this.renderData();
    },
    watch: {
        data: function(){
            this.renderData();
        },

        axis: function(){
            this.renderData();
        },

        locale: function(){
            this.renderData();
        },

        amount_of_days: function(){
            this.renderData();
        },

        // Supports changing the colors of this graph ad-hoc.
        // charts.js is not reactive.
        color_scheme: function(){
            this.renderData();
        },

        translation: function(){
            this.renderData();
        },
    }
};
</script>
