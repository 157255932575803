<template>
  <b-container>
    <b-navbar toggleable="lg" type="dark" variant="danger">

      <b-navbar-brand href="#">
        <b-badge pill variant="danger">{{ $t("title") }}</b-badge>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-b-modal.addorganization>{{ $t("add_organization") }}</b-nav-item>
          <b-nav-item v-b-modal.addproxies>{{ $t("add_proxies") }}</b-nav-item>
          <b-nav-item v-b-modal.appstate>{{ $t("alter_state") }}</b-nav-item>
          <b-nav-item to="/dev">{{ $t("dev_page") }}</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown text="Special" right>
            <b-dropdown-item @click="reset_application">{{ $t("reset_state") }}</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>

      <!--
          <a @click="$parent.set_color_scheme('pink')" class="dropdown-item">Pink (did you color all?)</a>
          <a @click="$parent.set_theme('default')" class="dropdown-item">Theme: Default</a>
      -->

    </b-navbar>

    <app-state/>
    <add-organization/>
    <add-proxies/>
    <move-organization/>

  </b-container>
</template>

<script>

import AddOrganization from "@/components/management/AddOrganizationForm";
import AddProxies from "@/components/management/AddProxiesForm";
import AppState from "@/components/management/AppStateForm";
import MoveOrganization from "@/components/management/MoveOrganizationForm";

export default {
  components: {MoveOrganization, AppState, AddProxies, AddOrganization},
  methods: {
    reset_application() {

      let answer = confirm("Do you want to reset the local storage and restart the app?");
      if (answer) {
        window.localStorage.clear();
        window.location.reload();
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "title": "Admin",
    "debug": "debug",
    "admin": "admin",
    "flower": "task monitoring",
    "grafana": "activity monitoring",
    "add_proxies": "Add proxies",
    "alter_state": "Alter state",
    "add_organization": "Add organization",
    "ticker": "Toggle Ticker",
    "dev_page": "Development Testpage",
    "reset_state": "Reset application"
  },
  "nl": {
    "title": "Beheer",
    "debug": "ongediertebestrijdingsmodus",
    "admin": "beheer",
    "flower": "taak overzicht",
    "grafana": "activiteiten overzicht",
    "add_proxies": "Proxies toevoegen",
    "alter_state": "Staat wijzigen",
    "add_organization": "Organisatie toevoegen",
    "ticker": "Ticker aan/uitzetten",
    "dev_page": "Ontwikkel-testpagina",
    "reset_state": "Applicatie herinitialiseren"
  }
}
</i18n>
