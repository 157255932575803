<template>
  <div class="share-network-list">

    <b-card
      :title="title"
      :img-src="image_src"
      :img-alt="image_alt"
      img-top
      tag="article"
      class="mb-2"
    >
      <blockquote>{{ description }}</blockquote>

      <p>{{ sharing_introduction }}</p>

      <ShareNetwork v-for="network in networks" :key="network.network"
                    :network="network.network"
                    :url="`${$store.state.websecmap_endpoint}/${url}`"
                    :title="title"
                    :description="description"
                    :quote="facebook_quote"
                    :hashtags="twitter_facebook_hashtags"
                    :twitterUser="twitter_user"
      >
        <b-badge :style="{backgroundColor: network.color}" class="mr-1">
          <b-icon :icon="network.icon" class="mr-1"/>
          <span>{{ network.name }}</span>
        </b-badge>

      </ShareNetwork>

      <template #footer>
        <em class="cta">{{ $t('cta') }}</em>
      </template>

    </b-card>

  </div>
</template>

<script>

// https://github.com/nicolasbeauvais/vue-social-sharing
import {ShareNetwork} from "vue-social-sharing/src/vue-social-sharing";

export default {
  name: "ShareCard",
  components: {
    ShareNetwork
  },

  props: {
    sharing_introduction: {type: String, required: false, default: "Share"},

    url: {type: String, required: false, default: "https://internetcleanup.foundation"},
    title: {type: String, required: true},
    description: {type: String, required: true},

    facebook_quote: {type: String, required: false, default: ""},
    twitter_facebook_hashtags: {type: String, required: false, default: 'security,web,map,transparency'},
    twitter_user: {type: String, required: false, default: ""},
    // media is not supported by the currently listed networks.

    // card props:
    image_src: {type: String, required: false, default: ""},
    image_alt: {type: String, required: false, default: ""},
  },

  data() {
    return {
      networks: [
        // ordered by rainbow. Starting with Green, Yellow, Orange, Red, Purple, Blue.
        // Should this come from settings? :)
        {network: 'email', name: 'Email', icon: 'envelope', color: '#333333'},
        {network: 'whatsapp', name: 'Whatsapp', icon: 'whatsapp', color: '#25d366'},
        {network: 'reddit', name: 'Reddit', icon: 'reddit', color: '#ff4500'},
        {network: 'linkedin', name: 'LinkedIn', icon: 'linkedin', color: '#007bb5'},
        {network: 'twitter', name: 'Twitter', icon: 'twitter', color: '#1da1f2'},
        {network: 'skype', name: 'Skype', icon: 'skype', color: '#00aff0'},
        {network: 'telegram', name: 'Telegram', icon: 'telegram', color: '#0088cc'},
        {network: 'facebook', name: 'Facebook', icon: 'facebook', color: '#1877f2'},
        {network: 'messenger', name: 'Messenger', icon: 'messenger', color: '#0084ff'},
        {network: 'yammer', name: 'Yammer', icon: 'building', color: '#0072c6'},
      ],
    }
  },

}
</script>

<style scoped>

.cta {
  font-size: 0.8em;
}

.share-network-list {

}

.card-footer {
  padding: 0.25rem 1.25rem;
}

</style>
<i18n>
{
  "en": {
    "cta": "Click on the social network to share"
  },
  "nl": {
    "cta": "Klik op het sociale network om te delen"
  }
}
</i18n>
