<template>
  <b-modal size="xl" id="comply-or-explain-guidelines" @close="close()" scrollable title="Comply or explain guidelines"
             ok-only ok-title="Add organization" ok-variant="success">

    <template #modal-ok="">
      {{ $t('ok') }}
    </template>
    <template #modal-title="">
      {{ $t('title') }}
    </template>

    <comply-or-explain-guideline-text />

  </b-modal>
</template>

<script>
import ComplyOrExplainGuidelineText from "@/components/report/ComplyOrExplainGuidelineText";
export default {
  name: "ComplyOrExplainGuidelines",
  components: {ComplyOrExplainGuidelineText}
}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "ok": "Close",
    "title": "Guidance for a good explanation"
  },
  "nl": {
    "ok": "Sluiten",
    "title": "Handleiding voor een goede verklaring"
  }
}
</i18n>
