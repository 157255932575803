<template>
  <b-modal size="xl" id="addorganization" @close="stop_add_organization()" scrollable title="Add Organization"
           @cancel="stop_add_organization" @ok="do_add_organization" cancel-title="Stop" ok-title="Add organization" ok-variant="success" cancel-variant="danger">
    <server-response :response="server_response" />

    <b-row class="mb-4" v-if="last_added.name">
      <b-col>
        <p><b>✅ Last added: {{ last_added.name }}, {{ last_added.address }}, {{ last_added.domains }}</b></p>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>

        <b-form-group
          label="Domains (without http(s)://)"
          label-for="input-domains"
          description="example.com, test.nl, Every domain on a new line, or separated with comma's. No https:// or pages."
          class="mb-0"
        >
          <b-form-textarea
            id="input-domains"
            v-model="add_organization.domains"
            placeholder="example.com, test.nl, Every domain on a new line, or separated with comma's."
          ></b-form-textarea>
        </b-form-group>

      </b-col>
      <b-col>

        <CountryLayerSelection @change="handleCountryLayerChange"/>

      </b-col>
    </b-row>

    <GoogleLocationSearch @placed="setPlace"/>

  </b-modal>
</template>

<script>
/**
 * Use google maps geolocation API to make autocompletion easy.
 * */
import http from "@/httpclient";

import GoogleLocationSearch from "@/components/management/GoogleLocationSearch";
import CountryLayerSelection from "@/components/management/CountryLayerSelection";


export default {
  name: "Add-Organization",
  components: {CountryLayerSelection, GoogleLocationSearch},

  data() {
    return {
      server_response: {},
      add_organization: {
        name: "",
        address: "",
        domains: "",
        latitude: "",
        longitude: "",
        layer: this.$store.state.layer,
        country: this.$store.state.country,
      },
      last_added: {},
    }
  },

  methods: {
    handleCountryLayerChange(item) {
      this.add_organization.country = item.country;
      this.add_organization.layer = item.layer;
    },


    setPlace(place) {
      if (place.geometry) {
        this.add_organization.longitude = place.geometry.location.lng()
        this.add_organization.latitude = place.geometry.location.lat()
        this.add_organization.name = place.name;
        this.add_organization.address = place.formatted_address;
      }
    },

    reset: function () {
      this.add_organization = {
        name: "",
        address: "",
        domains: "",
        latitude: "",
        longitude: "",
        layer: this.$store.state.layer,
        country: this.$store.state.country,
      }
    },

    stop_add_organization: function () {
      this.reset();
    },

    do_add_organization: function (event) {

      // in case of validation errors, cancel closing.
      event.preventDefault();

      http.post(`data/admin/organization/add/`, this.add_organization).then((server_response) => {
          this.server_response = server_response.data;

          if (server_response.data.success) {
            // can't add domains to this organization now... unfortunately.
            this.last_added = this.add_organization;
            this.reset();


            // invalid domains
            if (server_response.data.data) {
              this.add_organization.domains = server_response.data.data.invalid_domains.join(", ");
            }

            // close this
            this.$refs['addorganization'].hide()
          }
        }
      );

    },

  }
}
</script>

