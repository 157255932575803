<template>
  <div v-if="technology && technology.id">
    <div>
      <div style="position: relative; text-align: center;">
        <div style="position: absolute; bottom: -10px; z-index: 200; width: 100%; text-align: center" >
         <b-badge :href="link" class="m-2 whitelink" v-if="showVersion" variant="dark">Bezoek inlogpagina</b-badge><br>
        </div>
        <b-avatar :size="`${size}px`" class="mb-2" :id="`tooltip-${unique_tooltip_id}`"
          :href="link"
          target="_blank"
          :style="`background-color: transparent; border: ${border}; background-image: url('/login_plaza_logos/${logolink()}.png'); background-size: cover; background-position: center center; `"
          rel="nofollow noreferrer"
        >  &nbsp;

        </b-avatar>
      </div>
    </div>

    <span v-if="showName"><br><b>{{ technology.name }}</b></span>
    <b-tooltip :target="`tooltip-${unique_tooltip_id}`" triggers="hover" placement="bottom">
      <h4>{{technology.name}}</h4>
      <b-badge class="m-2" v-if="version !== 'unknown'" variant="danger">{{ $t('version') }}: {{version}}</b-badge>
      <b-badge class="m-2" variant="light" v-if="technology.cve_details_link" :href="technology.cve_details_link" target="_blank" rel="nofollow" :title="$t('cve_info')">{{ $t('view cves') }}</b-badge>
      <br>
      <span v-if="technology.vendor && technology.vendor.name">{{ $t('by') }}: {{technology.vendor.name}}<br></span>
      <span v-if="technology.tags && technology.tags.length > 0">{{ $t('tags') }}: {{technology.tags.join(', ')}}<br></span>
      <span v-if="technology.common_execution_context">{{ $t('runs_on') }}:  {{technology.common_execution_context}}<br></span>
      <br>
      <i>{{technology.intended_use_case_description}}</i><br>
    </b-tooltip>
    <br v-if="badge_position === 'bottom'">
    <b-badge class="m-2" v-if="version !== 'unknown' && showVersion" variant="danger">{{ $t('version') }}: {{version}}</b-badge>
    <b-badge class="m-2" variant="light" v-if="technology.cve_details_link && showCve" :href="technology.cve_details_link" target="_blank" rel="nofollow" :title="$t('cve_info')">{{ $t('view cves') }}</b-badge>
  </div>
</template>

<i18n>
{
  "en": {
    "version": "Version",
    "view cves": "View CVEs",
    "cve_info": "The Common Vulnerabilities and Exposures (CVE) system provides a reference method for publicly known information-security vulnerabilities and exposures.",
    "by": "By",
    "tags": "Tags",
    "runs_on": "Runs on",
    "intended_use_case_description": "Description"
  },
  "nl": {
   "version": "Versie",
    "view cves": "Bekijk CVE's",
    "cve_info": "Common Vulnerabilities and Exposures, meestal afgekort als CVE, is een publieke databank met informatie over kwetsbaarheden in computersystemen en netwerken.",
    "by": "Door",
    "tags": "Tags",
    "runs_on": "Draait op",
    "intended_use_case_description": "Omschrijving"
  }
}
</i18n>

<script>
export default {
  name: "techInfo",
  props: {
    id: {type: Number, required: true},
    version: {type: String, required: false},
    link: {type: String, required: false},
    size: {type: Number, required: false, default: 250},
    showCve: {type: Boolean, required: false, default: true},
    showVersion: {type: Boolean, required: false, default: true},
    showName: {type: Boolean, required: false, default: true},
    badge_position: {type: String, required: false, default: 'bottom'},
    border: {type: String, required: false, default: '1px solid silver'},
  },
  mounted(){
    this.unique_tooltip_id = this.uuidv4()
    this.technology = this.$store.state.technologies[this.id];
  },
  methods: {
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
    logolink(){
      if (this.technology.slug.startsWith('wordpress')) {
        return "wordpress"
      }
      return this.technology.slug
    }
  },
  data() {
    return {
      technology: {},
      unique_tooltip_id: "",
    }
  },
  watch: {
    '$store.state.technologies'() {
      this.technology = this.$store.state.technologies[this.id];
    }
  }
}
</script>

<style scoped>
.whitelink, .whitelink:hover,.whitelink:focus, .whitelink:active, .whitelink:visited {
  color: var(--light);
}
</style>
