<script>
export default {
  name: "TranslationsFromConfigMixin",
  data() {
    return {
      translations: {},
    }
  },
  mounted() {
    this.update()
  },
  methods: {
      update(locale) {
        if (!this.$store.state.config.translations)
          return

        if (locale)
          this.translations = this.$store.state.config.translations[locale];

        this.translations = this.$store.state.config.translations[this.$i18n.locale];
      }
  },
  watch: {
    '$i18n.locale': {
      deep: false,
      handler(new_value) {
        this.update(new_value)
      }
    },
    '$store.state.config.translations': {
      deep: false,
      handler() {
        this.update()
      }
    }
  }
}
</script>
