<style scoped>
th {
  font-weight: 500;
}
</style>

<template>

  <b-table-simple striped hover bordered small responsive>
    <b-thead>
      <b-tr>
        <b-th style="width:40%; border: 0;" sticky-column></b-th>
        <th style="white-space: nowrap; vertical-align: bottom; height: 240px;  border: 0;"
              v-for="issue in issues_list"
              :key="issue.name">
          <div
            style="transform: translate(21px, -10px) rotate(315deg); width: 60px; margin-bottom: -0.3; margin-left: -0.8em">
            <span>{{ translate_issue_name(issue.name) }}</span>
            <div class="pl-3" style="margin-top: -0.3em; padding: 0; font-size: 0.8em; font-weight: normal;">
              {{ categories(issue.name) }}
            </div>
          </div>
        </th>
      </b-tr>
    </b-thead>
    <b-tbody style="max-height: 240px; overflow-y: scroll">
      <template v-for="url in sorted_urls">
        <b-tr :key="url.id" v-if="url.endpoints.length > 0">
          <b-td sticky-column><b><span @click="set_url_filter(url)"
                                       v-scroll-to="'#detail-report'">{{ url.url }}</span></b></b-td>
          <td v-for="col in worst_issue_summary(url)" :key="col.url" :class="'text-center '+col.bgcolor">
            {{ col.text }}
          </td>
        </b-tr>
      </template>
      <template v-if="!urls.length">
        <b-tr>
          <b-td>-</b-td>
          <b-td colspan="100">{{ $t("no_data") }}</b-td>
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>

</template>

<script>

export default {
  name: "RiskSummary",
  props: {
    issues_list: {type: Array, required: true},
    urls: {type: Array, required: true},
  },
  data() {
    return {
      i: 0,
      sorted_urls: [],
      issue_table: {}
    }
  },
  mounted() {
    this.sort_urls()
    this.urls_to_keyed_issues()
  },
  methods: {
    // this is not applied to the domains below...
    sort_urls() {
      this.sorted_urls = this.urls
      this.sorted_urls.sort(this.dynamicSortMultiple("high", "medium", "low", "url"))
    },
    dynamicSortMultiple() {
      /*
       * save the arguments object as it will be overwritten
       * note that arguments object is an array-like object
       * consisting of the names of the properties to sort by
       */
      let self = this
      let props = arguments;
      return function (obj1, obj2) {
        let i = 0, result = 0, numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while (result === 0 && i < numberOfProperties) {
          result = self.dynamicSort(props[i])(obj1, obj2);
          i++;
        }
        return result;
      }
    },
    dynamicSort(property) {
      return function (obj1, obj2) {
        return obj1[property] > obj2[property] ? -1
          : obj1[property] < obj2[property] ? 1 : 0;
      }
    },



    urls_to_keyed_issues(){
      // needed: this.issue_table[www.example.com][internet_nl_xyz] = [{}, {}, {}]
      // This groups all issues together per domain. As we only show the result of the worst
      // issue per domain. That should not be a O2 but O1 operation.

      for(let i=0; i<this.sorted_urls.length; i++){
        let u = this.sorted_urls[i]
        let u_name = u['url']
        this.issue_table[u_name] = {}
        u.ratings.forEach((rating) => {
          if (!this.issue_table[u_name][rating['type']])
            this.issue_table[u_name][rating['type']] = []
          this.issue_table[u_name][rating['type']].push(rating);
        });
        u.endpoints.forEach((e) => {
          e.ratings.forEach((rating) => {
            if (!this.issue_table[u_name][rating['type']])
              this.issue_table[u_name][rating['type']] = []
            this.issue_table[u_name][rating['type']].push(rating);
          });
        })
      }
    },

    worst_issue_summary: function (url) {
      let findings = [];

      this.issues_list.forEach((issue) => {
        let res = this.worstof(url.url, issue.name);
        findings.push({bgcolor: res.bgclass, text: res.text});
      });

      return findings;
    },
    set_url_filter(url) {
      this.$store.commit("change", {clicked_url: url.url});
    },
    worstof: function (url_name, risk_name) {
      let high = 0, medium = 0, low = 0;
      let risk_found = false;
      let explained = false;
      let not_tested = false;
      let error_in_test = false;

      if (!this.issue_table[url_name])
        return {'bgclass': '', 'text': ''}

      if (!this.issue_table[url_name][risk_name])
        return {'bgclass': '', 'text': ''}


      for (let i = 0; i < this.issue_table[url_name][risk_name].length; i++) {
        let rating = this.issue_table[url_name][risk_name][i];

        risk_found = true;
        high += rating.high;
        medium += rating.medium;
        low += rating.low;
        if (rating.comply_or_explain_valid_at_time_of_report)
          explained = true;
        if (rating.not_testable)
          not_tested = true;
        if (rating.error_in_test)
          error_in_test = true;
      }

      let text = "";
      let bgclass = "";

      if (high) {
        text = "❌";
        bgclass = "high_background_light";
      } else if (medium) {
        text = "⚠️";
        bgclass = "medium_background_light";
      } else if (low) {
        text = "✅";
        bgclass = "low_background_light";
      } else if (risk_found) {
        text = "✅";
        bgclass = "good_background_light";
      }

      if (explained) {
        // if this is a string with "", translations say unterminated string. As ES6 template it's fine.
        text = "💬";
        bgclass = "good_background_light";
      }

      if (not_tested) {
        // if this is a string with "", translations say unterminated string. As ES6 template it's fine.
        text = "-";
        bgclass = "not_tested_background_light";
      }

      if (error_in_test) {
        // if this is a string with "", translations say unterminated string. As ES6 template it's fine.
        text = "⚡️";
        bgclass = "not_tested_background_light";
      }

      return {'bgclass': bgclass, 'text': text}

    },
  },
  computed: {
    // These issues are specific for the URL level, others are on endpoint level.
    url_issue_names: function () {
      if (this.$store.state.issues === undefined)
        return [];

      // todo: frontend doesnt know the difference between url and endpoint scans.
      if (!Object.keys(this.$store.state.issues).includes("dnssec"))
        return [];

      return [this.$store.state.issues['dnssec']['name']]

    }
  }
}
</script>

<i18n>
{
  "en": {
    "no_data": "This report contains no data / urls."
  },
  "nl": {
    "no_data": "Dit rapport bevat geen gegevens."
  }
}
</i18n>
