<template>
  <div>
    <p>{{ urls_with_usable_endpoints.length }} {{$t('sites_found')}}</p>
  <b-row>
    <template v-for="set in urls_with_usable_endpoints">
      <b-col :key="set.endpoint.id" class="mb-4 m-2 p-2 hide-overflow colover rounded"
             :class="`report_url_background_${url_grade(set.url)}`">
        <EndpointScreenshot :url="set.url" :endpoint="set.endpoint"/>
        {{ set.url.url }}
      </b-col>
    </template>
  </b-row>
    </div>
</template>

<script>
import EndpointScreenshot from "@/components/report/EndpointScreenshot";

export default {
  name: "WebsitePortfolio",
  components: {EndpointScreenshot},
  props: {
    urls: {type: Array, required: true}
  },
  methods: {
    url_grade(url) {
      if (url.high > 0) return "high";
      if (url.medium > 0) return "medium";
      if (url.low > 0) return "low";
      return "good";
    },
  },
  computed: {
    urls_with_usable_endpoints() {
      let answer = []

      this.urls.forEach(url => {
        url.endpoints.forEach(endpoint => {
          if (['http', 'https', 'dns_a_aaaa'].includes(endpoint.protocol)) {
            answer.push({
              url: url,
              endpoint: endpoint
            })
          }
        })
      })

      answer = answer.sort((a, b) => {
        if (a.url.url < b.url.url) {
          return -1;
        }
        if (a.url.url > b.url.url) {
          return 1;
        }
        return 0;
      });

      return answer
    }
  }
}
</script>

<style scoped>
.hide-overflow {
  overflow: hidden;
  min-width: 320px;
}

.colover{
  text-align: center;
}

.colover:hover {
  background-color: var(--light);
}
</style>
<i18n>
{
  "en": {
    "sites_found": "Websites found"
  },
  "nl": {
    "sites_found": "Websites gevonden"
  }
}
</i18n>
