<style scoped>
.outline {
  text-align: center;
  border-radius: 1em;
  width: 100%;
  height: 660px;
}

.number {
  font-size: 7em;
  font-weight: bold;
}

.icon {
  font-size: 8em;
  font-weight: bold;
}

.title {
  font-weight: bold;
  /* Can be two lines when wrapping*/
  min-height: 3em;
  display: block;
}

.percentage_text {
  font-size: 3em;
}

</style>

<template>
  <div class="p-4 mb-4 outline" :style="'background-color: var(--'+backgroundVariant+'); color: var(--'+textVariant+');'">
    <span class="title" v-if="headtext">{{ headtext}}<br></span>
    <span class="icon" v-if="icon"><b-icon :icon="icon" v-if="icon" /></span><br>
    <span class="number">{{ number }}</span><span class="percentage_text" v-if="postfix">{{postfix}}</span><br>
    <span class="subtext" v-if="subtext">{{ subtext }}</span>
  </div>
</template>

<script>
export default {
  name: "VeryLargeNumber",

  props: {
    direction: {type: String, default: "right", required: false},
    number: {type: Number, default: 0, required: true},
    postfix: {type: String, default: '', required: false},
    subtext: {type: String, default: "", required: false},
    headtext: {type: String, default: "", required: false},
    backgroundVariant: {type: String, default: "dark", required: false},
    textVariant: {type: String, default: "light", required: false},
    icon: {type: String, default: "", required: false}
  }
}
</script>

<style scoped>

</style>
