var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('header',{staticClass:"do-not-print"},[(_vm.$store.state.config.show.announcement === true)?_c('announcement'):_vm._e(),(_vm.$store.state.config.app.menu === 'dual')?[_c('main-menu-dual-bar'),(this.$router.currentRoute.meta.menu === 'app')?_c('sub-menu-app'):_vm._e(),(this.$router.currentRoute.meta.menu === 'organization')?_c('sub-menu-organization'):_vm._e()]:[_c('main-menu-single-bar')],(_vm.$store.state.config.admin === true)?_c('management-menu'):_vm._e()],2),_c('main',{staticStyle:{"background-color":"var(--light)"}},[_c('keep-alive',[_c('router-view')],1)],1),_c('footer',{staticClass:"footer p-2 pt-4 pb-1 do-not-print",staticStyle:{"background-color":"var(--dark)"}},[(_vm.$store.state.config.show.disclaimer === true)?_c('disclaimer'):_vm._e(),_c('footer-content')],1),_c('comply-or-explain-guidelines'),(_vm.$store.state.config.show.snow)?_c('Particles',{attrs:{"id":"snow_particles","particlesInit":_vm.particlesInit,"options":{
          "fullScreen": {
              "zIndex": 1
            },

            particles: {
                move: {
                    direction: "bottom",
                    enable: true,
                    random: false,
                    straight: false,
                },
                opacity: {
                    value: { min: 0.6, max: 0.8 },
                },
                size: {
                    value: { min: 1, max: 10 },
                },
                wobble: {
                    distance: 20,
                    enable: true,
                    speed: {
                        min: -5,
                        max: 5,
                    },
                },
            }}}}):_vm._e(),(_vm.$store.state.show_confetti)?_c('Particles',{attrs:{"id":"tsparticles","particlesInit":_vm.particlesInit,"options":{
  "fullScreen": {
    "zIndex": 1
  },
  "emitters": {
    "position": {
      "x": 50,
      "y": 100
    },
    "rate": {
      "quantity": 5,
      "delay": 0.15
    }
  },
  "particles": {
    "color": {
      "value": [
        "#1E00FF",
        "#FF0061",
        "#E1FF00",
        "#00FF9E"
      ]
    },
    "move": {
      "decay": 0.05,
      "direction": "top",
      "enable": true,
      "gravity": {
        "enable": true
      },
      "outModes": {
        "top": "none",
        "default": "destroy"
      },
      "speed": {
        "min": 50,
        "max": 100
      }
    },
    "number": {
      "value": 0
    },
    "opacity": {
      "value": 1
    },
    "rotate": {
      "value": {
        "min": 0,
        "max": 360
      },
      "direction": "random",
      "animation": {
        "enable": true,
        "speed": 30
      }
    },
    "tilt": {
      "direction": "random",
      "enable": true,
      "value": {
        "min": 0,
        "max": 360
      },
      "animation": {
        "enable": true,
        "speed": 30
      }
    },
    "size": {
      "value": 3,
      "animation": {
        "enable": true,
        "startValue": "min",
        "count": 1,
        "speed": 16,
        "sync": true
      }
    },
    "roll": {
      "darken": {
        "enable": true,
        "value": 25
      },
      "enlighten": {
        "enable": true,
        "value": 25
      },
      "enable": true,
      "speed": {
        "min": 5,
        "max": 15
      }
    },
    "wobble": {
      "distance": 30,
      "enable": true,
      "speed": {
        "min": -7,
        "max": 7
      }
    },
    "shape": {
      "type": [
        "circle",
        "square"
      ],
      "options": {}
    }
  },
  "responsive": [
    {
      "maxWidth": 1024,
      "options": {
        "particles": {
          "move": {
            "speed": {
              "min": 33,
              "max": 66
            }
          }
        }
      }
    }
  ]
}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }