<script>

import chart_mixin from "@/components/charts/ChartMixin";
import {Chart, LineController, LineElement, PointElement, LinearScale, Title, Legend} from 'chart.js';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, Legend);

export default {
  mixins: [chart_mixin],

  methods: {
    buildChart: function () {
      this.chart = new Chart(this.$refs.canvas.getContext('2d'), {
        type: 'line',
        data: {},
        options: {
          elements: {
            point: {
              radius: 0,
            }
          },
          legend: {
            display: this.display_legend,
          },
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: this.display_title,
            text: 'Internet connectivity'
          },
          plugins: {
            tooltip: {
              mode: 'index',
              intersect: false,
            }
          },
          scales: {
            x: {
              adapters: {
                date: {
                  locale: this.locale_text_data
                }
              },
              display: true,
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'month'
              },
              scaleLabel: {
                display: false,
                labelString: 'Month'
              }
            },
            y: {
              display: true,
              stacked: false,
              suggestedMin: 0,
              scaleLabel: {
                display: false,
                labelString: 'Value'
              },
              ticks: {
                min: 0,
                beginAtZero: true,
                precision: 0,
              }
            }
          }
        }
      });
    },

    renderData: function () {
      let data = this.data;

      if (data === undefined)
        return;

      let labels = Array();

      let urls = Array();
      let endpoints = Array();

      for (let i = 0; i < data.length; i++) {

        if (data[i].date < this.from_date_when_number_of_days)
          continue;

        labels.push(data[i].date);
        urls.push(data[i].urls);
        endpoints.push(data[i].endpoints);
      }

      this.chart.data.labels = labels;
      this.chart.data.datasets = [
        {
          label: this.$t(`${this.$store.state.config.grading_policy}_score_amount_domains`),
          // label: '# Internet Adresses',
          data: urls,
          backgroundColor: this.color_scheme.addresses_background,
          borderColor: this.color_scheme.addresses_border,
          borderWidth: 3,
          lineTension: 0,
          //fill: 'start',
        },
        {
          label: this.$t(`${this.$store.state.config.grading_policy}_score_amount_services`),
          data: endpoints,
          backgroundColor: this.color_scheme.services_background,
          borderColor: this.color_scheme.services_border,
          borderWidth: 3,
          lineTension: 0,
          // fill: 'start',
        }];

      this.chart.update();
    }
  }
};
</script>
