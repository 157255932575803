<script>
// not because pie charts are useful, but because they look cool.
// https://www.businessinsider.com/pie-charts-are-the-worst-2013-6?international=true&r=US&IR=T
// https://www.datapine.com/blog/notorious-pie-charts/
import chart_mixin from "@/components/charts/ChartMixin";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  DoughnutController,
  ArcElement,
  Legend, PieController
} from 'chart.js';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, DoughnutController, ArcElement, Legend, PieController);


export default {
  mixins: [chart_mixin],

  props: {
    type: {type: String, default: "doughnut", required: false},
  },

  methods: {

    buildChart: function () {
      let context = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(context, {
        type: this.type,
        data: {},
        options: {
          plugins: {
            legend: {
              display: this.display_legend,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: this.display_title,
            text: this.translate('title'),
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
        }
      });

    },
    renderData: function () {
      let data = this.data;

      if (data === undefined)
        return;

      let labels = Array();
      let high = Array();
      let medium = Array();
      let low = Array();
      let ok = Array();

      high.push(data[data.length - 1].high);
      medium.push(data[data.length - 1].medium);
      low.push(data[data.length - 1].low);
      ok.push(data[data.length - 1].ok);

      let backgroundColor = [];
      let borderColor = [];
      let chartdata = [];


      // Only include OK in the donuts, not the graphs. Otherwise the graphs become unreadable (too much data)
      backgroundColor.push(this.color_scheme.good_background);
      borderColor.push(this.color_scheme.good_border);
      labels.push(this.$t(this.$store.state.config.grading_policy + '_score_good'));
      chartdata.push(ok);

      if (this.axis.includes('low')) {
        backgroundColor.push(this.color_scheme.low_background);
        borderColor.push(this.color_scheme.low_border);
        labels.push(this.$t(this.$store.state.config.grading_policy + '_score_low'));
        chartdata.push(low);
      }

      if (this.axis.includes('medium')) {
        backgroundColor.push(this.color_scheme.medium_background);
        borderColor.push(this.color_scheme.medium_border);
        labels.push(this.$t(this.$store.state.config.grading_policy + '_score_medium'));
        chartdata.push(medium);

      }

      if (this.axis.includes('high')) {
        backgroundColor.push(this.color_scheme.high_background);
        borderColor.push(this.color_scheme.high_border);
        labels.push(this.$t(this.$store.state.config.grading_policy + '_score_high'));
        chartdata.push(high);
      }


      this.chart.data.labels = labels;
      this.chart.data.datasets = [{
        data: chartdata,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 2,
        lineTension: 0,
      }];

      this.chart.update();
    }
  }
};
</script>
