<template>
  <b-modal id="addproxies" size="xl" @close="stop_add_proxies()" title="Add Proxies" @ok="add_proxies"
           @cancel="stop_add_proxies">

    <server-response :response="server_response"></server-response>

    <p>You can add proxies using csv, newline or space separated... or mixed. Make sure that
      the proxy is HTTPS capable(!). So only HTTPS proxies!</p>
    <p><i>Protip: try to add proxies for your region.
      And more important: preferably use proxies you exclusively use.</i></p>
    <h4>Proxies</h4>
    <b-textarea style="width: 100%; height: 240px" v-model="new_proxies"
                placeholder="1.1.1.1:8000, 2.2.2.2.2:8008, 3.3.3.3:1234..."></b-textarea>

  </b-modal>
</template>

<script>
import http from "@/httpclient";

export default {
  name: "AddProxies",

  data() {
    return {
      server_response: "",
      new_proxies: "",
    }
  },

  methods: {
    stop_add_proxies: function () {
      this.new_proxies = "";
    },
    add_proxies: function () {

      http.post(
        `data/admin/proxy/add/`, {proxies: this.new_proxies}).then(server_response => {
        this.server_response = server_response.data;

        if (server_response.data.data) {
          this.new_proxies = server_response.data.data.invalid_proxies.join(", ");
        }
      });
    },

  }
}
</script>
