<style scoped>
.infodot {
  margin-top: 34px;
  font-size: 1.2em;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  padding: 6px;
  height: 34px;
  position: absolute;
  width: 34px;
  text-align: center;
  line-height: 1.2;
}
</style>

<template>
  <l-control position="topleft">
    <b-button v-b-modal.map-health-modal class='infodot' v-if="map_health">
      <span v-b-tooltip.hover  :title="`${map_health.percentage_up_to_date}%`">{{ dot }}</span> <span class="sr-only">{{
        meaning_of_percentage
      }}</span>
    </b-button>
  </l-control>
</template>

<script>
import http from "@/httpclient";
import {LControl} from 'vue2-leaflet';

export default {
  components: {LControl},
  name: "health_dot.vue",
  data: function () {
    return {
      map_health: null,
      percentage_up_to_date: 0,
      meaning_of_percentage: "unknown",
      dot: '❓'
    }
  },
  mounted: function () {
    this.load();
  },
  methods: {
    get_health_state(percentage) {
      if (percentage === 100)
        return ["perfect", "✅"]

      if (percentage === 0)
        // This should trigger a warning over the map that the data should not be relied upon.
        return ["obsolete", "⭕"]

      if (percentage > 0 && percentage < 55)
        return ["outdated", "🔴"]

      if (percentage > 54 && percentage < 90)
        return ["incomplete", "🟠"]

      if (percentage > 89)
        return ["usable", "🟢"]

      return ["unknown", "❓"]
    },
    load: function () {
      http.get(`/data/map_health/${this.$store.state.country}/${this.$store.state.layer}/`).then((data) => {
        this.map_health = data.data;
        [this.meaning_of_percentage, this.dot] = this.get_health_state(this.map_health.percentage_up_to_date);
      })
    },
  }
}
</script>
