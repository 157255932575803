<style>
.sticky_to_top {
  position: fixed;
  right: 1.8em;
  bottom: 1.8em;
  z-index: 1000000;
}
</style>
<template>
  <b-row>
    <b-col class="text-center tc m-3">
      <b-button variant="dark" class="sticky_to_top" @click="backtotop"><b-icon icon="chevron-up"/></b-button>
      <b-button variant="dark" @click="backtotop"><b-icon icon="chevron-up"/> {{ $t('Back to top') }} </b-button>
    </b-col>
  </b-row>
</template>
<script>
export default {
  methods: {
    backtotop(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
}
</script>
<i18n>
{
  "en": {
    "Back to top": "Click here to scroll back to top of the page"
  },
  "nl": {
    "Back to top": "Klik hier om naar de bovenkant van de pagina te gaan"
  }
}
</i18n>
