<template>
  <div>
    <b-row class="p-2">
      <div style="position: relative; height:250px; width:100%">
        <vulnerability-graph
          :color_scheme="$store.state.color_scheme"
          :data="chart_data"
          :axis="axis"
          :translation="$i18n.t('test')"
          :locale="locale"
          :amount_of_days="amount_of_days"
        ></vulnerability-graph>
      </div>
    </b-row>


    <b-row v-if="chart_data.length > 1" class="mt-2 p-3">
      <b-col v-for="ax in axis" :key="ax">
        <div style="position: relative; height:150px; width:100%">
          <vulnerability-graph
            :color_scheme="$store.state.color_scheme"
            :data="chart_data"
            :axis="[ax]"
            :display_title="false"
            :display_legend="true"
            :display_x_scale="false"
            :translation="$i18n.t('test')"
            :locale="locale"
            :amount_of_days="amount_of_days"
          ></vulnerability-graph>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import vulnerabilityGraph from "@/components/charts/VulnerabilityLineChart";

export default {
  name: "LineChartWithSubcharts",
  components: {vulnerabilityGraph},

  props: {
    axis: {type: Array, required: true},
    chart_data: {type: Array, required: true},
    locale: {type: String, required: false, default: "en"},
    amount_of_days: {type: Number, required: false, default: 365},
  }
}
</script>

<style scoped>

</style>
<i18n>

{
  "en": {
    "test": {
      "title": "Total amount of risks over time.",
      "xAxis_label": "Month",
      "yAxis_label": "Risk"
    }
  },
  "nl": {
    "test": {
      "title": "Aantal risico's over tijd.",
      "xAxis_label": "Maand",
      "yAxis_label": "Risico"
    }
  }
}
</i18n>
