<template>
  <b-row>
    <b-col>
      <very-large-number
        direction="right"
        :number="value_then"
        postfix="%"
        :headtext="days_back + ' dagen geleden'"
        subtext="was veilig"
        :background-variant="'secondary'"
        text-variant="light"
        :icon="icon"
      />
    </b-col>
    <b-col>
      <very-large-number
        direction="right"
        :number="difference > 0 ? difference : value_then - value_now "
        postfix="%"
        :headtext="(difference > 0 ? 'verbeterd' : 'verslechterd') + ' in ' + days_back + ' dagen'"
        :subtext="'is ' + (difference > 0 ? 'verbeterd' : 'verslechterd')"
        :background-variant="'light'"
        :text-variant="difference > 0 ? 'success' : 'danger'"
        :icon="difference > 0 ? 'arrow-up-circle' : 'arrow-down-circle'"
      />
    </b-col>
    <b-col>
      <very-large-number
        direction="right"
        :number="value_now"
        postfix="%"
        headtext="vandaag"
        subtext="is veilig"
        :background-variant="value_now  !== 100 ? 'danger' : 'success'"
        text-variant="light"
        :icon="icon"
      />
    </b-col>
  </b-row>
</template>

<script>
import VeryLargeNumber from "@/components/VeryLargeNumber";

export default {
  name: "ImprovementComparison",
  components: {VeryLargeNumber},
  props: {
    value_now: {type: Number, default: 0, required: true},
    value_then: {type: Number, default: 0, required: true},
    icon: {type: String, default: "", required: true},
    days_back: {type: Number, default: 0, required: true}

  },
  computed: {
    difference() {
      return this.value_now - this.value_then;
    }
  }
}
</script>

<style scoped>

</style>
