<template>
  <b-modal ok-only size="xl" title="Alter state" id="appstate" scrollable>

    <h3>Map State</h3>
    <p>
    <b>Country</b>: <input v-model="$store.state.country"><br />
    <b>Layer</b>:<input v-model="$store.state.layer"><br />
    </p>

    <h3>Visible Application Features</h3>
    <p>

      <template v-for="value, name in $store.state.config.show">
        <template v-if="name !=='issues'">
        <b-check switch v-model="$store.state.config.show[name]" :key="name">{{name}}</b-check>
        </template>
      </template>
    </p>

    <h3>More</h3>
    <b-check switch v-model="$store.state.show_confetti">Confetti</b-check>

    <h3>Visible issues</h3>
    <p>Note that the issue has to be in the report to be visible in this UI.</p>
    <p>
    <template v-for="issue_value, issue_name in $store.state.config.show.issues">
      <b-check switch v-model="$store.state.config.show.issues[issue_name]" :key="issue_name">{{$t(issue_name)}}</b-check>

    </template>
    </p>



  </b-modal>
</template>

<script>
export default {
  name: "AppState",
}
</script>

<style scoped>

</style>
