<template>
  <b-col v-cloak v-if="services.length">
    <h4>{{ $t('IPv' + ip_versions[0]) }}</h4>
    <b-table
      :responsive="'sm'"
      :no-border-collapse="true"
      :items="this.services"
      :fields="fields"
      :busy="loading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      striped hover
    >
      <template #cell(percentage)="data">
        {{ ((data.item.amount / endpoints_now) * 100).toFixed(2) }}%
      </template>

      <template #cell(protocol)="data">
        {{ $t(data.item.protocol) }}
      </template>
    </b-table>
  </b-col>
</template>

<script>
import http from "@/httpclient";

import country_layer_switch_mixin from "@/CountryLayerSwitchMixin";
import TimeMachineMixin from "@/components/TimeMachineMixin";

export default {
  mixins: [country_layer_switch_mixin, TimeMachineMixin],

  mounted: function () {
    this.load(0)
  },

  data: function () {
    return {
      data: Array,
      services: [],
      endpoints_now: 0,

      loading: false,
      stats_days_back: 365,

      sortBy: 'amount',
      sortDesc: true,
      fields: [
        // {key: "ip_version", sortable: true, label: 'IP Version'},
        {key: "protocol", sortable: true, label: 'Protocol'},
        {key: "port", sortable: true, label: 'Port',},
        {key: "amount", sortable: true, label: 'Amount'},
        {key: "percentage", sortable: false, label: 'Percentage'}
      ],
    }
  },

  props: {
    ip_versions: {
      type: Array, required: false, default: () => {
        return [4, 6, 0]
      }
    }
  },

  methods: {
    load: function (weeknumber = 0) {
      this.loading = true;

      let data_url = ""
      if (this.$store.state.time_machine_date) {
          data_url = `/data/stats/${this.$store.state.country}/${this.$store.state.layer}/${this.$store.state.time_machine_date}/${this.stats_days_back}/`;
        } else {
          data_url = `/data/stats/${this.$store.state.country}/${this.$store.state.layer}/${weeknumber}/${this.stats_days_back}/`;
        }


      http.get(data_url).then(data => {

        // empty
        if (Object.keys(data.data).length < 1) {
          this.data = [];
          this.endpoints_now = 0;
          this.services = [];
          return;
        }

        this.data = data.data;

        this.endpoints_now = data.data.endpoints_now;

        this.services = [];
        for (let i = 0; i < data.data.endpoint.length; i++) {
          let z = data.data.endpoint[i][1];
          this.services.push({
            'amount': z.amount,
            'ip_version': z.ip_version,
            'protocol': z.protocol,
            'port': z.port
          })
        }

        // Only show the ip-version requested:
        this.services = this.services.filter(service => this.ip_versions.includes(service.ip_version))
        let has_ports = this.services.filter(service => service.port > 0)

        if (has_ports.length === 0) {
          this.fields = [
            // {key: "ip_version", sortable: true, label: 'IP Version'},
            {key: "protocol", sortable: true, label: 'Protocol'},
            {key: "amount", sortable: true, label: 'Amount'},
            {key: "percentage", sortable: false, label: 'Percentage'}
          ]
        }

        this.loading = false;
      });
    },
  }
};
</script>

<i18n>
{
  "en": {
    "title": "Protocols and ports",
    "intro": "One address can have a plethora of services.",
    "number_of_service_checked": "A total of {0} services are checked.",
    "ip_version": "IP Version",
    "protocol": "Protocol",
    "port": "Port",
    "amount": "Amount",
    "percentage": "Percentage",
    "IPv4": "Available via IPv4 address",
    "IPv6": "Available via IPv6 address",
    "IPv0": "Gebruikte DNS informatie",
    "dns_a_aaaa": "A of AAAA Record",
    "dns_mx_no_cname": "MX record (geen CNAME)"
  },
  "nl": {
    "title": "Protocollen en poorten",
    "intro": "Een enkel adres kan meerdere diensten hebben, denk aan bestandsoverdracht, een website en e-mail.",
    "number_of_service_checked": "In totaal werden {0} verschillende diensten gescand.",
    "ip_version": "IP Versie",
    "protocol": "Protocol",
    "port": "Poort",
    "amount": "Aantal",
    "percentage": "Percentage",
    "IPv4": "Beschikbaar via IPv4 adres",
    "IPv6": "Beschikbaar via IPv6 adres",
    "IPv0": "Gebruikte DNS informatie",
    "dns_a_aaaa": "A of AAAA Record",
    "dns_mx_no_cname": "MX record (geen CNAME)"
  }
}
</i18n>
