<template>
  <b-button variant="primary" @click="navto(link)" :id="`${country}-${layer}-popover-${page}`">
    <b-icon :icon="icon" />
    <b-popover placement="bottom" :target="`${country}-${layer}-popover-${page}`" triggers="hover focus">
        {{$t("menu." + page)}}
    </b-popover>
  </b-button>
</template>

<script>
export default {
  name: "MicroMenuButton",
  props: {
    country: {
      type: String,
      required: true
    },
    layer: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    page: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  methods: {
    navto(pagename) {
      this.$store.commit('change', {country: this.country, layer: this.layer});
      this.$router.push(pagename);
    },
  }
}
</script>

<style scoped>

</style>
