<template>
  <l-control position="topright" class="hide_on_small_screens" v-if="issuel">
    <div style="max-width: 300px; overflow:hidden;" class="info table-light">
      <b-button v-b-toggle.collapse-1 :variant="button_variant" style='width: 100%'>
        <template v-if="['clear_filter_option', ''].includes($store.state.map_filter)">{{
            $t("filter") }} {{$t("everything")
          }}</template>
          <template v-if="$store.state.map_filter !== ''"><b-icon icon="chevron-down"/> {{ translate_issue_name($store.state.map_filter) }}</template>
      </b-button>

      <b-collapse id="collapse-1" class="mt-2">
        <b-form-radio class="mb-2 radiohover p-2 rounded" v-model="$store.state.map_filter" name="displayed_issue" value=""
               id="clear_filter_option">
          {{ $t("everything") }}</b-form-radio>
        <div v-for="issue in Object.keys(issuel)" style="white-space: nowrap;" :key="issue" >
          <b-form-radio v-model="$store.state.map_filter" class="radiohover mb-1 p-2 rounded" name="displayed_issue" :value="issuel[issue].name" v-if="$store.state.config.show.issues[issuel[issue].name]"
                 :id="issuel[issue].name">
            {{ translate_issue_name(issuel[issue].name) }} <div style="font-size: 0.8em">{{categories(issuel[issue].name)}}</div>

          </b-form-radio>
        </div>
      </b-collapse>
    </div>
  </l-control>
</template>

<script>
import {LControl} from 'vue2-leaflet';

export default {
  components: {LControl},
  data: function () {
    return {
      issuel: {}
    }
  },
  mounted: function () {
    this.issuel = this.$store.state.issues;
  },
  computed: {
    button_variant(){
      return this.$store.state.map_filter !== "" ? "primary" : "secondary"
    }
  },
  watch: {
    // apparently it will not automatically update on boot
    '$store.state.issues': {
      deep: false,
      handler() {
        this.issuel = this.$store.state.issues;
      }
    }
  }
}
</script>

<style scoped>
.radiohover:hover {
  background-color: var(--dark);
  color: var(--light);
}
.radiohover {
    margin-left: 1em;
}


</style>
<i18n>
{
  "en": {
    "everything": "Show all tests",
    "filter": "Filter: "
  },
  "nl": {
    "everything": "Toon alle tests",
    "filter": "Filter: "
  }
}
</i18n>
