<!--
done: graphs from and to, instead of days back.
halfway: translate every scan type into a human friendly explanation
todo: version for 'all' level: so all layers summarized and compared.
done: switch between layers
done: support report landing page
todo: password protect press page? / embargo?
done: what moments are reports published? Can this be changed by the end user? Can the end user compare?
todo: make page screenshotable and pdf-export ready
done: add comparison with N days ago , todo: when the data of this half year is known.
todo: support translatable free text per report, per language. this might also define a report.
       currently with a generic set of texts that are fair to use but also a bit generic.
todo: when days back is set to 0, there should be no comparison but just straight facts. -> or when there is no comparison data. So this should be a computed value 'can_be_compared' / 'comparison_attempt'
todo: translations (after ok)
-->

<style>
.page-header {
  break-before: always !important;
  page-break-before: always !important;
}

@media print {
  body {
    background-color: var(--white) !important;

  }

  main {
    background-color: var(--white) !important;
  }

  a{
    color: var(--black) !important;
    text-decoration: none!important;
  }
}
</style>

<style scoped>
.pressfont {
  font-size: 1.4em;
}

h3 {
  margin-top: 1em;
}

h4 {
  margin-top: 1.2em;
}

.friendly_explanation {
  font-size: 0.8em;
}

figcaption {
  font-size: 0.8em;
  margin-top: 0.3em;
  font-style: italic;
}
</style>

<template>
  <div class="pt-1 pb-4" role="main">

    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white do-not-print" v-if="loading">
      <loading-spinner :loading="loading"/>

      <b-progress :value="loaded" :max="to_load" show-progress animated></b-progress>
    </b-container>

    <template v-if="!loading">

      <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white pressfont page-header">

        <h1>Veiligheid {{ $t($store.state.layer) }}: {{ percentage_current_organization_good }}% veilig</h1>

        <b-row>
          <b-col>
            <p><i>Gegegevens van: {{ humanize_date(report.current.date.toISOString().substr(0, 10)) }}, vergeleken met
              gegevens van {{ humanize_date(report.compared.date.toISOString().substr(0, 10)) }}.</i></p>

            <p>Een veilig internet is belangrijk. Alleen dan kan je zonder zorgen een website bezoeken, een formulier
              opsturen of mailen.</p>
            <p>Wij meten de veiligheid van het internet. Dat doen we iedere dag. Op deze pagina staan de resultaten van
              deze metingen..</p>

            <p>
              Van organisaties gemeten in de categorie '<b>{{ $t($store.state.layer) }}</b>' is
              <b>{{ percentage_current_organization_good }}%</b>
              veilig. Er zijn <b>{{ format_large_number(historic_issue_stats.total[historic_newest].high) }} zwakke plekken</b>
              gevonden.</p>
            <p></p>

            <p>Problemen met veiligheid kan leiden tot valse e-mails en het datalekken.</p>

            <h2>Lees verder:</h2>
            <ol>
              <li v-scroll-to="'#conclusion'"><a href="#/press/">De grote getallen</a></li>
              <li v-scroll-to="'#map'"><a href="#">De kaart van toen en nu</a></li>
              <li v-scroll-to="'#info'"><a href="#">Informatie over veiligheid, metingen en verantwoording</a></li>
            </ol>
            <ul>
              <li v-scroll-to="'#appendixa'"><a href="#">Bijlage A: Metingen en betekenissen</a></li>
              <li v-scroll-to="'#appendixb'"><a href="#">Bijlage B: Grote versie van de kaart</a></li>
              <li v-scroll-to="'#appendixc'"><a href="#">Bijlage C: Overzichtstabel en aantal metingen</a></li>
            </ul>

          </b-col>

          <b-col cols="4">
            <figure>
              <web-sec-map
                :country="$store.state.country"
                :layer="$store.state.layer"
                :at_when="report.current.date.toISOString().substr(0,10)"
                :controls="false"
                :always_show_entire_map="true"
                :content-padding="5"
                :height="'400px'"
                :show_popup="false"
              ></web-sec-map>
              <figcaption>Kaart met veiligheid van {{ $t($store.state.layer) }}. De kleuren van een stoplicht staan voor
                veiligheid. Bij groen is alles veilig, bij rood is er iets onveiligs gevonden. Bekijk de grote versie
                van de kaart verderop.
              </figcaption>

            </figure>
          </b-col>

        </b-row>
      </b-container>


      <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white pressfont page-header">
        <h2 id="conclusion">1: De grote getallen</h2>

        <p>Dit is de samenvatting van alle metingen. Deze worden vergeleken met de meting van {{ days_back }}
          dagen terug.</p>

        <h3>Veiligheid van de
          {{ format_large_number(total_current_organizations) }}
          organisaties: {{ $t($store.state.layer) }}</h3>

        <p>Een organisatie is veilig wanneer alle online dienstverlening als veilig is gemeten.</p>

        <improvement-comparison
          icon="building"
          :value_now="percentage_current_organization_good"
          :value_then="percentage_historic_organization_good"
          :days_back="days_back"
        />

        <h3 class="page-header">Veiligheid van de {{ format_large_number(total_current_urls) }} internet domeinen (zoals .nl)</h3>

        <p>Een domein is veilig wanneer alles wat daarop wordt gemeten veilig is. Denk aan een website,
          bestandsoverdracht of
          veilige e-mail.</p>

        <improvement-comparison
          icon="window"
          :value_now="percentage_current_url_good"
          :value_then="percentage_historic_url_good"
          :days_back="days_back"
        />

      </b-container>

      <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white pressfont page-header">

        <h2 id="map">2: De kaart van toen en nu</h2>
        <p>Deze kaart laat in kleuren zien hoe er wordt gescoord door de verschillende gemeten organisaties.
          Hoe groener de kaart, hoe beter het resultaat. Groen staat voor veilig, rood staat voor een of meerdere
          ernstige zwakke plekken. De overige stoplichtkleuren staan voor minder ernstige zwakheden.
        </p>

        <b-row>
          <b-col>
            <h3>Kaart van {{ humanize_date(report.current.date.toISOString().substr(0, 10)) }}</h3>

            <b-overlay show="show" variant="invisible" :z-index="1000" :opacity="0.00">

              <web-sec-map
                :country="$store.state.country"
                :layer="$store.state.layer"
                :at_when="report.current.date.toISOString().substr(0,10)"
                :controls="false"
                :always_show_entire_map="true"
                :content-padding="5"
                :height="'500px'"
                :show_popup="false"
              ></web-sec-map>

              <template #overlay>&nbsp;</template>

            </b-overlay>


          </b-col>

          <b-col>
            <h3>Kaart {{ days_back }} dagen geleden</h3>

            <b-overlay show="show" variant="invisible" :z-index="1000" :opacity="0.00">
              <web-sec-map
                :country="$store.state.country"
                :layer="$store.state.layer"
                :at_when="report.compared.date.toISOString().substr(0,10)"
                :controls="false"
                :always_show_entire_map="true"
                :content-padding="5"
                :height="'500px'"
                :show_popup="false"
              ></web-sec-map>
              <template #overlay>&nbsp;</template>

            </b-overlay>

          </b-col>

        </b-row>

        <b-row>
          <b-col>
            <h3>Zoek de verschillen</h3>
            <p>Twee kaarten naast elkaar is soms lastig vergelijken. Om dat makkelijker te maken staat hiernaast een
              vergelijking tussen de twee kaarten.</p>
            <p>Zie je geen afbeelding, of bijna niets? Dan zijn er geen verschillen.</p>
            <p></p>

          </b-col>


          <b-col style="min-height: 620px; ">

            <b-overlay show="show" variant="invisible" :z-index="100000" :opacity="0.40"
                       style="filter: invert(100%) hue-rotate(180deg) grayscale(100%); min-height: 620px;">
              <web-sec-map
                style="position: absolute; top:0; left: 0; -webkit-filter: invert(0%) opacity(100%); width: 100%; height: 500px;"
                :country="$store.state.country"
                :layer="$store.state.layer"
                :at_when="report.current.date.toISOString().substr(0,10)"
                :controls="false"
                :always_show_entire_map="true"
                :content-padding="5"
                :height="'500px'"
                :show_popup="false"
              ></web-sec-map>

              <web-sec-map
                style="position: absolute; top:0; left: 0; -webkit-filter: invert(0%) opacity(100%); width: 100%; height: 500px; mix-blend-mode:difference"
                :country="$store.state.country"
                :layer="$store.state.layer"
                :at_when="report.compared.date.toISOString().substr(0,10)"
                :controls="false"
                :always_show_entire_map="true"
                :content-padding="5"
                :height="'500px'"
                :show_popup="false"
              ></web-sec-map>

              <template #overlay>&nbsp;</template>

            </b-overlay>

          </b-col>


        </b-row>
      </b-container>

      <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white pressfont page-header">

        <h2 id="info">3: Informatie over veiligheid, metingen en verantwoording</h2>

        <b-row>

           <b-col sm="6" md="6" lg="6" xl="6">
            <h3>Aanpak</h3>
            <p>
              Deze website meet de hele dag door veiligheid. Iedere nacht wordt een nieuw rapport gemaakt. Nieuwe websites
              worden automatisch gevonden maar ook weer verwijderd als de eigenaar ze weggooit.
            </p>

            <p>De rapporten zijn op de website te vinden onder het kopje 'rapporten' of door op de grote kaart te klikken.</p>

            <p>
              Basisveiligheid geeft een idee hoe goed de rest is beveiligd: alles wat we niet vanaf het internet kunnen zien.
            </p>
          </b-col>


          <b-col sm="6" md="6" lg="6" xl="6">
            <h3>Afwijkingen / Comply or Explain</h3>
            <p>
              Een aantal bekende afwijkingen op afspraken rondom veiligheid worden niet meegeteld. Dit gaat over
              situaties waar een website niet is te onderscheiden van bijvoorbeeld een online telefoniedienst.
            </p>
            <p>
              In die gevallen wordt er een verklaring toegevoegd en wordt de bevinding als neutraal gemarkeerd.
              Afwijkingen doen nooit afbreuk aan de veiligheid: de afwijking is met opzet zo gemaakt en veiligheid
              blijft gewaarboorgd.
            </p>
            <p>Dit gebeurd volgens de <b>Pas Toe of Leg Uit</b> regeling. Een aantal van deze afwijken worden
            automatisch toegepast. Bijvoorbeeld voor telefoniediensten.</p>
          </b-col>


          <b-col sm="6" md="6" lg="6" xl="6">
            <h3>Wat kan je doen?</h3>
            <p>
              Neem contact op met de gemeten organisatie. Uit je zorgen over de staat van de veiligheid en
              vraag wanneer dit wordt verbeterd. De contactinformatie van de gemeten organisatie staat op
              de rapportage-pagina per organisatie. Klik op de grote kaart om deze te zien.
            </p>
          </b-col>


          <b-col sm="6" md="6" lg="6" xl="6">
            <h3>Waarom is iets onveilig?</h3>
            <p>Onveiligheid komt vaak door:</p>
            <ul style="list-style: dashed">
              <li>Geen automatisch proces voor nieuwe diensten en software updates</li>
              <li>Vergeten projecten en websites die verouderen</li>
              <li>Nog onvoldoende capaciteit of kennis voor het (wegautomatiseren) van veiligheid</li>
            </ul>

          </b-col>

          <b-col sm="6" md="6" lg="6" xl="6">
            <h3>Over dit project</h3>
            <p>Deze website meet de veiligheid van een groot aantal organisaties. Het doel is dat deze organisaties
              inzicht krijgen in veiligheid en al hun diensten volledig veilig maken voor iedereen.
            </p>
          </b-col>

          <b-col sm="6" md="6" lg="6" xl="6">
            <h3>Verantwoording</h3>
            <p>Gegegevens van: {{ humanize_date(report.current.date.toISOString().substr(0, 10)) }}.</p>
            <p>Historische gegevens van: {{ humanize_date(report.compared.date.toISOString().substr(0, 10)) }}.</p>
            <p>Metingen worden automatisch uitgevoerd en regelmatig herhaald.</p>
            <p><i>Deze pagina samengesteld met Web Security Map van de Internet Cleanup Foundation.</i></p>
          </b-col>

        </b-row>

      </b-container>

      <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white pressfont page-header">

        <h2 id="appendixa">Bijlage A: Metingen en betekenissen</h2>

        <p class="">
          De volgende tabel toont de verschillende metingen met een eenvoudige uitleg.
        </p>

        <b-row>
          <b-col>
            <h4>Meting</h4>
          </b-col>

          <b-col cols="2">
            <h4>Verdeling</h4>
          </b-col>

          <b-col cols="3">
            <h4>Trendlijn ({{ days_back }} dagen)</h4>
          </b-col>
        </b-row>


        <template v-for="issue in $store.state.issues">

          <div :key="issue.name"
               v-if="historic_issue_stats[issue['name']] && $store.state.config.show.issues[issue['name']]">

            <b-row>
              <b-col>
                <h4>{{ $t('friendly_issue_translations.' + issue.name + '.title') }}</h4>
              </b-col>
            </b-row>

            <b-row :key="issue.name" v-if="historic_issue_stats[issue['name']]">
              <b-col>
                <p class="friendly_explanation"
                   v-html="$t('friendly_issue_translations.' + issue.name + '.explanation')"></p>


                <template v-for="category in categories_list(issue.name)">
                  <b-badge variant="info" pill :key="category" style="margin-right: 0.2em; font-size:0.6em">{{
                      category
                    }}
                  </b-badge>
                </template>

              </b-col>

              <b-col cols="2">
                <div class="chart-container" style="position: relative; height:120px; width:100%">
                  <vulnerability-donut-chart
                    :color_scheme="$store.state.color_scheme"
                    type="pie"
                    :data="historic_issue_stats[issue['name']]"
                    :axis="issue['relevant impacts']"
                    :display_legend="false"
                    :display_title="false"
                    :display_x_scale="false"
                    :translation="$i18n.t('vulnerability_donut')"
                  ></vulnerability-donut-chart>
                </div>

              </b-col>
              <b-col cols="3">
                <div class="chart-container" style="position: relative; height:120px; width:100%">
                  <vulnerability-line-chart
                    :color_scheme="$store.state.color_scheme"
                    :data="historic_issue_stats[issue['name']]"
                    :display_legend="false"
                    :display_title="false"
                    :display_x_scale="false"
                    :display_y_scale="false"

                    :axis="issue['relevant impacts']"
                    :translation="$i18n.t('vulnerability_donut')"
                  ></vulnerability-line-chart>
                </div>
              </b-col>

            </b-row>

          </div>
        </template>
      </b-container>


      <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white pressfont page-header">
        <h2 id="appendixb">Bijlage B: Grote versie van de kaart</h2>

        <p>Op de kaart worden drie kleuren gebruikt, zoals bij een stoplicht: rood, oranje of
          groen.
        <p>
        <p>Rood
          betekent dat er een
          kwetsbaarheid is gemeten. Bij groen is alles veilig. Ieder vlak of stip staat voor een organisaties in de
          categorie: {{ $t($store.state.layer) }}.
        </p>
        <p>Een stip met
          cijfer betekent dat organisaties zijn samengevoegd. Op de hoofdkaart op de website kan je hierop inzoomen en
          zien welke organisaties op dat adres zitten.
        </p>

            <web-sec-map
          :country="$store.state.country"
          :layer="$store.state.layer"
          :at_when="report.current.date.toISOString().substr(0,10)"
          :controls="false"
          :always_show_entire_map="true"
          :content-padding="5"
          :height="'800px'"
          :show_popup="false"
        ></web-sec-map>


      </b-container>

      <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white pressfont page-header">
        <h2 id="appendixc">Bijlage C: Overzichtstabel en aantal metingen</h2>

        <h3>Overzichtstabel</h3>

        <b-table-simple hover striped responsive>
          <b-tr>
            <b-th>&nbsp;</b-th>
            <b-th colspan="3" class="border-left">
              Nu
            </b-th>
            <b-th colspan="3" class="border-left border-right">
              {{ days_back }} dagen geleden
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>
              &nbsp;
            </b-th>
            <b-th class="border-left">
              Percentage veilig
            </b-th>
            <b-th>
              Aantal veilig
            </b-th>
            <b-th>
              Totaal
            </b-th>
            <b-th  class="border-left">
              Percentage veilig
            </b-th>
            <b-th>
              Aantal veilig
            </b-th>
            <b-th class="border-right">
              Totaal
            </b-th>
          </b-tr>

          <b-tr>

            <b-th>
              Organisaties
            </b-th>
            <b-td class="border-left">
              {{ percentage_current_organization_good }}%
            </b-td>
            <b-td>
              {{ format_large_number(current_organization_stats[0]['good']) }}
            </b-td>
            <b-td>
               {{ format_large_number(total_current_organizations) }}
            </b-td>
            <b-td class="border-left">
              {{ percentage_historic_organization_good }}%
            </b-td>
            <b-td>
              {{ format_large_number(historic_organization_stats[0]['good']) }}
            </b-td>
            <b-td class="border-right">
                {{ format_large_number(total_historic_organizations) }}
            </b-td>

          </b-tr>

          <b-tr>

            <b-th>
              Domeinen
            </b-th>
            <b-td class="border-left">
              {{ percentage_current_url_good }}%
            </b-td>
            <b-td>
              {{ format_large_number(current_url_stats[0].good) }}
            </b-td>
            <b-td>
               {{ format_large_number(total_current_urls) }}
            </b-td>
            <b-td class="border-left">
              {{ percentage_historic_url_good }}%
            </b-td>
            <b-td>
              {{ format_large_number(historic_url_stats[0].good) }}
            </b-td>
            <b-td class="border-right">
                {{ format_large_number(total_historic_urls) }}
            </b-td>

          </b-tr>


          <b-tr>

            <b-th>
              Metingen
            </b-th>
            <b-td class="border-left">
              {{ percentage_current_issues_good }}%
            </b-td>
            <b-td>
              {{ format_large_number(historic_issue_stats.total[historic_newest].ok) }}
            </b-td>
            <b-td>
               {{ format_large_number(total_current_issues) }}
            </b-td>
            <b-td class="border-left">
              {{ percentage_historic_issues_good }}%
            </b-td>
            <b-td>
              {{ format_large_number(historic_issue_stats.total[0].ok) }}
            </b-td>
            <b-td class="border-right">
                {{ format_large_number(total_historic_issues) }}
            </b-td>

          </b-tr>
        </b-table-simple>
        <br><br>
        <h3>Veiligheid van de {{ format_large_number(total_current_issues) }} meetresultaten</h3>

        <improvement-comparison
          icon="terminal"
          :value_now="percentage_current_issues_good"
          :value_then="percentage_historic_issues_good"
          :days_back="days_back"
        />

      </b-container>

      <b-container class="page-header do-not-print">
        <back-to-top />
      </b-container>

    </template>
  </div>
</template>
<script>

import TranslationsFromConfigMixin from "@/components/TranslationsFromConfigMixin";
import BackToTop from "@/components/BackToTop";
import WebSecMap from "@/components/map/WebSecMap";
import http from "@/httpclient";
import VulnerabilityDonutChart from "@/components/charts/VulnerabilityDonutChart";
import VulnerabilityLineChart from "@/components/charts/VulnerabilityLineChart";
import {subDays} from 'date-fns'
import ImprovementComparison from "@/components/report/ImprovementComparison";
import LandingPageMixin from "@/components/LandingPageMixin";

export default {
  components: {
    ImprovementComparison,
    VulnerabilityLineChart, VulnerabilityDonutChart, BackToTop, WebSecMap
  },
  mixins: [TranslationsFromConfigMixin, LandingPageMixin],

  data() {
    return {
      // data sources to retrieve for the final report
      to_load: 3,
      loaded: 0,

      // data sources
      current_organization_stats: [],
      current_url_stats: [],
      historic_organization_stats: [],
      historic_url_stats: [],
      current_issue_stats: [],
      historic_issue_stats: [],

      // reporting
      days_back: 31,
      report: {
        // A total of N points of time should be supported. This will make it possible to create a timeline for
        // each of the metrics.
        current: {
          date: new Date(),
        },
        // should it be possible to compare multiple reports? Can people still
        // understand multiple comparisons? no...
        compared: {
          // automatically filled by days back
          date: new Date(),
        }
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    supportCustomDateArgument() {
      // default = first of the current month
      let currDate = new Date();
      this.report.current.date = new Date(currDate.getFullYear() + "-" + (currDate.getMonth() + 1))

      // console.log(this.$route.params.date)

      if (!this.$route.params.date) {
        return
      }

      // the toIsostring will crash when an unparseable date is given
      try {
        let my_date = new Date(this.$route.params.date)
        my_date = new Date(my_date.toISOString().substr(0, 10))
        this.report.current.date = my_date
      } catch (e) {
        // just another nonsene date, ignore and just use the default as set above
        console.log(e)
      }
    },

    supportCustomDaysBackArgument() {
      if (!this.$route.params.days_back)
        return

      let input = parseInt(this.$route.params.days_back)

      if (input > 0)
        this.days_back = input
    },

    setup_compared_date() {
      if (this.days_back) {
        this.report.compared.date = subDays(this.report.current.date, this.days_back);
      }
    },


    load() {
      this.supportCustomDateArgument()
      this.supportCustomDaysBackArgument()
      this.setup_compared_date()

      this.to_load = 3;
      this.loaded = 0;

      http.get(`/data/stats/${this.$store.state.country}/${this.$store.state.layer}/${this.report.current.date.toISOString().substr(0, 10)}/1/`).then(data => {

        if (Object.keys(data.data).length < 1) {
          this.current_organization_stats = [];
          this.current_url_stats = [];
          return;
        }

        // [ { "high": 278, "medium": 39, "good": 40, "date": "2022-02-01" } ]
        this.current_organization_stats = data.data.organizations.reverse();

        this.current_url_stats = data.data.urls.reverse();
        this.loaded += 1;
      });

      http.get(`/data/stats/${this.$store.state.country}/${this.$store.state.layer}/${this.report.current.date.toISOString().substr(0, 10)}/${this.days_back}/`).then(data => {

        if (Object.keys(data.data).length < 1) {
          this.historic_organization_stats = [];
          this.historic_url_stats = [];
          return;
        }

        // [ { "high": 278, "medium": 39, "good": 40, "date": "2022-02-01" } ]
        this.historic_organization_stats = data.data.organizations.reverse();

        this.historic_url_stats = data.data.urls.reverse();
        this.loaded += 1;
      });

      // https://basisbeveiliging.nl/data/vulnerability_graphs/NL/province/2022-02-02/1/
      /*
          "tls_qualys_encryption_quality": [
            {
              "high": 2,
              "medium": 0,
              "low": 136,
              "date": "2022-02-01",
              "urls": 0,
              "ok_urls": 0,
              "endpoints": 1156,
              "ok_endpoints": 725,
              "ok": 725
            },
            {
              "high": 2,
              "medium": 0,
              "low": 136,
              "date": "2022-02-02",
              "urls": 0,
              "ok_urls": 0,
              "endpoints": 1155,
              "ok_endpoints": 726,
              "ok": 726
            }
          ],
          "total": [
            {
              "high": 240,
              "medium": 403,
              "low": 3312,
              "date": "2022-02-01",
              "urls": 880,
              "ok_urls": 15,
              "endpoints": 2184,
              "ok_endpoints": 4501,
              "ok": 4516
            },
            {
              "high": 239,
              "medium": 402,
              "low": 3310,
              "date": "2022-02-02",
              "urls": 882,
              "ok_urls": 15,
              "endpoints": 2181,
              "ok_endpoints": 4492,
              "ok": 4507
            }
          ]
        }
       */
      // Do not get current and historic data in two calls...
      // this gets the data on an exact date, which is efficient.
      //http.get(`/data/vulnerability_graphs/${this.$store.state.country}/${this.$store.state.layer}/${this.report.current.date.toISOString().substr(0, 10)}/0/`).then(data => {

      //  if (Object.keys(data.data).length < 1) {
      //    console.log("no current vulnerability_graphs data");
      //    return
      //  }

      //  this.current_issue_stats = data.data;
      //  this.loaded += 1;
      //});
      http.get(`/data/vulnerability_graphs/${this.$store.state.country}/${this.$store.state.layer}/${this.report.current.date.toISOString().substr(0, 10)}/${this.days_back}/`).then(data => {

        if (Object.keys(data.data).length < 1) {
          console.log("no past vulnerability_graphs data")
          return
        }

        this.historic_issue_stats = data.data;
        this.loaded += 1;
      })
    },

  },
  watch: {
    '$store.state.country': {
      deep: false,
      handler() {
        this.load()
      }
    },
    '$store.state.layer': {
      deep: false,
      handler() {
        this.load()
      }
    },
  },
  computed: {

    loading() {
      return this.to_load !== this.loaded;
    },

    historic_newest(){
      // This helps with the 'best effort' render of stats on the page. It might be that for the current date stats
      // are missing for whatever reason. So then use the first available set of data available.
      // console.log("length")
      // console.log(this.historic_issue_stats.total.length)
      // console.log(this.historic_issue_stats.total[this.historic_issue_stats.total.length-1].date)
      // console.log(this.historic_issue_stats.total[0].date)
      return this.historic_issue_stats.total.length-1
    },

    percentage_current_organization_good() {
      return parseInt(this.percentage(this.current_organization_stats[0]['good'], this.current_organization_stats[0]['high'] + this.current_organization_stats[0]['medium'] + this.current_organization_stats[0]['good'], 0, ''))
    },
    percentage_current_url_good() {
      return parseInt(this.percentage(this.current_url_stats[0].good, this.current_url_stats[0].high + this.current_url_stats[0].medium + this.current_url_stats[0].good, 0, ''))
    },
    percentage_current_issues_good() {
      return parseInt(this.percentage(this.historic_issue_stats.total[this.historic_newest].ok, this.historic_issue_stats.total[this.historic_newest].high + this.historic_issue_stats.total[this.historic_newest].medium + this.historic_issue_stats.total[this.historic_newest].low + this.historic_issue_stats.total[this.historic_newest].ok, 0, ''))
    },
    percentage_historic_organization_good() {
      return parseInt(this.percentage(this.historic_organization_stats[0]['good'], this.historic_organization_stats[0]['high'] + this.historic_organization_stats[0]['medium'] + this.historic_organization_stats[0]['good'], 0, ''))
    },
    percentage_historic_url_good() {
      return parseInt(this.percentage(this.historic_url_stats[0].good, this.historic_url_stats[0].high + this.historic_url_stats[0].medium + this.historic_url_stats[0].good, 0, ''))
    },
    percentage_historic_issues_good() {
      return parseInt(this.percentage(this.historic_issue_stats.total[0].ok, this.historic_issue_stats.total[0].high + this.historic_issue_stats.total[0].medium + this.historic_issue_stats.total[0].low + this.historic_issue_stats.total[0].ok, 0, ''))
    },
    difference_organization_good() {
      return this.percentage_current_organization_good - this.percentage_historic_organization_good
    },
    difference_url_good() {
      return this.percentage_current_url_good - this.percentage_historic_url_good
    },
    difference_issues_good() {
      return this.percentage_current_issues_good - this.percentage_historic_issues_good
    },
    total_current_organizations() {
      return this.current_organization_stats[0]['high'] + this.current_organization_stats[0]['medium'] + this.current_organization_stats[0]['good']
    },
    total_historic_organizations() {
      return this.historic_organization_stats[0]['high'] + this.historic_organization_stats[0]['medium'] + this.historic_organization_stats[0]['good']
    },
    total_current_urls() {
      return this.current_url_stats[0].high + this.current_url_stats[0].medium + this.current_url_stats[0].good
    },
    total_historic_urls() {
      return this.historic_url_stats[0].high + this.historic_url_stats[0].medium + this.historic_url_stats[0].good
    },
    total_current_issues() {
      return this.historic_issue_stats.total[this.historic_newest].high + this.historic_issue_stats.total[this.historic_newest].medium + this.historic_issue_stats.total[this.historic_newest].low + this.historic_issue_stats.total[this.historic_newest].ok
    },
    total_historic_issues() {
      return this.historic_issue_stats.total[0].high + this.historic_issue_stats.total[0].medium + this.historic_issue_stats.total[0].low + this.historic_issue_stats.total[0].ok
    },
  }
}
</script>
<i18n>
{
  "en": {
    "title": "About {0}",
    "vulnerability_donut": {
      "title": "Today's issue in this category",
      "xAxis_label": "Month",
      "yAxis_label": "Risk"
    }
  },
  "nl": {
    "title": "Over {0}"
  }
}

</i18n>
