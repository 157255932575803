<style>
.report_controls {
  float: right
}


.total_awarded_points {
  padding: 1px 4px;
  font-weight: bold;
  border-radius: 6px;
  margin-right: 5px;

}

.faildomain {
  font-size: 1.7em;

}

</style>

<style scoped>
.page-break {
  page-break-before: always;
}
</style>
<template>
  <div id="report_content">

    <content-block v-if="loading">
      <loading-spinner :loading="loading"></loading-spinner>
    </content-block>

    <template v-if="!loading && (reported_organization.id || reported_organization.name)">
      <content-block class="stats_part p-3 mb-3 rounded bg-white">

        <div class="report_controls">
          <b-button class="btn btn-secondary" variant="secondary" v-if="$store.state.config.show.send_in_new_domains"
                    :href="'mailto:' + $store.state.config.send_in_new_domains.email_address + '?subject=' + encodeURIComponent($store.state.config.send_in_new_domains.email_subject) + '&body=' + encodeURIComponent($store.state.config.send_in_new_domains.email_body)">
            <b-icon icon="envelope"/>
            {{ $t("controls.send_in_domains") }}
          </b-button>
        </div>

        <template v-if="name">

          <div class="page-header">
            <h2>{{ $t("report_of") }} {{ name }}</h2>
            <p>{{ $t("data_from") }} {{ humanize_relative_date(when) }}, {{humanize_date(when)}}.</p>
          </div>

          <ReportScoreBadges :scores="scores" v-if="$store.state.config.show.report_numbers" />

          <ShareCardReport class="do-not-print" v-if="!$store.state.time_machine_date"
            :number_of_high_risk_issues="scores.high"
            :organization_name="name"
            :report_id="reported_organization.id"
            :twitter_username="reported_organization.twitter_handle ? reported_organization.twitter_handle : ''"
          />

        </template>
                <plus-info />

        <div v-if="!urls.length" class="perurl" :class="`report_url_background_good`">
          {{ $t("no_data") }}
        </div>


      </content-block>

      <content-block class="stats_part p-3 mb-3 rounded bg-white">
        <template v-if="$store.state.config.show.report_content">
          <b-tabs lazy>

            <b-tab :title="$t('detailed_reports')" class="pt-2">
              <ReportFindingsFilter :urls="urls"/>
            </b-tab>

            <b-tab :title='$t("risksummary.title")' v-if='$store.state.config.show.report_risk_summary'>
              <div class="table-responsive-md">
                <risksummary :issues_list="ordered_visible_issue_names" :urls="urls"/>
              </div>
            </b-tab>

            <b-tab :title="$t('testrsultsummarygraph')" class="pt-2" v-if='$store.state.config.show.report_charts'>
              <p class="page-break">{{ $t("timeline.title") }}</p>
              <ReportOrganizationChart :organization_id="reported_organization.id" :days_back="365"/>
            </b-tab>

            <b-tab :title="$t('website_gallery')"  class="pt-2" v-if="$store.state.config.show.report_website_gallery">
              <WebsitePortfolio :urls="urls" />
            </b-tab>
          </b-tabs>
        </template>
      </content-block>
    </template>

  </div>
</template>

<script>

import http from "@/httpclient";
import plusInfo from "@/components/PlusInfo";
import Risksummary from "@/components/report/RiskSummaryTable";
import ReportOrganizationChart from "@/components/report/ReportOrganizationChart";
import ReportScoreBadges from "@/components/report/ReportScoreBadges";
import ReportFindingsFilter from "@/components/report/ReportFindingsFilter";
import WebsitePortfolio from "@/components/report/WebsitePortfolio";
import TimeMachineMixin from "@/components/TimeMachineMixin";
import ShareCardReport from "@/components/socials/ShareCardReport";

export default {
  mixins: [TimeMachineMixin],

  components: {
    ShareCardReport,
    WebsitePortfolio,
    ReportFindingsFilter,
    ReportScoreBadges,
    ReportOrganizationChart,
    Risksummary, plusInfo
  },

  data: function () {
    return {
      scores: {
        high: 0,
        medium: 0,
        low: 0,
      },
      when: 0,
      twitter_handle: '',
      name: "",
      urls: [],
      selected: {'id': null, 'label': null, 'name': null},

      loading: false,

    }
  },

  props: {
    organization: [String, Number],
  },

  methods: {

    reset: function () {
      this.when = 0;
      this.twitter_handle = '';
      this.name = null;
      this.urls = [];
      this.selected = {'id': null, 'label': null, 'name': null};
      this.loading = false;

      this.scores = {
        high: 0,
        medium: 0,
        low: 0,
      }
    },

    load: function () {
      // against symptom of autoloading when setting state, this doesn't have the right parameters.
      if (!this.reported_organization.id && !this.reported_organization.name) {
        this.reset();
        return;
      }
      this.loading = true;

      let data_url = ""
      if (this.$store.state.time_machine_date) {
        data_url = `/data/report/${this.$store.state.country}/${this.$store.state.layer}/${this.reported_organization.id}/${this.$store.state.time_machine_date}/`;
      } else {
        data_url = `/data/report/${this.$store.state.country}/${this.$store.state.layer}/${this.reported_organization.id}/0`;
      }

      http.get(data_url).then((data) => {
        data = data.data;

        this.urls = data.calculation["organization"]["urls"];

        this.scores = {
          high: data.calculation["organization"]["high"],
          medium: data.calculation["organization"]["medium"],
          low: data.calculation["organization"]["low"],
        }
        this.when = data.when;
        this.name = data.name;
        this.twitter_handle = data.twitter_handle;
        this.slug = data.slug;
        this.selected = {id: data.id, name: data.slug};

        // Easy report sharing
        let newHash = '/report/' + data.id;
        history.replaceState({}, '', '#' + newHash);

        this.show_confetti_on_zero_issues()
        this.loading = false;
      });
    },
    idize: function (url) {
      url = url.toLowerCase();
      return url.replace(/[^0-9a-z]/gi, '')
    },

    show_confetti_on_zero_issues(){
      // let's not count 0 to make life a bit more easy.
      this.$store.state.show_confetti = this.scores.high + this.scores.medium === 0
    }

  },

  watch: {
    reported_organization() {
      this.load()
    },

  },

  mounted: function () {
    // when a report is directly requested via the url string
    this.load();
  },

  computed: {
    reported_organization () {
      return this.$store.state.reported_organization
    },
  }
};
</script>
<i18n>
{
  "en": {
    "controls": {
      "send_in_domains": "Send in more domains"
    },
    "detailed_reports": "Detailed Reports",
    "website_gallery": "Website Gallery",
    "report_of": "Report of",
    "data_from": "Data from",
    "timeline": {
      "title": "Timeline of risks and available services"
    },
    "testrsultsummarygraph": "Test result history",
    "risksummary": {
      "title": "Test result summary",
      "no_data": "This report contains no data / urls."
    },
    "title": "Measurements per domain",
    "no_data": "This report contains no data / urls."
  },
  "nl": {
    "controls": {
      "send_in_domains": "Domeinen insturen"
    },
    "detailed_reports": "Detailrapportage",
    "website_gallery": "Website Gallerij",
    "report_of": "Rapport van",
    "data_from": "Gegevens van",
    "timeline": {
      "title": "Samenvatting van de testresultaten van het afgelopen jaar"
    },
    "testrsultsummarygraph": "Testresultaat historie",
    "risksummary": {
      "title": "Samenvatting testresultaten",
      "no_data": "Dit rapport bevat geen gegevens."
    },
    "title": "Metingen per domein",
    "no_data": "Dit rapport bevat geen gegevens."
  }
}
</i18n>
