<template>
  <div>
    <loading-spinner :loading="loading"></loading-spinner>

    <template v-if="data.length === 0">
      <b-alert show variant="info">{{ $t('no_data_found') }}</b-alert>
    </template>
    <template v-else>

      <b-row><b-col>
        <b-pagination v-if="data.length > perPage"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>

      </b-col>
      <b-col>
      <b-form-group

        label-for="filter-input"
        label-cols-sm="0"
        label-align-sm="left"
        label-size="sm"
        class="m-2"
      >
        <b-input-group size="sm" prepend="🔍">

          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            :placeholder="$t('type_to_search')"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">↩️</b-button>
          </b-input-group-append>

        </b-input-group>
      </b-form-group>
      </b-col></b-row>

      <b-table v-if="data"
               :responsive="'sm'"
               :no-border-collapse="true"
               :items="data"
               :fields="fields"
               :busy="loading"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               :per-page="perPage"
               :current-page="currentPage"
               :filter="filter"
               :filter-included-fields="filterOn"
               striped hover
               small
      >
        <template #cell(last_scan_moment)="data">
          <template v-if="data.item.last_scan_moment">
            {{ humanize_relative_date(data.item.last_scan_moment) }}
          </template>
        </template>
        <template #cell(rating_determined_on)="data">
          <template v-if="data.item.rating_determined_on">
            {{ humanize_relative_date(data.item.rating_determined_on) }}
          </template>
        </template>
        <template #table-caption>{{ $t('limited to 1000 findings') }}</template>
      </b-table>


    </template>
  </div>

</template>

<script>
import http from "@/httpclient";

import country_layer_switch_mixin from "@/CountryLayerSwitchMixin";

export default {
  mixins: [country_layer_switch_mixin],

  mounted: function () {
    this.load()
  },

  data: function () {
    return {
      perPage: 25,
      currentPage: 1,

      filter: "",
      filterOn: ['url_url'],

      data: Array,
      loading: false,
      sortBy: 'rating_determined_on',
      sortDesc: false,
      fields: [
        // {key: "organization_name", sortable: true, label: 'Organization'},
        // {key: "url_url", sortable: true, label: this.$i18n.t('url_url')},
        {key: "subdomain", sortable: true, label: this.$i18n.t('subdomain')},
        {key: "domain", sortable: true, label: this.$i18n.t('domain')},
        // {key: "severity", sortable: true, label: 'Severity'},
        {key: "last_scan_moment", sortable: true, label: this.$i18n.t('last_scan_moment')},
        {key: "rating_determined_on", sortable: true, label: this.$i18n.t('rating_determined_on')}
      ],
    }
  },

  props: {
    issue_type: {type: String, required: true}
  },
  computed: {
    rows() {
      return this.data ? this.data.length : 0
    }
  },

  methods: {
    load: function () {
      this.loading = true;
      http.get(`/data/stats/what_to_improve/${this.$store.state.country}/${this.$store.state.layer}/${this.issue_type}/`).then(data => {
        this.data = data.data;
        this.loading = false;
      });
    },
  }
};
</script>

<i18n>
{
  "en": {
    "url_url": "Domains",
    "domain": "Domain",
    "subdomain": "Subdomain",
    "last_scan_moment": "Last scanned",
    "rating_determined_on": "Measured since",
    "no_data_found": "Nothing found to improve!",
    "limited to 1000 findings": "Number of findings limited to 500. The total number of rows in the table might not be equal to the graph as they are created on different moments and measuring happens continuously.",
    "type_to_search": "Type to search"
  },
  "nl": {
    "url_url": "Domeinen",
    "domain": "Domein",
    "subdomain": "Subdomein",
    "last_scan_moment": "Laatste scan",
    "rating_determined_on": "Gemeten sinds",
    "no_data_found": "Niets gevonden om te verbeteren!",
    "limited to 1000 findings": "Aantal bevindingen gelimiteerd tot 500 stuks. Het aantal rijen in de tabel kan ongelijk zijn aan de grafieken omdat deze op verschillende momenten worden gemaakt. Er wordt continu gemeten, hierdoor ontstaan afwijkingen.",
    "type_to_search": "Begin hier met zoeken..."
  }
}
</i18n>
