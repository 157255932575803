var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Location search API","label-for":"input-search","description":"Find a location by typing below. This will retrieve suggestions from the Google places API. You can\n          also override any result below."}},[_c('gmap-autocomplete',{staticClass:"rounded",on:{"place_changed":_vm.placed}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('GmapMap',{staticStyle:{"width":"500px","height":"500px"},attrs:{"center":{lat:_vm.startpositions[_vm.$store.state.country][1], lng:_vm.startpositions[_vm.$store.state.country][0]},"zoom":7,"map-type-id":"terrain","options":{
             zoomControl: true,
             mapTypeControl: false,
             scaleControl: true,
             streetViewControl: false,
             rotateControl: false,
             fullscreenControl: true,
             disableDefaultUi: false
           }}},[(_vm.latitude && _vm.longitude)?_c('Gmap-Marker',{attrs:{"label":"&#x2605;","draggable":true,"position":{
              lat: _vm.latitude,
              lng: _vm.longitude,
            }},on:{"dragend":_vm.updateCoordinates}}):_vm._e()],1)],1),_c('b-col',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Name","label-for":"input-organization","description":"Autofilled, find an organization by typing in the search field above"}},[_c('b-form-input',{attrs:{"id":"input-organization","placeholder":"Name","state":_vm.name.length > 0,"required":"","disabled":_vm.nameDisabled},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Address","label-for":"input-address","description":"Autofilled, find an organization by typing in the search field above"}},[_c('b-form-input',{attrs:{"id":"input-address","placeholder":"Address","required":"","disabled":_vm.nameDisabled},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Longitude","label-for":"input-longitude","description":"Autofilled, find an organization by typing in the search field above"}},[_c('b-form-input',{attrs:{"id":"input-longitude","placeholder":"Longitude","state":_vm.longitude > 0,"required":""},model:{value:(_vm.longitude),callback:function ($$v) {_vm.longitude=$$v},expression:"longitude"}})],1),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Latitude","label-for":"input-latitude","description":"Autofilled, find an organization by typing in the search field above."}},[_c('b-form-input',{attrs:{"id":"input-latitude","placeholder":"Latitude","state":_vm.latitude > 0,"required":""},model:{value:(_vm.latitude),callback:function ($$v) {_vm.latitude=$$v},expression:"latitude"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }