<template>
  <div class="pt-4">
    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
      <h2>
        <b-icon icon="cloud-download"/>
        {{ $t("title") }}
      </h2>
      <p>{{ $t("intro") }}</p>

      <div class="row" v-for="country in countries" :key="country">

        <b-table-simple class="table table-striped table-hover responsive">
          <thead>
          <tr>
            <th colspan="2">

              <b-icon icon="layers"/>
              {{ $t('layer') }}

            </th>
            <th>
              <b-icon icon="compass"/>
              {{ $t("only_urls") }}
            </th>
            <!--
            <th>
              <b-icon icon="diagram2"/>
              {{ $t("organization_types") }}
            </th>
            -->
            <th>
              <b-icon icon="building"/>
              {{ $t("organizations") }}
            </th>

            <!--
            <th>
              <b-icon icon="compass"/>
              {{ $t("map_data") }}
            </th>
            -->

            <!-- <th>
              <b-icon icon="compass"/>
              {{ $t("urls") }}
            </th> -->

            <th v-if="$store.state.config.show.comply_or_explain">
              <b-icon icon="chat-left-dots"/>
              {{ $t("explanations") }}
            </th>
          </tr>
          </thead>
          <tbody>

          <template v-for="layer in layers_from_country(country)">

            <tr :key="layer">
              <th colspan="2">
                {{ translate(layer) }}
              </th>


              <td>
              <span v-for="format in supported_formats" :key="format" class="nobreak">
                <b-badge :href="create_link(layer, 'urls_only', format)" class="mr-1" variant="success">{{
                    format
                  }}</b-badge>
              </span>
              </td>

              <!--
              <td>
              <span v-for="format in supported_formats" :key="format">
                <b-badge :href="create_link(layer, 'organization_types', format)" class="mr-1"
                         variant="success">{{ format }}</b-badge>
              </span>
              </td>
              -->


              <td>
              <span v-for="format in supported_formats" :key="format" class="nobreak">
                <b-badge :href="create_link(layer, 'organizations', format)" class="mr-1" variant="success">{{
                    format
                  }}</b-badge>
              </span>
              </td>


              <!--
              <td>
              <span v-for="format in supported_formats" :key="format">
                <b-badge :href="create_link(layer, 'coordinates', format)" class="mr-1" variant="success">{{
                    format
                  }}</b-badge>
              </span>
              </td>
              -->


              <!-- <td>
              <span v-for="format in supported_formats" :key="format" class="nobreak">
                <b-badge :href="create_link(layer, 'urls', format)" class="mr-1" variant="success">{{
                    format
                  }}</b-badge>
              </span>
              </td>
              -->


              <td>
              <span v-for="format in ['json']" :key="format" class="nobreak">
                <b-badge :href="create_link(layer, 'explains', format)" class="mr-1" variant="success">{{
                    format
                  }}</b-badge>
              </span>
              </td>
            </tr>

          </template>

          </tbody>
        </b-table-simple>
        <br/><br/>

      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      // we also support archaic formats like  "mediawiki" and "latex"
      supported_formats: ["json", "csv", "ods", "xlsx"]
    }
  },
  methods: {
    create_link: function (layer, linktype, filetype = "json") {
      return `/data/export/${linktype}/${this.$store.state.country}/${layer}/${filetype}/`;
    },
  },
};
</script>
<i18n>
{
  "en": {
    "title": "Datasets",
    "intro": "These datasets contain all data currently displayed on this website. These datasets are suitable for automatic processing and importing in other sites like this.",
    "only_urls": "Domains",
    "urls": "Domains with relations",
    "organizations": "Organizations",
    "map_data": "Map data",
    "organization_types": "Organization types",
    "map_compatibility": "These datasets can be imported in another installation of Web Security Map.",
    "explanations": "Explanations",
    "layer": "Map"
  },
  "nl": {
    "title": "Gegevens",
    "intro": "Download de gegevensbundels die worden gebruikt op deze site. Deze bundels zijn geschikt voor automatische verwerking. Zo kunnen bijvoorbeeld beheerders van dit soort sites deze gegevens importeren.",
    "only_urls": "Domeinen",
    "urls": "Domeinen met relaties",
    "organizations": "Organisaties",
    "map_data": "Kaartgegevens",
    "organization_types": "Organisatie typen",
    "map_compatibility": "Deze gegevens kunnen worden geimporteerd in een nieuwe installatie van Web Security Map",
    "explanations": "Verklaringen",
    "layer": "Kaart"
  }
}
</i18n>
<style scoped>

a:visited {
  color: var(--dark)
}

.nobreak{
  white-space: nowrap;
}

</style>
