<template>
  <span :class="'scorebadge score ' + (value > 0 ? subject : 'good')" class="mr-2 mb-2">
    <span :class="'score_value ' + (value ? subject + '_text' : 'good_text')">{{ value }}</span>
    <span class="score_label">{{ $t(this.$store.state.config.grading_policy + '_score_' + subject) }}</span>
  </span>
</template>
<script>
export default {
  props: {
    value: {type: Number, default: 0},
    subject: {type: String, default: "high"}
  }
}
</script>
<style scoped>

.scorebadge{
  display: inline-block;
}

.score {
    border-radius: 6px;
    text-align: center;
    padding: 0.5em;
}

.score_label {
    font-size: 1.3em;
    color: white;
}

.score_value {
    min-width: 2em;
  display: inline-block;
    font-size: 1.57em;
    background-color: #ffffffcf;
    border-radius: 4px;
    padding: 4px;
    margin-right: 0.3em;
}
</style>
