<template>
    <v-select :options="sorted_options" style="z-index:100000" :filter="fuseSearch" :placeholder="'🔍 ' + $t('placeholder')" v-model="selected">
      <template v-slot:option="option">
        <b-badge variant="danger" v-if="$conf.app.relevant_for_ui.high" class="inlinebadge">{{ option.high }}</b-badge><span v-if="$conf.app.relevant_for_ui.high"> &nbsp;</span>
        <b-badge variant="warning" v-if="$conf.app.relevant_for_ui.medium" class="inlinebadge">{{ option.medium }}</b-badge><span v-if="$conf.app.relevant_for_ui.medium"> &nbsp;</span>
        <b-badge variant="success" v-if="$conf.app.relevant_for_ui.low" class="inlinebadge">{{ option.low }}</b-badge><span v-if="$conf.app.relevant_for_ui.low"> &nbsp;</span>
        <b-badge variant="success" v-if="$conf.app.relevant_for_ui.good" class="inlinebadge">{{ option.good }}</b-badge>
        {{ option.name }} - 🗺️ {{ $t(option.layer) }} - {{ $t(option.country) }}
      </template>
    </v-select>
</template>

<script>
// This only works after all map data has loaded, so only on the multi-map page.

import Fuse from 'fuse.js'

export default {
  name: "SystemWideSearch",

  data() {
    return {
      selected: '',
    }
  },

  methods: {
    fuseSearch(options, search) {

      // we advise to search via domain name, but that will not work, people should be able to type in an url and
      // get decent results
      search = search.replace("https://", "")
      search = search.replace("http://", "")
      search = search.replace("www", " ")
      search = search.replace(".", " ")
      search = search.replace("/", " ")
      search = search.replace("#", " ")
      search = search.replace(".nl", " ")
      search = search.replace(".com", " ")
      search = search.replace(".net", " ")
      search = search.replace(".org", " ")

      const fuse = new Fuse(options, {
        keys: ['name', 'additional_keywords', 'alternative_names'],  // additional_keywords
        shouldSort: true,
         minMatchCharLength: 3,
         threshold: 0.2,
         ignoreLocation: true,
         ignoreFieldNorm: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },

  },

  computed: {
    sorted_options() {
      // They way to go would be structuredClone, but since our target is governments,
      // expecting a nov-22 browser in feb-23 is unrealistic

      const data = JSON.parse(JSON.stringify(this.$store.state.system_wide_search));

      // Nice alphabetical sorting, so it gives the impression that there is something like order :)
      return data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

  },

  watch: {
    selected(item) {
      // set country / layer based on selection
      this.$store.commit('change', {country: item.country, layer: item.layer});
      this.set_and_navigate_to_report(item.id, item.name)
    }
  }


}
</script>

<style scoped>
small {
  font-size: 0.8em;
  color: var(--dark);
}

.inlinebadge {
  min-width: 40px;
}


</style>
<i18n>
{
  "en": {
    "placeholder": "View the latest report of your organization via names, website, subdomain name"
  },
  "nl": {
    "placeholder": "Direct naar het rapport via de organisatienaam of domeinnaam"
  }
}


</i18n>
