<template>
  <div class="pt-4 pb-4">
    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
      <h2><b-icon icon="newspaper"/> {{ $t("title") }}</h2>
      <p>{{ $t("intro") }}</p>

      <loading-spinner :loading="loading"></loading-spinner>

      <b-card-group deck>
        <template v-for="issue in $store.state.issues" >
          <b-col sm="12" md="12" lg="6" xl="6" class="pb-4 widecard" v-if="Object.keys(scans).includes(issue.name) && scans[issue.name].length > 1" :key="issue.name">
            <b-card :key="issue.name" >
              <template #header>
                <h4 v-html="translate(issue.name)"></h4>
              </template>

              <b-card-text>

              <b-table-simple responsive striped>
                  <b-thead>
                  <b-tr>
                    <b-th>{{ $t("scan_moment") }}</b-th>
                    <b-th>{{ $t("url") }}</b-th>
                  </b-tr>
                  </b-thead>
                  <b-tbody v-for="scan in scans[issue['name']]" :key="scan.id">
                  <b-tr :class="rowclass(scan)">
                    <b-td class="date">{{ humanize_relative_date(scan.last_scan_moment) }}</b-td>
                    <b-td class="url">{{ scan.url }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="service">{{ scan.service }}</b-td>
                    <b-td colspan="2" class="explanation">{{ translate(scan.explanation) }}</b-td>
                  </b-tr>
                  </b-tbody>

              </b-table-simple>

              </b-card-text>
            </b-card>

        </b-col>
        </template>
      </b-card-group>

    </b-container>
  </div>
</template>

<script>
import http from "@/httpclient";

import country_layer_switch_mixin from "@/CountryLayerSwitchMixin";

export default {
  mixins: [country_layer_switch_mixin],
  data: function () {
    return {
      scans: {},
      loading: false,
    }
  },

  mounted: function () {
    this.load()
  },
  methods: {
    load: function () {
      this.loading = true;
      let url = `/data/all_latest_scans/${this.$store.state.country}/${this.$store.state.layer}/`;

      http.get(url).then(data => {

        if (!data.data)
          return;

        this.scans = data.data.scans;
        this.loading = false;
        // because some nested keys are used (results[x['bla']), updates are not handled correctly.
        this.$forceUpdate();
      });
    },
    rowclass: function (scan) {
      if (scan.high === 0 && scan.medium === 0 && scan.low === 0)
        return "goodrow";
      else if (scan.high > 0)
        return "highrow";
      else if (scan.medium > 0)
        return "mediumrow";
      else
        return "lowrow";
    }
  }
};
</script>
<i18n>
{
  "en": {
    "title": "New Scans",
    "intro": "This is an overview of the most recent changes based on the latest scans. These changes will be visible the next time a report is being created.",
    "scan_moment": "Scan",
    "url": "Domain"
  },
  "nl": {
    "title": "Nieuwe Scans",
    "intro": "Dit is een overzicht van de meest recente wijzigingen op basis van de laatste scans. Dit laat zien dat er e.e.a. veranderd en verbeterd, nog voordat het in een rapport komt te staan.",
    "scan_moment": "Scantijd",
    "url": "Domein"
  }
}
</i18n>
