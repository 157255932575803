<template>
  <div>

    <p>{{ $t('what-are-explanations-answer') }}</p>

    <p>{{ $t('what-is-a-good-explanation-answer') }}</p>

    <h3>{{ $t('examples-title') }}</h3>

    <b-row>
      <b-col>
            <h4>{{ $t('good-examples-title') }}</h4>
    <p>{{ $t('good-examples-intro') }}</p>

        <h5>{{ $t('good-explanation-1-title') }}</h5>
      <b-alert variant="success" show>{{ $t('good-explanation-1-example') }}
      </b-alert>
        <h5>{{ $t('good-explanation-2-title') }}</h5>
      <b-alert variant="success" show>{{ $t('good-explanation-2-example') }}
      </b-alert>
        <h5>{{ $t('good-explanation-3-title') }}</h5>
      <b-alert variant="success" show>
        {{ $t('good-explanation-3-example') }}
      </b-alert>

      </b-col>
      <b-col>
            <h4>{{ $t('bad-examples-title') }}</h4>
    <p>{{ $t('bad-examples-intro') }}</p>

        <h5>{{ $t('bad-explanation-1-title') }}</h5>
      <b-alert variant="danger" show>{{ $t('bad-explanation-1-example') }}
      </b-alert>
        <h5>{{ $t('bad-explanation-2-title') }}</h5>
      <b-alert variant="danger" show>{{ $t('bad-explanation-2-example') }}
      </b-alert>
        <h5>{{ $t('bad-explanation-3-title') }}</h5>
      <b-alert variant="danger" show>
        {{ $t('bad-explanation-3-example') }}
      </b-alert>


      </b-col>
    </b-row>

  </div>
</template>

<i18n>
{
  "en": {
    "what-are-explanations-question": "What is an explanations?",
    "what-are-explanations-answer": "Explanation are public descriptions which are intended to increase trust in your organizations capability to provide secure and sensible services. To strengthen this trust, we only allow explanations that are great and fit the intent of your organization.",
    "what-is-a-good-explanation-question": "what makes a good explanation?",
    "what-is-a-good-explanation-answer": "A great explanation is always one that is \"by design\" the safest option.",

    "examples-title": "Here are examples of good and bad explanations",

    "good-examples-title": "Good explanations",
    "good-examples-intro": "Good explanations show design decisions. They are breaks from the norms for various reasons.",
    "good-explanation-1-title": "Following technical recommendations",
    "good-explanation-1-example": "HTTPS is disabled because Certificate Revocation Lists must be accessed without encryption. CRL data contains checksums to validate integrity.",
    "good-explanation-2-title": "Understanding the target audience",
    "good-explanation-2-example": "This service is intended for devices, these devices trust this specific certificate. This certificate is a dutch state G1 certificate which is not trusted by design.",
    "good-explanation-3-title": "Understanding your users",
    "good-explanation-3-example": "Encryption cannot be enabled because Commodore 64's do not speak TLS but still need to access these pages.",

    "bad-examples-title": "Bad explanations",
    "bad-examples-intro": "Bad explanation will reflect badly on your organization. Please do not send in any of the following explanations.",
    "bad-explanation-1-title": "Promises about the future",
    "bad-explanation-1-example": "We're working on it. / Supplier is working on it. / Will be fixed. / Planned for end of the year.",
    "bad-explanation-2-title": "Deflecting responsibility / shifting blame",
    "bad-explanation-2-example": "The supplier will not fix it unless we pay. Supplier cannot do this.",
    "bad-explanation-3-title": "Scoping / Denial",
    "bad-explanation-3-example": "While this service is on our domain, we don't own this service... It is not in our scope."
  },
  "nl": {
    "what-are-explanations-question": "Wat zijn verklaringen?",
    "what-are-explanations-answer": "Verklaringen zijn stukken uitleg die bedoeld zijn om het vertrouwen in een organisatie te versterken. Dit vertrouwen ontstaat doordat de organisatie laat zien enkel doelbewust veilige diensten aan te bieden, en af te wijken op standaarden wanneer dit noodzakelijk is. Wij ondersteunen dit door alleen verklaringen toe te voegen die een organisatie sterker in beeld brengt.",
    "what-is-a-good-explanation-question": "Wat is een goede verklaring?",
    "what-is-a-good-explanation-answer": "Een goede verklaring is er altijd een waarbij de grondslag 'by design' is: dus dit is volgens het ontwerp zo bedoeld en de veiligste optie.",

    "examples-title": "Hier zijn wat voorbeelden van goede en slechte verklaringen",

    "good-examples-title": "Goede verklaringen",
    "good-examples-intro": "Goede verklaringen laten beslissingen zien in het ontwerp. Ze wijken af van de standaarden met een specifiek doel.",
    "good-explanation-1-title": "Volgen van technische eisen",
    "good-explanation-1-example": "HTTPS is uitgeschakeld omdat Certificate Revocation Lists toegankelijk moeten zijn zonder versleuteling (ivm kip en ei probleem). Deze data heeft checksums om de integriteit vast te stellen.",
    "good-explanation-2-title": "Begrijpen van de doelgroep",
    "good-explanation-2-example": "Deze dienst is bedoeld voor apparaten die gegevens uitwisselen. Deze apparaten vertrouwen dit certificaat. Dit is een overheids G1 certificaat dat volgens het ontwerp niet wordt vertrouwd in browsers.",
    "good-explanation-3-title": "Begrijpen van de eindgebruiker",
    "good-explanation-3-example": "Versleuteling is niet mogelijk omdat de Commodore 64 geen TLS spreekt, maar wel bij deze gegevens moet kunnen komen.",

    "bad-examples-title": "Slechte verklaringen",
    "bad-examples-intro": "Slechte verklaringen staan slecht bij een organisatie. Deze verklaringen graag niet insturen: we gebruiken ze niet.",
    "bad-explanation-1-title": "Beloften over de toekomst",
    "bad-explanation-1-example": "We werken eraan / De leverancier werkt eraan / Dit wordt opgelost / Dit staand gepland voor aan het einde van het jaar.",
    "bad-explanation-2-title": "Afwijzen of afschuiven van verantwoordelijkheid",
    "bad-explanation-2-example": "De leverancier wil dit niet doen, of vraagt een enorm bedrag. Dit is voor ons niet haalbaar.",
    "bad-explanation-3-title": "Afbakening van verantwoordelijkheid / Scope / Ontkenning",
    "bad-explanation-3-example": "Dit subdomein is van ons maar wij hebben hier geen dienst op, dat wordt gedaan door een andere organisatie. Dit past niet in onze scope."
  }
}

</i18n>

<script>
export default {
  name: "ComplyOrExplainGuidelineText"
}
</script>

<style scoped>

</style>
