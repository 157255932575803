<script>
export default {
  watch: {
    time_machine_date(){
      this.load()
    }

  },
  computed: {
    time_machine_date () {
      return this.$store.state.time_machine_date
    }
  }
}
</script>
