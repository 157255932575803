<template>
  <ShareCard v-if="$store.state.config.show.socials.report"
             :sharing_introduction="$t('sharing_introduction', this.$props)"
             :description="$t('description', this.$props)"
             :title="$t('title', this.$props)"
             :url="'reports/' + report_id"
             :twitter_user="twitter_user"
             :twitter_facebook_hashtags="$t('hashtags', this.$props)"
  />
</template>
<script>


import ShareCard from "@/components/socials/ShareCard";

export default {
  name: "ShareCardReport",
  components: {
    ShareCard
  },
  props: {
    number_of_high_risk_issues: {type: Number, required: true},
    organization_name: {type: String, required: true},
    report_id: {type: Number, required: true},
    twitter_user: {type: String, required: false, default: ""},
  },
}
</script>

<i18n>
{
  "en": {
    "title": "{number_of_high_risk_issues} security issues in {organization_name}?",
    "description": "This report states that {organization_name} has {number_of_high_risk_issues} security issues.",
    "sharing_introduction": "Share this report in your network:",
    "hashtags": "cybersecurity,transparency,{organization_name},report"
  },
  "nl": {
    "title": "{number_of_high_risk_issues} kwetsbaarheden bij {organization_name}?",
    "description": "In dit rapport staat dat {organization_name} {number_of_high_risk_issues} kwetsbaarheden heeft.",
    "sharing_introduction": "Deel dit rapport in je netwerk:",
    "hashtags": "cybersecurity,transparantie,{organization_name},rapport"
  }
}
</i18n>
