<template>
    <b-container v-cloak class="pt-1" v-if="$store.state.config.show.intro && translations.landing">
        <b-jumbotron class="jumbotron mt-4" :header-html="translations.landing.title"
                     :lead-html="translations.landing.leader"/>

    </b-container>
</template>

<script>

import TranslationsFromConfigMixin from "@/components/TranslationsFromConfigMixin";
export default {
  name: "LandingHeader",
    mixins: [TranslationsFromConfigMixin],
}
</script>

<style scoped>

</style>
