
<i18n>
{
  "en": {
    "title": "Map Health",
    "intro": "Map Health gives insight into how up to date this map is. Data is considered out of date after {0} days.",
    "uptodate": "Up to date",
    "outofdate": "Outdated",
    "table_scan": "Scan",
    "table_uptodate": "Up to date",
    "table_outofdate": "Out of date"
  },
  "nl": {
    "title": "Datakwaliteit",
    "intro": "Inzicht in hoeverre de gegevens op deze kaart zijn bijgewerkt. Data wordt gezien als verouderd na {0} dagen.",
    "uptodate": "Bijgewerkt",
    "outofdate": "Verouderd",
    "table_scan": "Scan",
    "table_uptodate": "Bijgewerkt",
    "table_outofdate": "Verouderd"
  }
}

</i18n>
<template>
  <b-modal size="xl" id="map-health-modal" header-bg-variant="info"
           header-text-variant="light" scrollable v-if="map_health" okOnly @shown="get_map_health">
    <h3 slot="modal-title">{{ $t('title')}}</h3>

    <p>{{ $t('intro', [map_health['outdate_period_in_hours'] / 24])}}</p>

    <template v-if="map_health['percentage_up_to_date'] !== -1">
      <p>{{ $t('table_uptodate')}}: {{ get_health_state(map_health['percentage_up_to_date'])[1] }}
        {{ map_health['percentage_up_to_date'] }}%
        ({{ map_health['amount_up_to_date'] }}), {{ $t('table_outofdate')}} {{ map_health['percentage_out_of_date'] }}%
        ({{ map_health['amount_out_of_date'] }})</p>
    </template>

    <b-table-simple striped small hover responsive style="width: 100%"
                    class="mb-2">
      <b-thead>
        <b-th></b-th>
        <b-th>{{ $t('table_scan')}}</b-th>
        <b-th>{{ $t('table_uptodate')}}</b-th>
        <b-th>%</b-th>
        <b-th>{{ $t('table_outofdate')}}</b-th>
        <b-th>%</b-th>
      </b-thead>
      <b-tbody>
        <b-tr v-for="scan in map_health['per_scan']" :key="scan.scan_type">
          <b-td>{{ get_health_state(scan['percentage_up_to_date'])[1] }}</b-td>
          <b-td>{{ translate_issue_name(scan['scan_type']) }}<br> {{ categories(scan['scan_type']) }}</b-td>
          <b-td>{{ scan['amount_up_to_date'] }}</b-td>
          <b-td>{{ scan['percentage_up_to_date'] }}%</b-td>
          <b-td>{{ scan['amount_out_of_date'] }}</b-td>
          <b-td>{{ scan['percentage_out_of_date'] }}%</b-td>
        </b-tr>
        <tr></tr>
      </b-tbody>
    </b-table-simple>

  </b-modal>
</template>

<script>

import http from "@/httpclient";

export default {
  name: "MapHealthModal",
  mounted: function () {
    // this.timer = setInterval(this.get_planned_scan_progress, 30 * 60 * 1000);
    this.get_map_health();
    // this.get_planned_scan_progress();
  },
  data: function () {
    return {
      map_health: null,
      planned_scan_progress: null,
      meaning_of_percentage: "unknown",
      dot: '❓',
    }
  },
  methods: {
    get_health_state(percentage) {
      if (percentage === 100)
        return ["perfect", "✅"]

      if (percentage === 0)
        // This should trigger a warning over the map that the data should not be relied upon.
        return ["obsolete", "⭕"]

      if (percentage > 0 && percentage < 55)
        return ["outdated", "🔴"]

      if (percentage > 54 && percentage < 90)
        return ["incomplete", "🟠"]

      if (percentage > 89)
        return ["usable", "🟢"]

      return ["unknown", "❓"]
    },
    get_map_health: function () {
      http.get(`/data/map_health/${this.$store.state.country}/${this.$store.state.layer}/`).then((data) => {
        this.map_health = data.data;
        [this.meaning_of_percentage, this.dot] = this.get_health_state(this.map_health.percentage_up_to_date);
      });
    },
  }

}
</script>
<style scoped>
h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
