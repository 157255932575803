import VueI18n from "vue-i18n";
import Vue from "vue";
// todo: lazy loading.
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  // it's requformatDistanceired this is called messages.
  messages: {
    "en": {
      "maps": "Maps",
      "map": "Map",
      "info": "Info",
      "charts": "Best/Worst",
      "reports": "Reports",
      "statistics": "Statistics",
      "comply-or-explain": "Comply or explain",
      "datasets": "Datasets",
      "scan-monitor": "New Scans",
      "scan-schedule": "Schedule",
      "more": "More",
      "overview": "Overview",
      "sector-report": "Monthly Overview",
      "login-plaza": "Login Plaza"
    },
    "nl": {
      "maps": "Kaarten",
      "map": "Kaart",
      "info": "Info",
      "charts": "Beste/Slechtste",
      "reports": "Rapporten",
      "statistics": "Statistieken",
      "comply-or-explain": "Pas toe of leg uit",
      "datasets": "Gegevens",
      "scan-monitor": "Nieuwe scans",
      "scan-schedule": "Schema",
      "more": "Meer",
      "overview": "Overzicht",
      "sector-report": "Maandoverzicht",
      "login-plaza": "Login Plaza"
    }
  },
  sharedMessages: {}
});

export default i18n
