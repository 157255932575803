<template>
  <web-sec-map :country="$store.state.country" :layer="$store.state.layer" :week="$store.state.week" :controls="true" :map_filter="$store.state.map_filter"></web-sec-map>
</template>
<script>
import WebSecMap from "@/components/map/WebSecMap";
export default {
  name: "FullMap.vue",
  components: {WebSecMap}
}
</script>
<style scoped>
</style>
