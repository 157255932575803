<template>
  <div :class="'score ' + (value > 0 ? subject : 'good')" class="mt-2 mb-2">
    <span :class="'score_value ' + (value ? subject + '_text' : 'good_text')">{{ value }}</span> <br>
    <span class="score_label">{{ $t(this.$store.state.config.grading_policy + '_score_' + subject) }}</span>
  </div>
</template>
<script>
export default {
  props: {
    value: {type: Number, default: 0},
    subject: {type: String, default: "high"}
  }
}
</script>
<style scoped>

.score {
    border-radius: 6px;
    text-align: center;
    padding: 13px;
}

.score_label {
    font-size: 1.3em;
    color: white;
}

.score_value {
    font-size: 5em;
    background-color: #ffffffcf;
    display: block;
    border-radius: 10px;
}
</style>
