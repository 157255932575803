<style>

/* Hide info boxes on mobile / small resolutions */
@media screen and (max-width: 768px) {
    .hide_on_small_screens {
        visibility: hidden;
        pointer-events: none;
      width:0;
      height: 0;
    }

    #searchbar {
        width: 80vw;
    }

    .search-on-map {
        max-width: 80vw;
    }
}

#searchbar {
    width: 100%;
    font-size: 20px;
}

/* awesome transition */
path {
    -webkit-transition: fill 400ms;
    transition: fill 400ms;
}

.map_loading {
  opacity: 60%;
  cursor: wait !important;
}

.leaflet-layer {
  filter: grayscale(0.8);
}

.chart-number {
  font-size: 1.4em;
  text-anchor: middle;
  fill: white !important;
  -moz-transform: translateY(0.3em);
  -ms-transform: translateY(0.3em);
  -webkit-transform: translateY(0.3em);
  transform: translateY(0.3em);
}

.websecmap a:hover, .websecmap a:active, .websecmap a:visited {
  text-decoration: none;
  color: black;
}

.leaflet-popup-close-button {
  top: 7px !important;
  right: 17px !important;
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
    line-height: 30px;
}

/* Make a single menu, not separate controls. Todo: only when there is room... */
@media screen and (min-width: 768px) {
    .leaflet-top.leaflet-right {
        height: calc(100vh - 235px);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        /*background-color: rgba(255, 255, 255, 1.8);
        padding-left: 10px;
        margin-bottom: 10px;
        overflow-y: scroll;
        border-left: 2px solid #b3b3b3;
        pointer-events: initial;*/
    }
}

.info {
    min-width: 317px;
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
    border-radius: 4px;
    /* border: 2px solid rgba(0,0,0,0.2); */
    background-clip: padding-box;
}

.info h4 {
    margin: 0 0 5px;
}

</style>
<template>
  <div :class="loading ? 'websecmap map_loading' : 'websecmap'">
    <!-- these settings made the map unusable on mobile devices -->

    <!-- pass down map styliee -->
    <l-map :style="'height: ' + height + '; width: 100%;'" ref="lmap" :options="map_options">

      <!-- If you supply invalid parameters, the map will wrap around only to show the US etc. -->
      <!-- Todo: tile layer is mapped as 512 instead of 256. Therefore: Will-change memory consumption is too high. Budget limit is the document surface area multiplied by 3 (686699 px).-->
      <l-tile-layer
        :url="'https://{s}.tile.osm.org/{z}/{x}/{y}.png'"
        :attribution="show_controls ? 'Geography and Imagery(c) <a href=\'https://openstreetmap.org\'>OpenStreetMap</a> contributors, <a href=\'http://creativecommons.org/licenses/by-sa/2.0/\'>CC-BY-SA</a>, Data created with <a href=\'https://websecuritymap.org/\'>Web Security Map</a>.': 'Tiles by OSM, Data by WebSecMap.org'"
      ></l-tile-layer>

      <!-- The actual data on the map is manipulated by reference, as there are several complex methods that require
      a lot of thought to rewrite to the new approach. The goal was mainly to have all the controls declarative.
      For example: i have no clue how searching and updating / recoloring and filtering would work otherwise.
      It's probably not too hard, as this entire operation has been a breeze so far. -->
      <!-- <l-geo-json :geojson="polygons"></l-geo-json> -->

      <!-- Including marker-cluster in a 'classic' web project was not easy, and the code doesn't match yet. See above.
       <v-marker-cluster :iconCreateFunction="marker_cluster_iconcreatefunction" :maxClusterRadius="25" ref="clusterRef">
          <v-marker v-for="m in markers" v-if="c.location !== null" :lat-lng="c.latlng">

          </v-marker>
      </v-marker-cluster> -->

      <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>

      <l-control position="bottomleft">
        <b-badge class="m-1" variant="success" v-if="stats['low'] + stats['good']">{{stats['low'] + stats['good']}}</b-badge>
        <b-badge class="m-1" variant="warning" v-if="stats['medium']">{{stats['medium']}}</b-badge>
        <b-badge class="m-1" variant="danger" v-if="stats['high']">{{stats['high']}}</b-badge>
        <b-badge class="m-1" variant="secondary" v-if="stats['unknown']">{{stats['unknown']}}</b-badge>
      </l-control>

      <template v-if="show_controls">
        <l-control-zoom position="topleft" :zoomInTitle="$t('zoom in')" :zoomOutTitle="$t('zoom out')"></l-control-zoom>

        <l-control position="topright" class="hide_on_small_screens">
          <simplestats></simplestats>
        </l-control>

        <l-control position="topright" class="hide_on_small_screens" style="z-index: 1;" v-if="$store.state.config.show.geolocation_results">
          <div class="info table-light" style="width: 200px; overflow-x: hidden">
            <h4>{{$t('near me')}}</h4>

            <div v-for="item in nearby" style="white-space: nowrap" class="mb-2" :key="item.layer.feature.properties.organization_id">
              <b-badge variant="danger" v-if="item.layer.feature.properties.severity === 'high'" style="min-width: 2em; height: 2em;" class="mr-1 pt-1">{{item.layer.feature.properties.high}}</b-badge>
              <b-badge variant="warning" v-if="item.layer.feature.properties.severity === 'medium'" style="min-width: 2em; height: 2em;" class="mr-1 pt-1">{{item.layer.feature.properties.medium}}</b-badge>
              <b-badge variant="success" v-if="item.layer.feature.properties.severity === 'low'" style="min-width: 2em; height: 2em;" class="mr-1">&nbsp;</b-badge>
              <b-badge variant="success" v-if="item.layer.feature.properties.severity === 'good'" style="min-width: 2em; height: 2em;" class="mr-1">&nbsp;</b-badge>
              <b-badge variant="secondary" v-if="item.layer.feature.properties.severity === 'unknown'" style="min-width: 2em; height: 2em;" class="mr-1">&nbsp;</b-badge>
              <router-link :to="'/report/' + item.layer.feature.properties.organization_id">{{item.layer.feature.properties.organization_name}}</router-link>
            </div>

            <ask-geolocation-permission />
          </div>
        </l-control>

        <!-- z-index: 0; is set for the dropdown nav to switch maps on the map-->
        <l-control position="topright" class="search-on-map" style="z-index: 1;">
          <div class="info table-light">
            <v-select
              v-model="vmodel_searchquery"
              :filter="mySearch"
              :options="organizations.length > 0 ? organizations : []"
              :placeholder="$t('search')">
            </v-select>

            <!-- The old search bar does not need to be displayed but is used elsewhere in this tangled mess. -->
            <input id='searchbar' type='text' style="display: none;" v-model="searchquery"
                   :placeholder="$t('search')"/>
          </div>
        </l-control>




        <map-filter style="z-index: 0;"></map-filter>

         <!--<l-control position="topright" class="hide_on_small_screens" style="z-index: 0;">
          <div style="max-width: 310px; overflow:hidden;" class="info  table-light" >
            <TimeMachine />
          </div>
        </l-control> -->


        <report-preview style="z-index: 0;" :hover_info="hover_info"></report-preview>

        <map-legend></map-legend>


        <l-control position="topleft">
          <div style="cursor: pointer">
          <span @click="show_all_map_data()" :title='$t("show everything")'
                style='font-size: 1.4em; background-color: white; border: 2px solid rgba(0,0,0,0.35); border-radius: 4px; padding: 6px; height: 34px; position: absolute; width: 34px; text-align: center; line-height: 1.2;'>🗺️</span>
          </div>
        </l-control>
        <map-health-modal/>
        <map-health-dot/>
        <l-control position="topleft" v-if="loading">
          <div
            style='margin-top: 34px; font-size: 1.4em; background-color: white; border: 2px solid rgba(0,0,0,0.35); border-radius: 4px; padding: 6px; height: 34px; position: absolute; width: 34px; text-align: center; line-height: 1.2;'>
            <span v-if='loading'><div class="loader" style="width: 18px; height: 18px;"></div></span>
          </div>
        </l-control>
      </template>
    </l-map>
  </div>
</template>
<i18n>
{
  "en": {
    "zoom in": "Zoom in",
    "zoom out": "Zoom out",
    "show coordinates": "Show coordinates",
    "center here": "Center map here",
    "show everything": "Show everything",
    "add domains": "Add domains",
    "relocate point": "Relocate this point",
    "switch latlon": "Switch Latitude & Longitude",
    "search": "Search",
    "toggle controls": "Toggle controls",
    "near me": "Near me"

  },
  "nl": {
    "zoom in": "Zoom in",
    "zoom out": "Zoom uit",
    "show coordinates": "Toon coordinaten",
    "center here": "Centreer kaart op dit punt",
    "show everything": "Toon alles",
    "add domains": "Domeinnamen toevoegen",
    "relocate point": "Verplaatst dit punt",
    "switch latlon": "Verwissel Latitude & Longitude",
    "search": "Zoeken",
    "toggle controls": "Controls aan/uit",
    "near me": "In de buurt"
  }
}
</i18n>

<script>
import http from "@/httpclient";
import {LMap, LTileLayer, LControl, LControlScale, LControlZoom} from 'vue2-leaflet';
import MapHealthDot from "@/components/map/MapHealthDot";
import MapHealthModal from "@/components/map/MapHealthModal";
import simplestats from "@/components/map/MapSimpleStats";

import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-contextmenu';
import MapLegend from '@/components/map/MapLegend';
import MapFilter from '@/components/map/MapContentFilter';
import ReportPreview from '@/components/map/MapReportPreview';
import TimeMachineMixin from "@/components/TimeMachineMixin";
import GeometryUtil from "leaflet-geometryutil";
import AskGeolocationPermission from "@/components/map/geolocation/AskGeolocationPermission";

export default {
  name: 'WebSecMap.vue',

  mixins: [TimeMachineMixin],

  components: {
    AskGeolocationPermission,
    ReportPreview,
    MapLegend,
    MapFilter,
    LMap,
    LTileLayer,
    LControl,
    LControlScale,
    MapHealthDot,
    simplestats,
    MapHealthModal,
    LControlZoom,
  },


  data: function () {
    return {
      has_changed: false,

      // The information shown at the top of the map, live updates when changing the slider.
      visibleweek: "",

      // # historyslider
      loading: false,
      week: 0,
      selected_organization: -1,
      features: null,
      proxy_tiles: true,

      // keep track if we need to show everything, or can stay zoomed in:
      previously_loaded_country: null,
      previously_loaded_layer: null,


      // things that get rendered on the map
      polygons: null,

      // leafletmarkercluster is not supported for 'old school' approaches like this
      markers: null,


      // direct refrence to the leaflet map that is being used to display data.
      // this.$refs.lmap.mapObject
      map: this.$refs,

      possibleIconSeverities: ["unknown", "good", "low", "medium", "high"],

      // let the user decide by right-clicking for example, override the prop.
      show_controls: true,

      // search functionality:
      searchquery: "",
      vmodel_searchquery: "",

      search_features_added: false,

      // only show information when the mouse is more than 0.1 second.
      timer: 0,

      // a bar of what colors are visible on this map:
      stats: {},

      // hover_info:
      hover_info: {
        properties: {
          organization_name: "",
          high: 0,
          medium: 0,
          low: 0,
          high_urls: 0,
          medium_urls: 0,
          low_urls: 0,
          total_urls: 0
        }
      },

      inkpot: {
        high: '',
        medium: '',
        low: '',
        good: '',
        unknown: '',
      },

      clicked_map_object: null,

      show_map_health: false,

      // a list of nearby stuff, requires sharing location in browser...
      nearby: [],

      // todo: apply options at the right moment.
      map_options: {
        scrollWheelZoom: false,
        zoomSnap: 0.1,
        contextmenu: true,
        contextmenuWidth: 140,

        // do this custom
        zoomControl: false,

        // two finger dragging on mobile.
        // see: https://stackoverflow.com/questions/41622980/how-to-customize-touch-interaction-on-leaflet-maps
        // !L.Browser.mobile
        dragging: !L.Browser.mobile,
        touchZoom: true,
        tap: true,

        contextmenuItems: [
          {
            text: this.$t('show coordinates'),
            callback: this.showCoordinates
          },
          {
            text: this.$t('center here'),
            callback: this.centerMap
          },
          '-',
          {
            text: this.$t('zoom in'),
            icon: '/static_frontend/zoom-in.png',
            callback: this.zoomIn
          }, {
            text: this.$t('zoom out'),
            icon: '/static_frontend/zoom-out.png',
            callback: this.zoomOut
          }, {
            text: this.$t('show everything'),
            callback: this.show_all_map_data,
          },
          '-',
          {
            text: this.$t('toggle controls'),
            callback: this.toggle_controls,
          }
        ]
      }
    }
  },

  props: {
    controls: {default: true, type: Boolean},
    country: {default: "NL", type: String},
    layer: {default: "municipality", type: String},
    map_filter: {default: "", type: String},
    height: {default: "100vh", type: String},

    at_when: {type: String, default:"", required: false},

    // Fixes a window resize bug and reloading edge case on the multi-map page where the value comes from viewing
    // the entire map next to other ones.
    always_show_entire_map: {default: false, type: Boolean},

    // How much distance from the border of the map to the first item on the map
    contentPadding: {default: 50, type: Number},

    show_popup: {type: Boolean, default: true, required: false},

    borderSize: {type: Number, default: 1},
    borderColor: {type: String, default: 'white'}
  },

  created: function () {
    // store the original map data in a copy, as this library overwrites the values for quicker updates:
    // unfortunately with an ugly approach

    // https://codingexplained.com/coding/front-end/vue-js/accessing-dom-refs
    this.$nextTick(() => {
      // The whole view is rendered, so I can safely access or query the DOM. ¯\_(ツ)_/¯
      this.init()
    })
  },

  mounted() {
    // allow prop to be overwritten by user.
    this.show_controls = this.controls;
  },

  methods: {
    toggle_controls() {
      // mutating prop
      this.show_controls = !this.show_controls;
    },
    init() {
      this.map = this.$refs.lmap.mapObject;

      this.polygons = L.geoJson();
      this.markers = this.markerclustergroup();

      // https://github.com/aratcliffe/Leaflet.contextmenu/issues/32
      this.map.on('contextmenu.show', (event) => {
        // alert('swag'); Gets fired twice, once with null...
        if (event.relatedTarget !== undefined)
          this.clicked_map_object = event.relatedTarget;
      });
      this.fill_inkpot();
      this.load();
      this.update_visible_week();
    },

    fill_inkpot(){
      // Because classes are not dynmamic, we have to work with real colors.
      let computed_style = getComputedStyle(document.body);
      this.inkpot = {
        high:  computed_style.getPropertyValue('--high-map'),
        medium:  computed_style.getPropertyValue('--medium-map'),
        low:  computed_style.getPropertyValue('--low-map'),
        good:  computed_style.getPropertyValue('--good-map'),
        unknown:  computed_style.getPropertyValue('--unknown-map'),
      }
    },

    markerclustergroup: function () {
      return L.markerClusterGroup(
        {
          // this better matches the shape of a country
          maxClusterRadius: 25,

          iconCreateFunction: (cluster) => {
            let css_class = "unknown";

            let childmarkers = cluster.getAllChildMarkers();

            let selected_severity = 0;

            // doesn't even need to be an array, as it just matters if the text matches somewhere
            let search_active = false;
            for (let point of childmarkers) {
              // we can figure this out another way.
              if (point.options.fillOpacity === 1) {
                search_active = true;
              }
            }

            let color_total = 0;
            let color_red = 0;
            let color_orange = 0;
            let color_green = 0;

            for (let point of childmarkers) {
              // upgrade severity until you find the highest risk issue.
              if (search_active) {
                // filter down only on items that are actually seached for...
                if (point.options.fillOpacity === 1) {
                  if (this.possibleIconSeverities.indexOf(point.feature.properties.severity) > selected_severity) {
                    selected_severity = this.possibleIconSeverities.indexOf(point.feature.properties.severity);
                    css_class = point.feature.properties.severity;
                  }
                }
                // todo: seems to always be active
                if (point.options.fillOpacity === 1) {
                  color_total += 1;
                  if (point.feature.properties.severity === "high") color_red += 1;
                  if (point.feature.properties.severity === "medium") color_orange += 1;
                  if (point.feature.properties.severity === "low") color_green += 1;
                  if (point.feature.properties.severity === "good") color_green += 1;
                }
              } else {
                //  do not take in account the possible difference in search results.
                if (this.possibleIconSeverities.indexOf(point.feature.properties.severity) > selected_severity) {
                  selected_severity = this.possibleIconSeverities.indexOf(point.feature.properties.severity);
                  css_class = point.feature.properties.severity;
                }
                // ["unknown", "good", "low", "medium", "high"]
                // todo: use dynamic colors.
                // color_total += 1;
                // if (point.feature.properties.severity === "high") color_red += 1;
                // if (point.feature.properties.severity === "medium") color_orange += 1;
                // if (point.feature.properties.severity === "low") color_green += 1;
                // if (point.feature.properties.severity === "good") color_green += 1;
              }
            }

            let filled_red = 0;
            let filled_orange = 0;
            let filled_green = 0;

            if (color_total) {
              filled_red = 100 * (color_red + color_orange + color_green) / color_total;
              filled_orange = 100 * (color_orange + color_green) / color_total;
              filled_green = 100 * (color_green) / color_total;
            }

            let classname = search_active ? 'marker-cluster marker-cluster-' + css_class : 'marker-cluster marker-cluster-white';
            // https://heyoka.medium.com/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72

            let options = {
              html: '<div><span><svg width="100%" height="100%" viewBox="0 1 42 42" class="donut">' +
                '  <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>' +
                '  <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" stroke-width="3"></circle>' +
                '  <circle class="donut-segment leaflet-marker-unknown" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="7" stroke-dasharray="100 0" stroke-dashoffset="25"></circle>' +
                '  <circle class="donut-segment leaflet-marker-high" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="7" stroke-dasharray="' + filled_red + ' ' + (100 - filled_red) + '" stroke-dashoffset="25"></circle>' +
                '  <circle class="donut-segment leaflet-marker-medium" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="7" stroke-dasharray="' + filled_orange + ' ' + (100 - filled_orange) + '" stroke-dashoffset="25"></circle>' +
                '  <circle class="donut-segment leaflet-marker-good" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="7"  stroke-dasharray="' + filled_green + ' ' + (100 - filled_green) + '" stroke-dashoffset="25"></circle>' +
                '  <g class="chart-text">' +
                '    <text x="50%" y="50%" class="chart-number">' +
                color_total +
                '    </text>' +
                '  </g>' +
                '</svg></span></div>',
              className: classname,
              iconSize: [40, 40]
            }
            return L.divIcon(options);
          }
        })
    },

    load: function () {
      this.loading = true;

      let url = `/data/map/${this.country}/${this.layer}/${this.$store.state.week * 7}/`;

      if (this.$store.state.time_machine_date) {
        url = `/data/map/${this.country}/${this.layer}/${this.$store.state.time_machine_date}/`;
      }

      if (this.at_when){
         url = `/data/map/${this.country}/${this.layer}/${this.at_when}/`;
      }
      if (this.map_filter) {
        url += `${this.map_filter}/`
      }
      http.get(url).then(data => {
        this.handle_map_data(data.data);
        this.loading = false;
      });
    },

    handle_map_data: function (data) {

      // Don't need to zoom out when the filters change, only when the layer/country changes.
      let fitBounds = false;
      if (this.previously_loaded_country !== this.$store.state.country || this.previously_loaded_layer !== this.$store.state.layer) {
        fitBounds = true;
      }

      this.plotdata(data, fitBounds);
      this.previously_loaded_country = this.$store.state.country;
      this.previously_loaded_layer = this.$store.state.layer;

      // make map features (organization data) available to other vues
      // do not update this attribute if an empty list is returned as currently
      // the map does not remove organizations for these kind of responses.
      if (data.features.length > 0) {
        this.features = data.features;
      }

      this.fillStatsBar(data);
      this.add_data_to_system_wide_search();
      this.show_nearby_stuff();
    },

    fillStatsBar: function(mapdata) {

      let stats = {
        'high': 0,
        'medium': 0,
        'low': 0,
        'good': 0,
        'unknown': 0,
      }

      mapdata.features.forEach(feature => {
        if (feature.properties.severity === "high")
          stats['high'] += 1;
        if (feature.properties.severity === "medium")
          stats['medium'] += 1;
        if (feature.properties.severity === "low")
          stats['low'] += 1;
        if (feature.properties.severity === "good")
          stats['good'] += 1;
        if (feature.properties.severity === "unknown")
          stats['unknown'] += 1;
      })

      this.stats = stats;
    },

    plotdata: function (mapdata, fitbounds = true) {
      let geodata = this.split_point_and_polygons(mapdata);

      // if there is one already, overwrite the attributes...
      if (this.polygons.getLayers().length || this.markers.getLayers().length) {
        // add all features that are not part of the current map at all
        // and delete the ones that are not in the current set
        // the brutal way would be like this, which would not allow transitions:
        // map.markers.clearLayers();
        // map.add_points(points);
        // Todo: a map data hash would help to determine if we need to check this at all.
        // Note: polygons overlap points more often than not, making the dots inaccessible. The order here is important.
        this.add_new_layers_remove_non_used(geodata.polygons, this.polygons, "polygons");
        this.add_new_layers_remove_non_used(geodata.points, this.markers, "markers");

        // update existing layers (and add ones with the same name)
        this.polygons.eachLayer((layer) => {
          this.recolormap(mapdata.features, layer)
        });
        this.markers.eachLayer((layer) => {
          this.recolormap(mapdata.features, layer)
        });

        // colors could have changed
        this.markers.refreshClusters();
      } else {
        this.add_polygons_to_map(geodata.polygons);
        this.add_points_to_map(geodata.points);
      }

      // apply the current search criteria to the new plot
      this.search();

      if (fitbounds)
        this.show_all_map_data();
    },

    add_data_to_system_wide_search(){
      // prevent on reloading component and such
      if (this.search_features_added)
        return

      this.features.forEach(feature => {

        let item = {
          'id': feature.properties.organization_id,
          'country': this.country,
          'layer': this.layer,
          'name': feature.properties.organization_name,
          'label': feature.properties.organization_name,
          'alternative_names': feature.properties.organization_alternative_names,
          'additional_keywords': feature.properties.additional_keywords,
          'high': feature.properties.high,
          'medium': feature.properties.medium,
          'low': feature.properties.low,
          'good': feature.properties.good,
          'ok': feature.properties.ok,
        }

        this.add_to_system_wide_search(item)
      })

      this.search_features_added = true;
    },

    show_all_map_data() {
      let paddingToLeft = this.contentPadding;
      if (document.documentElement.clientWidth > 768 && this.controls)
        paddingToLeft = this.contentPadding + 270;

      let bounds = this.polygons.getBounds();
      bounds.extend(this.markers.getBounds());

      if (Object.keys(bounds).length === 0)
        return;

      this.map.fitBounds(bounds, {paddingTopLeft: [this.contentPadding, this.contentPadding],
        paddingBottomRight: [paddingToLeft, this.contentPadding]});
    },

    recolormap: function (features, layer) {
      let existing_feature = layer.feature;

      features.forEach((new_feature) => {

        if (existing_feature.properties.organization_name !== new_feature.properties.organization_name) {
          return;
        }
        //if (JSON.stringify(new_feature.geometry.coordinates) !== JSON.stringify(existing_feature.geometry.coordinates)) {
        //if (map.evil_json_compare(new_feature.geometry.coordinates, existing_feature.geometry.coordinates)) {
        if (new_feature.geometry.coordinate_id !== existing_feature.geometry.coordinate_id) {
          // Geometry changed, updating shape. Will not fade.
          // It seems not possible to update the geometry of a shape, too bad.


          if (new_feature.geometry.type === "Point"){
            // just using adddata will cause the new point to have swapped lat lng and end up in the ocean next to afr
            this.polygons.removeLayer(existing_feature);
            this.add_points_to_map([new_feature]);
          } else {
            this.polygons.removeLayer(layer);
            this.polygons.addData(new_feature);
          }
        } else {
          // colors changed, shapes / points on the map stay the same.
          existing_feature.properties.severity = new_feature.properties.severity;
          // make the transition
          layer.setStyle(this.style(layer.feature));
        }
      });
    },

    split_point_and_polygons: function (mapdata) {
      // needed because MarkedCluster can only work well with points in our case.

      // mapdata is a mix of polygons and multipolygons, and whatever other geojson types.
      let regions = []; // to polygons
      let points = []; // to markers

      // the data is plotted on two separate layers which both have special properties.
      // both layers have a different way of searching, clicking behaviour and so forth.
      for (var i = 0; i < mapdata.features.length; i++) {
        switch (mapdata.features[i].geometry.type) {
          case "Polygon":
          case "MultiPolygon":
            regions.push(mapdata.features[i]);
            break;
          case "Point":
            points.push(mapdata.features[i]);
            break;
        }
      }

      return {'polygons': regions, 'points': points}
    },
    add_new_layers_remove_non_used: function (shapeset, target, layer_type) {
      // when there is no data at all, we're done quickly
      if (!shapeset.length) {
        target.clearLayers();
        return;
      }

      // Here we optimize the number of loops if we make a a few simple arrays. We can then do Contains,
      // which is MUCH more optimized than a nested foreach loop. It might even be faster with intersect.
      let shape_names = [];
      let target_names = [];
      shapeset.forEach(function (shape) {
        shape_names.push(shape.properties.organization_name)
      });
      target.eachLayer(function (layer) {
        target_names.push(layer.feature.properties.organization_name)
      });

      // add layers to the map that are only in the new dataset (new)
      shapeset.forEach((shape) => {
        // polygons has addData, but MarkedCluster doesn't. You can't blindly do addlayer on points.
        if (!target_names.includes(shape.properties.organization_name)) {
          if (layer_type === "polygons") {
            target.addData(shape);
          } else {
            this.add_points_to_map([shape]);
          }
        }

      });

      // remove existing layers that are not in the new dataset, both support removeLayer.
      target.eachLayer(function (layer) {
        if (!shape_names.includes(layer.feature.properties.organization_name))
          target.removeLayer(layer);
      });
    },

    highlightFeature: function (e) {
      this.timer = setTimeout(() => {
        let layer = e.target;

        layer.openPopup();

        layer.setStyle({weight: 1, color: '#fff', dashArray: '0', fillOpacity: 0.7});

        // because of the "bring to front" the timer of this feature is called again. Thus, again after timeout
        // a new timer is started and then again requests a report. It's not really clear why this code is
        // here in the first place. Disabled it until further notice. We don't have overlapping polygons...
        //if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        //    layer.bringToFront();
        //}

        this.hover_info.properties = layer.feature.properties;

      }, 300);
    },

    // from hover info
    showreport: function (e) {
      this.set_and_navigate_to_report(
        e.target.feature.properties['organization_id'],
        e.target.feature.properties['organization_name']
      );
    },

    showreportfromcontextmenu: function () {
      this.set_and_navigate_to_report(
        this.clicked_map_object.feature.properties['organization_id'],
        this.clicked_map_object.feature.properties['organization_name']
      );
    },

    set_and_navigate_to_report: function (organization_id, organization_name) {
      this.$store.commit('change', {
        reported_organization: {
          id: organization_id,
          name: organization_name,
        }
      });
      this.$router.push({path: '/report'})
    },

    pointToLayer: function (geoJsonPoint, latlng) {
      return L.circleMarker(latlng, this.style(geoJsonPoint));
    },
    add_points_to_map: function (points) {
      // Geojson causes confetti to appear, which is great, but doesn't work with multiple organization on the same
      // location. You need something that can show multiple things at once place, such as MarkerCluster.
      // map.markers = L.geoJson(points, {
      //     style: map.style,
      //     pointToLayer: map.pointToLayer,
      //     onEachFeature: map.onEachFeature
      // }).addTo(map.map);
      let self = this;
      points.forEach((point) => {
        let pointlayer = this.pointToLayer(point, L.latLng(point.geometry.coordinates));

        let menuItems = [{
          text: point.properties['organization_name'],
          index: 0
        },
          {
            text: this.$t('view_report'),
            callback: this.showreportfromcontextmenu,
            index: 1,
          }];

        this.add_nearby_stuff_to_menu(menuItems, point.geometry.coordinates[0], point.geometry.coordinates[1])

        if (this.$store.state.config.admin) {
          menuItems.push(
            {
              separator: true,
              index: 2
            },
            {
              text: this.$t('add domains'),
              callback: this.add_domains,
              index: 3
            },
            {
              text: this.$t('relocate point'),
              callback: this.move_point,
              context: point,
              index: 4
            },
            {
              text: this.$t('switch latlon'),
              callback: this.switch_lattitude_and_longitude,
              index: 5
            },
            {
              separator: true,
              index: 6
            })
        } else {
          menuItems.push({
            separator: true,
            index: 2
          })
        }

        // see geojson data: https://github.com/aratcliffe/Leaflet.contextmenu
        pointlayer.bindContextMenu({
          contextmenu: true,
          contextmenuItems: menuItems
        });

        if (this.show_popup) {
          let popup = L.popup({minWidth: 300, className: 'btn-lg', closeButton: false});
          popup.setContent(this.popup_content(point.properties));

          pointlayer.bindPopup(popup).openPopup();
        }

        pointlayer.on({
          mouseover: this.highlightFeature,
          mouseout: this.resetHighlight,
          click: this.highlightFeature,
          dblclick: this.showreport,
        });

        // allow opening of reports and such in the old way.
        pointlayer.feature = {"properties": point.properties, "geometry": point.geometry};

        self.markers.addLayer(pointlayer);
      });
      this.map.addLayer(this.markers);
    },

    add_domains() {
      // use the admin interface. Until rest framework is added.
    },


    move_point() {
      // this.clicked_map_object.feature.properties['organization_id']

      this.$store.commit("set_selected_point", this.clicked_map_object.feature);

      this.$bvModal.show('move-organization')

    },

    popup_content: function (props) {
      return `
                <a style="cursor: pointer;" onClick="document.querySelector('#app').__vue__.direct_navigation_to_report(${props.organization_id})">
                    <div class="pb-1 font-weight-bold">${this.determine_book_color(props['percentages'])} ${props['organization_name']}</div>

                      <div class="progress mb-2">
                          <div class="progress-bar bg-success" style="width:${props['percentages']['good_urls']}%"></div>
                          <div class="progress-bar bg-success" style="width:${props['percentages']['low_urls']}%"></div>
                          <div class="progress-bar bg-warning" style="width:${props['percentages']['medium_urls']}%"></div>
                          <div class="progress-bar bg-danger" style="width:${props['percentages']['high_urls']}%"></div>
                      </div>

                    <div><button class="btn dropbox btn-primary btn">🔍 ${this.$i18n.t("view_report")}</button></div>


                </a>
            `
    },

    add_polygons_to_map: function (polygons) {
      this.polygons = L.geoJson(polygons, {
        style: this.style,
        pointToLayer: this.pointToLayer,
        onEachFeature: this.onEachFeature,
      }).addTo(this.map);
    },


    get_first_coordinate(coords){
      // a dumb way to do this, but it works
      // points
      if (this.is_coordinate(coords))
        return coords

      // polygons or multipoligons
      if (this.is_coordinate(coords[0]))
        return coords[0]

      if (this.is_coordinate(coords[0][0]))
        return coords[0][0]

      if (this.is_coordinate(coords[0][0][0]))
        return coords[0][0][0]

      if (this.is_coordinate(coords[0][0][0][0]))
        return coords[0][0][0][0]

      if (this.is_coordinate(coords[0][0][0][0][0]))
        return coords[0][0][0][0][0]

      if (this.is_coordinate(coords[0][0][0][0][0]))
        return coords[0][0][0][0][0]
    },

    is_coordinate(possible_coordinate){
      if (possible_coordinate === undefined)
        return false

      if (possible_coordinate.length !== 2)
        return false

      return Number.isFinite(possible_coordinate[0]) && Number.isFinite(possible_coordinate[1]);
    },

    onEachFeature: function (feature, layer) {

      let menuItems = [{
        text: layer.feature.properties['organization_name'],
        index: 0
      },
        {
          text: this.$t('view_report'),
          callback: this.showreportfromcontextmenu,
          index: 1,
        }];

      // todo: find center of geometry, now just use the first thing you get.
      // multipoligons have their latlng reversed it seems. This is done consistently.
      let coord = this.get_first_coordinate(layer.feature.geometry.coordinates)
      this.add_nearby_stuff_to_menu(menuItems, coord[1], coord[0])

      if (this.$store.state.config.admin) {
        menuItems.push({
            text: "Add url(s)",
            callback: this.start_adding_domains,
            index: 2
          },
          {
            separator: true,
            index: 3
          })
      } else {
        menuItems.push({
          separator: true,
          index: 2
        })
      }

      // see geojson data: https://github.com/aratcliffe/Leaflet.contextmenu
      layer.bindContextMenu({
        contextmenu: true,
        contextmenuItems: menuItems
      });

      if (this.show_popup) {
        let popup = L.popup({minWidth: 300, className: 'btn-lg', closeButton: false});

        popup.setContent(this.popup_content(layer.feature.properties));


        // ${props['total_urls']} ${this.$t('map.popup.urls')}<br>
        // <a onclick="showreport_frompopup(${props['organization_id']}, '${props['organization_name']}')">${this.$t('map.popup.view_report')}</a><br>
        layer.bindPopup(popup).openPopup();
      }

      layer.on({
        mouseover: this.highlightFeature,
        mouseout: this.resetHighlight,
        click: this.highlightFeature,
        dblclick: this.showreport,
      });
    },

    determine_book_color: function (percentages) {
      if (percentages['high_urls']) return "📕";
      if (percentages['medium_urls']) return "📙";
      if (percentages['low_urls']) return "📗";
      if (percentages['good_urls']) return "📗";
      return "📓";
    },

    showCoordinates: function (e) {
      alert(e.latlng);
    },
    centerMap: function (e) {
      this.map.panTo(e.latlng);
    },
    zoomIn: function () {
      this.map.zoomIn();
    },
    zoomOut: function () {
      this.map.zoomOut();
    },

    switch_lattitude_and_longitude: function () {
      let url = `/data/admin/map/switch_lat_lng/${this.clicked_map_object.feature.properties['organization_id']}/`;
      http.get(url).then(data => {
        alert(data.data.message)
      });
    },

    style: function (feature) {
      return {
        weight: this.borderSize, opacity: 1, color: this.borderColor, dashArray: '0', fillOpacity: 1,
        fillColor: this.getColorCode(feature.properties.severity),
        // Classname is not a dynamic property, so setting this doesn't work.
        // You will have to retrieve the colors for the map from the color variables.
        // https://github.com/Leaflet/Leaflet/issues/2662
        // className: this.getPolygonClass(feature.properties.severity)
      };
    },

    add_nearby_stuff_to_menu(){
      // menuItems, latitude, longitude
      // this seems to add the wrong items, or the right items at the wrong time more likely.
      // i assume this gets called when not all data is on the map yet and so the distances get all wrong.


      // todo: make this a submenu.
      // let nearby = this.get_nearby_stuff(latitude, longitude)
      // if (nearby.length > 0){
      //   menuItems.push(
      //     {
      //       separator: true,
      //       index: 3
      //     }
      //   )
      // }
      // for (let i=0; i<5;i++){
      //   if (nearby[i] !== undefined)
      //   menuItems.push(
      //     {
      //       text: nearby[i].layer.feature.properties.organization_name,
      //       separator: false,
      //       index: 4
      //     }
      //   )
      // }
    },

    get_nearby_stuff(latitude, longitude) {
      return GeometryUtil.layersWithin(
        this.map,
        this.polygons.getLayers().concat(this.markers.getLayers()),
        L.latLng([latitude, longitude])
        // using infinite distance here, because this is a per-pixel based approach, and pixels
        // are probably not working well when zooming, etc etc...
      )
    },

    update_sitewide_nearby_information() {
      let new_data = []
      if (this.$store.state.current_geolocation === undefined) {
        new_data = []
      } else {
        let lat = this.$store.state.current_geolocation.coords.latitude;
        let long = this.$store.state.current_geolocation.coords.longitude;
        new_data = this.get_nearby_stuff(lat, long);
      }

      // todo: should we do this per organization or all in one and place the organization type behind that
      // one list is nicer... but that should be done elsewhere as we don't know anything about any config here.
      // merge the key to one thing so its easier to retrieve elsewhere
      this.$store.state.nearby_organizations[`${this.country}${this.layer}`] = new_data
    },

    show_nearby_stuff(){
      if (this.$store.state.current_geolocation === undefined) {
        this.nearby = []
      } else {
        // we need a sensible default of things that are nearby. You would care less about nearby hospitals
        // than nearby schools. But it's nice to compare them. I think about 5 makes sense as a start of this value.
        let lat = this.$store.state.current_geolocation.coords.latitude;
        let long = this.$store.state.current_geolocation.coords.longitude;
        this.nearby = this.get_nearby_stuff(lat, long).slice(0, 6);
      }
    },

    // todo: make dynamic. Get this from the color palette...
    getColorCode: function (d) {
      return this.inkpot[d] ||  this.inkpot.unknown;
    },
    getPolygonClass: function (d) {
      // Does not work: https://github.com/Leaflet/Leaflet/issues/2662
      return `map_polygon_${d}`
    },
    resetHighlight: function (e) {
      clearTimeout(this.timer);

      let query = document.getElementById('searchbar');
      if (query && this.isSearchedFor(e.target.feature, query.value.toLowerCase()))
        e.target.setStyle(this.searchResultStyle(e.target.feature));
      else
        e.target.setStyle(this.style(e.target.feature));
    },
    searchResultStyle: function () {
      return {weight: 1.5, opacity: 1, color: 'white', dashArray: '0', fillOpacity: 0.1};
    },
    isSearchedFor: function (feature, query) {
      if (!query || query === "")
        return false;

      if (query.length < 3)
        return (feature.properties.organization_name_lowercase.indexOf(query) === -1);

      return (feature.properties.organization_name_lowercase.indexOf(query) === -1 &&
        feature.properties.additional_keywords.indexOf(query) === -1);
    },

    search: function () {
      let query = this.searchquery.toLowerCase();
      if ((query === "") || (!query)) {
        // reset
        this.polygons.eachLayer((layer) => {
          layer.setStyle(this.style(layer.feature));
        });
        this.markers.eachLayer((layer) => {
          layer.setStyle(this.style(layer.feature));
        });
        this.markers.refreshClusters();
      } else {
        // text match
        // todo: is there a faster, native search option?
        this.polygons.eachLayer((layer) => {
          this.handleSearchQuery(layer, query)
        });
        this.markers.eachLayer((layer) => {
          this.handleSearchQuery(layer, query)
        });
        // check in the clusters if there are any searched for. Is done based on style.
        this.markers.refreshClusters();
      }
    },

    mySearch(options, search) {
      search = search.toLowerCase()
      this.searchquery = search;

      let filteredOptions = []

      if (search.length < 3) {
        filteredOptions = options.filter(item => item.organization_name_lowercase.indexOf(search) !== -1)
      } else {
        filteredOptions = options.filter(item => item.additional_keywords.indexOf(search) !== -1)
      }

      return filteredOptions;
    },

    handleSearchQuery(layer, query) {
      // organization names require one letter, additional properties three: to speed up searching
      if (query.length < 3) {
        if (layer.feature.properties.organization_name_lowercase.indexOf(query) === -1)
          layer.setStyle(this.searchResultStyle(layer.feature));
        else
          layer.setStyle(this.style(layer.feature));
      } else {

        if (layer.feature.properties.organization_name_lowercase.indexOf(query) === -1 &&
          layer.feature.properties.additional_keywords.indexOf(query) === -1)
          layer.setStyle(this.searchResultStyle(layer.feature));
        else
          layer.setStyle(this.style(layer.feature));
      }
    },

    update_visible_week: function (e) {
      let show_week = 0;
      if (!e) {
        show_week = this.$store.state.week;
      } else {
        show_week = parseInt(e.target.value);
      }
      let x = new Date();
      x.setDate(x.getDate() - show_week * 7);
      this.visibleweek = x.humanTimeStamp();
    },
  },
  computed: {

    organizations: function () {
      if (this.features != null) {
        let organizations = this.features.map(function (feature) {
          return {
            "id": feature.properties.organization_id,
            "label": feature.properties.organization_name,
            "name": feature.properties.organization_name,
            "slug": feature.properties.organization_slug,
            "organization_name_lowercase": feature.properties.organization_name.toLowerCase(),
            "additional_keywords": feature.properties.additional_keywords,
          }
        });
        return organizations.sort(function (a, b) {
          if (a['name'] > b['name']) return 1;
          if (a['name'] < b['name']) return -1;
          return 0;
        });
      }
      return 1;
    }
  },
  watch: {
    vmodel_searchquery(new_value) {

      if (new_value === null || new_value === undefined) {
        this.searchquery = "";
        return
      }

      this.searchquery = new_value.name;

      // if there is one or two items, then highlight those features to make the page more responsive
      // also show the report preview
      this.polygons.eachLayer((layer) => {
        if (layer.feature.properties.organization_name === new_value.name) {
          this.highlightFeature({target: layer})
        }
      });
      this.markers.eachLayer((layer) => {
        if (layer.feature.properties.organization_name === new_value.name) {
          this.highlightFeature({target: layer})
        }
      });

    },
    map_filter: function () {
      this.load(this.week)
    },
    country: function () {
      this.load(this.week)
    },
    layer: function () {
      this.load(this.week)
    },
    searchquery: function () {
      this.search();
    },
    '$route'() {
      /* This is a workaround fixes the window resizing issues: when a map is opened on page 1,
      * then another map is openened on page 2. Both use vue keep-alive. When the window resizes, the visible
      * map resizes properly, but the one on the container that is not visible does not. What happens when navigating
      * to the map that was not visible: it is completely out of focus showing a random part of the map strongly
      * zoomed in. While this fix might not be the most efficient, it works brilliantly. */

      if (["maps", "map"].includes(this.$route.name)) {
        // This ofc will not always work. but somehow the maps zooms in extremely for some reason when using
        // PageContextSwitcher... this is a bad hack to solve this. It's bad because it depends on time, and not a
        // certain state of the application/loading etc...
        if (this.has_changed) {
          setTimeout(() => {
            this.show_all_map_data();
          }, 400)
          this.has_changed = false;
        }

      }

      this.map.invalidateSize();


      // fix an edge case where the multi maps page shows zoomed in maps.
      if (this.always_show_entire_map) {
        this.show_all_map_data()
      }

    },
    '$store.state.country': {
      deep: false,
      handler() {
        this.has_changed = true;
      }
    },
    '$store.state.layer': {
      deep: false,
      handler() {
        this.has_changed = true;
      }
    },
    '$store.state.current_geolocation': {
      deep: false,
      handler() {
        this.update_sitewide_nearby_information()
        this.show_nearby_stuff()
      }
    }
  },
};
</script>
