<template>
  <div class="pt-4 pb-4">
    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
    <div class="page-header">
      <h2>
        <b-icon icon="calendar2-date"/>
        {{ $t("title") }}
      </h2>
      <p>{{ $t("intro") }}</p>
    </div>
    <loading-spinner :loading="loading"></loading-spinner>

    <b-table striped hover small
             :items="next"
             :fields="fields"
             class="mb-4"
    >
      <template #cell(date)="data">
        {{ humanize_relative_date(data.item.date) }}
      </template>

      <template #cell(name)="data">
        <span v-html="data.item.name"></span>
      </template>

      <template #empty="">
        <h4>{{ $t("no_scans_scheduled") }}</h4>
      </template>

    </b-table>

  </b-container>
  </div>
</template>

<script>
import http from "@/httpclient";

export default {
  data: function () {
    return {
      next: Array(),
      previous: Array(),
      fields: [
        {key: "date", sortable: true, label: this.$i18n.t('When')},
        {key: "name", sortable: true, label: this.$i18n.t('Scanner')},
      ],
      loading: false,
    }
  },

  mounted: function () {
    this.load()
  },

  methods: {
    load: function () {
      this.loading = true;
      http.get('/data/upcoming_and_past_scans/').then(data => {
        this.next = data.data.next.filter(item => item.name.search('hidden') < 0);
        this.previous = data.data.previous;
        this.loading = false;
      });
    },
  },
};
</script>
<i18n>
{
  "en": {
    "title": "Scan schedule",
    "intro": "Below scans and tasks will be performed in the future. This can help you getting a better perspective on when updates will be hapening to your organization. Note that all times are approximate due to caching and rounding. The time listed is the time the scan starts, it might take a while before your organization is scanned.",
    "no_scans_scheduled": "No scans are scheduled to be performed.",
    "When": "When",
    "Scanner": "Planned scan"
  },
  "nl": {
    "title": "Scan schema",
    "intro": "Dit is een schema van alle aankomende scans. De scantijd verschilt per scan, bij grote hoeveelheden urls kan dit langer duren. De bevindingen worden bij het creëren van een nieuwe rapportage meegenomen.",
    "no_scans_scheduled": "Er zijn geen scans ingepland.",
    "When": "Wanner",
    "Scanner": "Geplande scan"
  }
}
</i18n>
