<template>
  <div class="pt-1 pb-4" role="main">
    <b-container>
    <div class="jumbotron mt-4">
      <h1 class="display-4" v-if="translations.info.title">
        <b-icon icon="info-circle" /> {{ translations.info.title }}
      </h1>
      <p class="lead" v-html="translations.info.heading" v-if="translations.info.heading"></p>
    </div>

    <b-row>
      <b-col class="mb-4 mr-4 ml-3 p-3 bg-white rounded"><h2 v-html="translations.info.title_1" v-if="translations.info.title_1"></h2>
        <div v-html="translations.info.content_1" v-if="translations.info.content_1"></div>
      </b-col>
      <b-col class="mb-4 ml-4 mr-3 p-3 bg-white rounded"><h2 v-html="translations.info.title_2" v-if="translations.info.title_2"></h2>
        <div v-html="translations.info.content_2" v-if="translations.info.content_2"></div>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col class="mb-4 mr-4 ml-3 p-3 bg-white rounded"><h2 v-html="translations.info.title_3" v-if="translations.info.title_3"></h2>
        <div v-html="translations.info.content_3" v-if="translations.info.content_3"></div>
      </b-col>
      <b-col class="mb-4 ml-4 mr-3 p-3 bg-white rounded"><h2 v-html="translations.info.title_4" v-if="translations.info.title_4"></h2>
        <div v-html="translations.info.content_4" v-if="translations.info.content_4"></div>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <h2 v-html="translations.info.title_5" v-if="translations.info.title_5"></h2>
      <td v-html="translations.info.content_5" v-if="translations.info.content_5"></td>
    </b-row>

    <b-row class="mb-4 ml-3 mr-3 p-3 bg-white"
           v-if="$store.state.config.show.issues.tls_qualys_certificate_trusted || $store.state.config.show.issues.plain_http">
      <b-col>
        <h2>Automated Comply or Explain</h2>
        <p>The following services are automatically explained as being OK. The report will still show the original
          metric, accompanied with a canned explanation.
        </p>
        <p>IT departments can use this knowledge to adapt to these commonly used practices. Note that automatic
          explanation is extremely specific: it requires specific conditions such as certificate names and id's to be
          applied.</p>

        <ul>
          <li>Certificate Revocation List subdomains: the subdomains PKI.* and CRL.* do not need to have an https
            endpoint. This solves a chicken and egg problem.
          </li>
          <li>Microsoft subdomains: autodiscover, enterpriseregistration, webmail, lyncdiscover, sip,
            enterpriseenrollment, msoid do not have to have a trusted certificate. The http headers do not have to be
            correct. Reasoning: these domains are used for devices and do (mostly) have no human visitors, as such
            associated headers are also disabled. They should roll out using a trusted subdomain and certificate,
            but they wont. Would this not be explained, maps would be entirely red: that would incorrectly discredit
            the measured organizations.
          </li>
          <li>Domains using specific untrusted government certificates handed out by the (Dutch) government. These
            untrusted certificates are handed out and used by design. For example the certificate with serial 10004001.
          </li>
        </ul>

      </b-col>
    </b-row>
    </b-container>
  </div>
</template>
<script>

import TranslationsFromConfigMixin from "@/components/TranslationsFromConfigMixin";

export default {
  mixins: [TranslationsFromConfigMixin]
}
</script>
<i18n>
{
  "en": {
    "title": "About {0}",
    "run_by": "This website is run by %{organization}.",
    "contact": "For questions, suggestions, complaints or remarks: %{organization_mail}.",
    "Made with Web Security Map": "Made with Web Security Map"
  },
  "nl": {
    "title": "Over {0}",
    "run_by": "Deze website wordt onderhouden door %{organization}.",
    "contact": "Voor vragen, klachten, opmerkingen of suggesties: %{organization_mail}.",
    "Made with Web Security Map": "Gemaakt met Web Security Map"
  }
}

</i18n>
