import { render, staticRenderFns } from "./GoodBadChartList.vue?vue&type=template&id=8ee4e890&scoped=true&"
import script from "./GoodBadChartList.vue?vue&type=script&lang=js&"
export * from "./GoodBadChartList.vue?vue&type=script&lang=js&"
import style0 from "./GoodBadChartList.vue?vue&type=style&index=0&id=8ee4e890&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ee4e890",
  null
  
)

/* custom blocks */
import block0 from "./GoodBadChartList.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports