import FullMap from "@/pages/FullMapPage";
import VueRouter from "vue-router";
import ReportComposition from "@/pages/ReportPage";
import Charts from "@/pages/GoodBadChartPage";
import StatsComposition from "@/pages/StatsPage";
import DataSets from "@/pages/DatasetPage";
import ScanSchedule from "@/pages/ScanSchedulePage";
import ComplyOrExplain from "@/pages/ComplyOrExplainPage";
import ScanMonitor from "@/pages/ScanMonitorPage";
import About from "@/pages/InfoPage";
import Dev from "@/pages/DevelopmentPage";
import MultiMapLanding from "@/pages/MultiMapLandingPage";
import SingleMapPressPage from "@/pages/SingleMapPressPage"
import MetricsPage from "@/pages/MetricsPage"
import ResponsibleDisclosurePage from "@/pages/ResponsibleDisclosurePage"
import LoginPlazaPage from "@/pages/LoginPlazaPage"
import store from './store';
import i18n from './i18n';

i18n.locale = store.state.locale;

const routes = [
  {
    path: '/maps',
    component: MultiMapLanding,
    alias: '/',
    name: "maps",
    meta: {'menu': 'app', 'basename': 'maps'},
  },
  {
    path: '/map',
    component: FullMap,
    name: "map",
    meta: {'menu': 'organization', 'basename': 'map'},
  },
  {
    path: '/map/:layer_or_landing_page',
    component: FullMap,
    name: "map_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'map'},
  },
  {
    path: '/map/:country/:layer',
    component: FullMap,
    name: "map_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'map'},
  },
  {
    path: '/press',
    component: SingleMapPressPage,
    name: "press",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/sector-report',
    component: SingleMapPressPage,
    name: "sector_report",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
   {
    path: '/sector-report/:layer_or_landing_page/:date',
    component: SingleMapPressPage,
    name: "sector_report_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/sector-report/:layer_or_landing_page/:date/:days_back',
    component: SingleMapPressPage,
    name: "sector_report_with_country_or_layer",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/press/:layer_or_landing_page/:date',
    component: SingleMapPressPage,
    name: "press_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/press/:layer_or_landing_page/:date/:days_back',
    component: SingleMapPressPage,
    name: "press_with_country_or_layer",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/report',
    component: ReportComposition,
    name: "reports",
    meta: {'menu': 'organization', 'basename': 'reports'},
  },
  {
    path: '/reports/:country/:layer',
    component: ReportComposition,
    name: "reports-with-country_and_layer",
    meta: {'menu': 'organization', 'basename': 'reports'},
  },
  {
    path: '/login-plaza',
    component: LoginPlazaPage,
    name: "login-plaza",
    meta: {'menu': 'organization', 'basename': 'login-plaza'},
  },
  {
    path: '/login-plaza/:search_query',
    component: LoginPlazaPage,
    name: "login-plaza-search",
    meta: {'menu': 'organization', 'basename': 'login-plaza'},
  },
  {
    path: '/login-plaza/:country/:layer/',
    component: LoginPlazaPage,
    name: "login-plaza-with-country_and_layer",
    meta: {'menu': 'organization', 'basename': 'login-plaza'},
  },
  {
    path: '/login-plaza/:country/:layer/:search_query',
    component: LoginPlazaPage,
    name: "login-plaza-with-country_and_layer-search",
    meta: {'menu': 'organization', 'basename': 'login-plaza'},
  },
  {
    path: '/report/:url_report_id',
    component: ReportComposition,
    props: true,
    name: "report",
    meta: {'menu': 'organization', 'basename': 'report'},
  },

  {
    path: '/charts',
    component: Charts,
    name: "charts",
    meta: {'menu': 'organization', 'basename': 'charts'},
  },
  {
    path: '/charts/:layer_or_landing_page',
    component: Charts,
    name: "charts_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'charts'},
  },
  {
    path: '/charts/:country/:layer',
    component: Charts,
    name: "charts_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'charts'},
  },

  {
    path: '/stats',
    component: StatsComposition,
    alias: '/statistics',
    name: "statistics",
    meta: {'menu': 'organization', 'basename': 'statistics'},
  },
  {
    path: '/stats/:layer_or_landing_page',
    component: StatsComposition,
    alias: '/statistics',
    name: "statistics_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'statistics'},
  },
  {
    path: '/stats/:country/:layer',
    component: StatsComposition,
    alias: '/statistics',
    name: "statistics_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'statistics'},
  },
  {
    path: '/statistics/:country/:layer',
    component: StatsComposition,
    alias: '/statistics',
    name: "statistics_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'statistics'},
  },

  {
    path: '/datasets',
    component: DataSets,
    name: "datasets",
    meta: {'menu': 'app', 'basename': 'datasets'},
  },
  {
    path: '/scan-schedule',
    component: ScanSchedule,
    name: "scan_schedule",
    meta: {'menu': 'app', 'basename': 'scan-schedule'},
  },
  {
    path: '/comply-or-explain',
    component: ComplyOrExplain,
    name: "comply_or_explain",
    meta: {'menu': 'organization', 'basename': 'comply-or-explain'},
  },
  {
    path: '/comply-or-explain/:country/:layer',
    component: ComplyOrExplain,
    name: "comply_or_explain",
    meta: {'menu': 'organization', 'basename': 'comply-or-explain'},
  },
  {
    path: '/scan-monitor',
    component: ScanMonitor,
    name: "scan_monitor",
    meta: {'menu': 'app', 'basename': 'scan-monitor'},
  },
  {
    path: '/metrics',
    component: MetricsPage,
    name: "metrics",
    meta: {'menu': 'app', 'basename': 'metrics'},
  },
  {
    path: '/about',
    component: About,
    alias: '/info',
    name: "info",
    meta: {'menu': 'app', 'basename': 'info'},
  },
  {
    path: '/responsible-disclosure',
    component: ResponsibleDisclosurePage,
    alias: '/rd',
    name: "responsible-disclosure",
    meta: {'menu': 'app', 'basename': 'rd'},
  },
  {
    path: '/dev',
    component: Dev,
    name: "dev",
    meta: {'menu': 'app', 'basename': 'dev'},
  },
  {
    path: '/comply-or-explain-guidelines',
    component: ComplyOrExplainGuidelinePage,
    alias: '/coeguidelines',
    name: "comply-or-explain-guidelines",
    meta: {'menu': 'app', 'basename': 'comply-or-explain-guidelines'},
  },
  {
    path: '/metric-progress/',
    component: MetricProgressPage,
    name: "metric-progress",
    meta: {'menu': 'organization', 'basename': 'metric-progress'},
  },
  {
    path: '/metric-progress/:filter',
    component: MetricProgressPage,
    name: "metric-progress-with-filter",
    meta: {'menu': 'organization', 'basename': 'metric-progress'},
  },
  {
    path: '/metric-progress/:country/:layer',
    component: MetricProgressPage,
    name: "metric-progress-with-country_and_layer",
    meta: {'menu': 'organization', 'basename': 'metric-progress'},
  },
  {
    path: '/metric-progress/:country/:layer/:filter',
    component: MetricProgressPage,
    name: "metric-progress-with-country_and_layer-with-filter",
    meta: {'menu': 'organization', 'basename': 'metric-progress'},
  },
];


const router = new VueRouter({
  routes, // short for `routes: routes`
  props: true,
  // https://reactgo.com/scroll-to-anchor-tags-vue-router/
  // does not work, as nested anchors is not a thing (and not reliable). So do this in component.
  scrollBehavior: function (to) {
    if (to.hash) {
      return {selector: to.hash}
    }
  },
});

import Vue from 'vue'
import ComplyOrExplainGuidelinePage from "@/pages/ComplyOrExplainGuidelinePage";
import MetricProgressPage from "@/pages/MetricProgressPage";

router.afterEach((to) => {
  store.state.show_confetti = false;
  Vue.nextTick(() => {
    // initialization issue.
    if (store.state.config.project.name)
      document.title = to.name ? `${store.state.config.project.name} - ${i18n.t(to.meta.basename)}` : 'Loading Map...';
    else
      document.title = to.name ? `${i18n.t(to.meta.basename)}` : 'Loading Map...';
  });
})


export default router

