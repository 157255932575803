<template>
    <b-select v-model="$store.state.statistics_amount_of_days">
      <option value="7">📅 {{$t('7')}}</option>
      <option value="31">📅 {{$t('31')}}</option>
      <option value="92">📅 {{$t('92')}}</option>
      <option value="365">📅 {{$t('365')}}</option>
    </b-select>

</template>

<script>
export default {
  name: "StatisticsSelectRange"
}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "7": "View up until one week ago",
    "31": "View up until one month ago",
    "92": "View up until one quarter ago",
    "365": "View up until one year ago"
  },
  "nl": {
    "7": "Bekijk tot een week terug",
    "31": "Bekijk tot een maand terug",
    "92": "Bekijk tot een kwartaal terug",
    "365": "Bekijk tot een jaar terug"
  }
}
</i18n>
