<template>
  <div v-if="url.endpoints.length > 0 || url.ratings.length > 0" :key="url.id"
       :class="`report_url_background_${url_grade(url)} width-100 p-3 mb-3 rounded clear-both`">

    <!-- title row -->
    <div class="row mb-3">
      <div class="col-md-4">
        &nbsp;
      </div>
      <div class="col-md-8">
        <a :name="'report_url_'+url.url"></a>
        <span :class="'total_awarded_points mr-1 awarded_points_' + url_grade(url)">{{
            $t(`${$store.state.config.grading_policy}_score_${url_grade(url)}`)
          }}</span>&nbsp;
        <span :class="'faildomain report_' + url_grade(url) +'_text'"
              :data-tooltip-content="'#' + idize(url.url)">{{
            url.url
          }}</span><br/>
        <a
          :href="'mailto:' + $store.state.config.incorrect_finding.email_address + '?subject=' + encodeURIComponent(`${$store.state.config.incorrect_finding.email_subject}`.format(url.url)) + '&body=' + encodeURIComponent($store.state.config.incorrect_finding.email_body)"
          class="btn btn-secondary btn-sm" style="margin-top: 11px;" role="button"
          v-if="$store.state.config.show.incorrect_finding">
          {{ $t("report_incorrect_finding") }}</a>
      </div>
    </div>

    <div v-if="url.ratings.length > 0" class="row">
      <div class="col-md-4">
        &nbsp;
      </div>
      <div class="col-md-8">
        <h4>{{ $t("url_level_findings") }}</h4>
        <div v-for="rating in url.ratings" :key="rating.id">
          <ReportFinding :rating="rating" :url="url" v-if="$store.state.config.show.issues[rating.type]"/>
        </div>
      </div>
    </div>

    <div v-for="endpoint in url.endpoints" :key="endpoint.id" class="row">
      <div class="col-md-4 centered">
        <EndpointScreenshot v-if="['http', 'https', 'dns_a_aaaa'].includes(endpoint.protocol)" :url="url" :endpoint="endpoint" />
      </div>
      <div class="col-md-8">
        <!-- todo: should be ratings_by_type, but because there are just a few instances per page this is fast enough for now -->
        <h4 class="mb-3">{{ $t("service") }}: {{ endpoint_type(endpoint) }}</h4>
        <div v-for="issue_name in ordered_visible_issue_names" :key="issue_name.name">
          <template v-for="rating in endpoint.ratings">
          <ReportFinding :rating="rating" :url="url" :endpoint="endpoint"
                         :key="rating.name" v-if="rating.type === issue_name.name" class="mb-2"/>
          </template>
        </div>

        <!--
        As they come in the report:
        <div v-for="rating in endpoint.ratings" :key="rating.name">
          <ReportFinding :rating="rating" :url="url" :endpoint="endpoint"
                         v-if="$store.state.config.show.issues[rating.type]"/>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ReportFinding from "@/components/report/ReportFinding";
import EndpointScreenshot from "@/components/report/EndpointScreenshot";

export default {
  name: "ReportFindingsPerUrl",
  components: {EndpointScreenshot, ReportFinding},
  props: {
    url: {type: Object, required: true}
  },
  methods: {
    idize: function (url) {
      url = url.toLowerCase();
      return url.replace(/[^0-9a-z]/gi, '')
    },
    url_grade(url) {
      if (url.high > 0) return "high";
      if (url.medium > 0) return "medium";
      if (url.low > 0) return "low";
      return "good";
    },
    endpoint_type: function (endpoint) {
      if (endpoint.port && endpoint.ip_version)
        return this.translate(endpoint.protocol) + "/" + endpoint.port + " (IPv" + endpoint.ip_version + ")";
      return this.translate(endpoint.protocol)
    },
  }
}
</script>
<style scoped>
.centered {
  text-align: center;
}

</style>

<i18n>
{
  "en": {
    "url_level_findings": "Url level findings",
    "service": "Service",
    "report_incorrect_finding": "Report incorrect finding"
  },
  "nl": {
    "url_level_findings": "Algemene bevindingen bij dit adres",
    "service": "Dienst",
    "report_incorrect_finding": "Meld incorrecte bevinding"
  }
}
</i18n>
