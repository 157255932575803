<template>
  <div class="pt-4 pb-4">


    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
    <div class="page-header">
      <h2>
        <b-icon icon="chat-left-dots"/> {{ $t("title") }}
      </h2>
      <p>{{ $t("intro") }}</p>
      <p v-if='$store.state.config.show.comply_or_explain_guidelines' ><a @click="$bvModal.show('comply-or-explain-guidelines')">⛱️ {{$t('Explanation Guidelines')}}</a></p>
    </div>
    <loading-spinner :loading="loading"></loading-spinner>

    <template v-for="explain in explains">
      <div class="row" :key="explain.id">

        <div class="col-md-10">
          <h4>{{ explain.subject }}</h4>

          <div class="chat_quote_left">
            <del>
              <span :class="'awarded_points awarded_points_' + explain.original_severity">
                  {{ translate($store.state.config.grading_policy + '_score_' + explain.original_severity) }}
              </span>&nbsp;&nbsp;
              {{ translate(explain.scan_type) }}:
              {{ translate(explain.original_explanation) }}
            </del>
          </div>
          <br>
          <blockquote class="blockquote text-right chat_quote_right">
            <p class="mb-0">
              <template v-if="['trusted_on_local_device_with_custom_trust_policy', 'device_only_certificate',
                            'for_devices_only_not_browsers', 'for_specialized_applications_only_not_browsers', 'unencrypted_required',
                            'fix_in_progress', 'scanner_bug', 'service_intentionally_designed_this_way',
                            'domain_hosts_certificate_revocation_list', 'service_intended_for_devices_not_browsers'
                            ].includes(explain.explanation)">
                {{ $t("standard_explanations." + explain.explanation) }}
              </template>
              <template v-else>
                {{ explain.explanation }}
              </template>
            </p>
            <footer class="blockquote-footer">
              {{ explain.explained_by }} <cite title="Source Title">{{
                humanize_relative_date(explain.explained_on)
              }} </cite>
            </footer>
          </blockquote>
        </div>
        <div class="col-md-2 text-center">
          <small>{{ $t("view_report") }}</small>
          <ul style="padding-left: 0px">
            <li v-for="organization in explain.organizations" class="explain_reports" :key="organization.id">
              <router-link :to="'/report/' + organization.id" :title="'View report for ' + organization.name">
                <b-icon icon="file-bar-graph"/>
                {{ organization.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>

    <div class="row" v-if="more_explains.length">
      <div class="col-md-12 text-center">
        <button type="button" class="btn btn-primary" @click="showmore()" v-show="more_available">
          {{ $t("show_more") }}
        </button>
      </div>
    </div>

    <div class="row" v-if="!explains.length && !loading">
      <div class="col-md-12">
        {{ $t("no_explanations_yet") }}
      </div>
    </div>

  </b-container>
  </div>
</template>
<style scoped>
.chat_quote_left {
    border-radius: 20px;
    padding: 10px 15px;
    margin-right: 10%;
    margin-bottom: -14px;
}

.chat_quote_right {
    border-radius: 20px;
    padding: 10px 15px;
    margin-left: 10%;
    margin-bottom:40px;
}
.explain_reports {
    border: 1px solid #c5c5c5;
    list-style: none;
    padding: 10px;
    margin: 10px;
    width: 100%;
    background-color: #e3d1cb33;
    border-radius: 3px;
}

.awarded_points {
  padding: 0 3px;
  font-weight: bold;
  font-size: 13px;
  border-radius: 4px;
}
</style>
<script>
import http from "@/httpclient";

import country_layer_switch_mixin from "@/CountryLayerSwitchMixin";

export default {
  components: {},
  mixins: [country_layer_switch_mixin],
  data: function () {
    return {
      explains: Array(),
      more_explains: Array(),
      more_available: true,
      loading: false,
      per_page: 100,
    }
  },

  mounted: function () {
    this.load()
  },

  methods: {
    load: function () {
      this.loading = true;
      let url = `/data/explained/${this.$store.state.country}/${this.$store.state.layer}/`;

      http.get(url).then(explains => {
        console.log(explains)
        this.more_explains = explains.data.slice(this.per_page);
        this.explains = explains.data.slice(0, this.per_page);

        if (this.more_explains.length === 0)
          this.more_available = false;

        this.loading = false;
      });
    },
    showreport(organization_id) {
      location.href = '#report';

      let organization_name = "";
      this.$store.commit('change', {
        reported_organization: {
          id: organization_id,
          name: organization_name,
        }
      });
    },
    showmore() {
      if (this.more_explains.length > this.per_page) {

        for (let i = 0; i < this.per_page; i++)
        this.explains.push(this.more_explains.shift());
      } else if (this.more_explains.length > 1) {
        for (let i = 0; i < this.more_explains.length; i++) {
          this.explains.push(this.more_explains.shift());
        }
        this.more_available = false;
      }
    }
  },
};
</script>
<i18n>
{
  "en": {
    "title": "Comply or explain",
    "intro": "Comply or explain allows organizations to explain certain results on this website. In some edge cases, our finding may be technically correct but does not represent any danger. Below is a list of the latest explained issues. Organizations can explain issues using the 'explain' link per finding.",
    "no_explanations_yet": "No explanations yet...",
    "show_more": "Show more...",
    "Explanation Guidelines": "Guidance for a good explanation",
    "standard_explanations": {
      "service_intentionally_designed_this_way": "This domain is being used by specialized applications using their own protocols, which have no need for browser security features.",
      "trusted_on_local_device_with_custom_trust_policy": "The certificate is used on specific devices exclusively, these devices are configured to trust this certificate.",
      "device_only_certificate": "The certificate is used on specific devices exclusively, these devices are configured to trust this certificate.",
      "for_devices_only_not_browsers": "This domain is for devices only, not browsers. These devices does not need this requirement.",
      "for_specialized_applications_only_not_browsers": "This domain is being used by specialized applications using their own protocols, which have no need for browser security features.",
      "service_intended_for_devices_not_browsers": "This domain is being used by specialized applications using their own protocols, which have no need for browser security features.",
      "unencrypted_required": "This domain publishes data that according to best practices must be delivered unencrypted, such as Certificate Revocation Lists. This data is signed and validated by the consumer. Delivering this through an encrypted channel would possibly create technical problems, such as an infinite validation loop.",
      "domain_hosts_certificate_revocation_list": "Certificate Revocation List do not require TLS, authenticity of data is guaranteed inside the data hosted on this domain",
      "scanner_bug": "A bug in the scanner caused this issue. Manual testing has shown this issue does not occurs."
    }
  },
  "nl": {
    "title": "Pas toe of leg uit",
    "intro": "Pas toe of leg uit is een methode waarop bevindingen op deze site verklaard worden. In bijzondere gevallen is de bevinding op deze site technisch juist, maar veroorzaakt het geen gevaar. Hieronder staat een lijst met de meest recente 'pas toe of leg uit' verklaringen.",
    "no_explanations_yet": "Nog geen verklaringen verwerkt...",
    "show_more": "Toon meer...",
    "Explanation Guidelines": "Handleiding voor een goede verklaring",
    "standard_explanations": {
      "service_intentionally_designed_this_way": "This domain is being used by specialized applications using their own protocols, which have no need for browser security features.",
      "trusted_on_local_device_with_custom_trust_policy": "Dit certificaat wordt op specifieke apparatuur gebruikt. Deze apparatuur is ingericht om dit certificaat te vertrouwen.",
      "device_only_certificate": "Dit certificaat wordt op specifieke apparatuur gebruikt. Deze apparatuur is ingericht om dit certificaat te vertrouwen.",
      "for_devices_only_not_browsers": "Dit domein wordt voor specifieke apparatuur gebruikt. Deze apparatuur heeft geen noodzaak voor deze beveiligingseis.",
      "for_specialized_applications_only_not_browsers": "Dit domein wordt voor specialistische applicaties gebruikt met eigen protocollen. Hierdoor is deze browser beveiligingseis niet relevant.",
      "service_intended_for_devices_not_browsers": "Dit domein wordt voor specialistische applicaties gebruikt met eigen protocollen. Hierdoor is deze browser beveiligingseis niet relevant.",
      "unencrypted_required": "Dit domein publiceert gegevens die volgens best practices onversleuteld moeten worden aangeboden, bijvoorbeeld Certificate Revocation Lists. Deze gegevens zijn gesigned en worden gevalideerd door de lezer. Het aanbieden van deze gegevens over een versleuteld domein kan technische problemen veroorzaken.",
      "domain_hosts_certificate_revocation_list": "Certificate Revocation List gebruiken geen TLS, de authenticiteit van de gegevens op deze site wordt op een andere manier geregeld.",
      "scanner_bug": "Een bug in de scanner zorgt voor een verkeerde beoordeling. Een handmatige test toont aan dat dit probleem hier niet voorkomt."
    }
  }
}
</i18n>
