<template>
  <div>
    <!-- don't need to filter with under 10 results. -->
    <template v-if="this.urls.length > 10 || domain_name_filter !== ''">
      <b-input-group size="lg" prepend="Filter" class="pb-3">

        <b-form-input v-model="domain_name_filter" debounce="400"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" text="Button" variant="primary" @click="reset">{{ $t('clear') }}</b-button>
        </b-input-group-append>
      </b-input-group>
    </template>

    <b-button variant="secondary" class="float-right" v-if="domain_name_filter">{{ $t('results') }}
      <b-badge variant="light">{{ filtered_urls.length }}</b-badge>
    </b-button>

    <b-row>
      <b-col>
        <b-pagination :total-rows="filtered_urls.length" :per-page="10" v-model="current_page" :limit="10"
                      v-if="filtered_urls.length>10"></b-pagination>
      </b-col>
      <b-col style="text-align: right" class="mt-2">
        <fieldset>
          {{$t('content')}}:
          <b-check v-model="$store.state.report.show_high_in_report" v-if="$conf.app.relevant_for_ui.high"><span class="awarded_points awarded_points_high">{{$t(`${$store.state.config.grading_policy}_score_high`)}}</span></b-check>
          <b-check v-model="$store.state.report.show_medium_in_report" v-if="$conf.app.relevant_for_ui.medium"><span class="awarded_points awarded_points_medium">{{$t(`${$store.state.config.grading_policy}_score_medium`)}}</span></b-check>
          <b-check v-model="$store.state.report.show_low_in_report" v-if="$conf.app.relevant_for_ui.low"><span class="awarded_points awarded_points_low">{{$t(`${$store.state.config.grading_policy}_score_low`)}}</span></b-check>
          <b-check v-model="$store.state.report.show_good_in_report" v-if="$conf.app.relevant_for_ui.good"><span class="awarded_points awarded_points_good">{{$t(`${$store.state.config.grading_policy}_score_good`)}}</span></b-check>
        </fieldset>
      </b-col>
    </b-row>

    <ReportFindingsPerUrl :url="url" v-for="url in paginate(filtered_urls, per_page, current_page)" :key="url.id"/>
    <b-pagination :total-rows="filtered_urls.length" :per-page="10" v-model="current_page" :limit="10"
                  v-if="filtered_urls.length>10"></b-pagination>

  </div>
</template>

<script>
import ReportFindingsPerUrl from "@/components/report/ReportFindingsPerUrl";

export default {
  name: "ReportFindingsFilter",
  components: {ReportFindingsPerUrl},
  data() {
    return {
      current_page: 1,
      per_page: 10,

      filtered_urls: [],

      //
      domain_name_filter: "",
    }
  },
  mounted() {
    this.filtered_urls = this.urls;
  },
  methods: {
    paginate(array, page_size, page_number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },
    reset() {
      this.domain_name_filter = "";
    }
  },
  watch: {
    domain_name_filter(new_value) {

      if (new_value === "") {
        this.filtered_urls = this.urls;
        return
      }

      let results = []
      this.urls.forEach(url => {
        if (url.url.includes(new_value)) results.push(url)
      })
      this.filtered_urls = results;
    },
    '$store.state.clicked_url': {
      deep: false,
      handler() {
        this.domain_name_filter = this.$store.state.clicked_url;
      }
    }
  },
  props: {
    urls: {type: Array, required: true}
  }
}
</script>

<style scoped>

.custom-checkbox {
  display: inline-block;
  margin-right: 15px;
}

.awarded_points {
  padding: 0 3px;
  font-weight: bold;
  font-size: 11px;
  border-radius: 4px;
}

</style>
<i18n>
{
  "en": {
    "clear": "Clear",
    "results": "results",
    "content": "Content"
  },
  "nl": {
    "clear": "Wissen",
    "results": "resultaten",
    "content": "Inhoud"
  }
}
</i18n>
