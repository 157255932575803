<template type="x-template" id="charts_template">
  <div class="pt-4 pb-4">

  <b-container v-cloak class="p-3 mb-3 mt-2 rounded bg-white" fluid="xl">
    <h2><b-icon icon="trophy"/> {{$t('title')}}</h2>

    <b-card no-body>
      <b-tabs content-class="mt-0" pills card>
        <b-tab>
          <template #title>
            <b-icon icon="trophy"/>
            {{ $t('top_fail') }}
          </template>
          <chart-list :data_url="'/data/topfail/'"></chart-list>
        </b-tab>
        <b-tab>
          <template #title>
            <b-icon icon="trophy"/>
            {{ $t('top_win') }}
          </template>
          <chart-list :data_url="'/data/topwin/'"></chart-list>
        </b-tab>
      </b-tabs>
    </b-card>

  </b-container>

    <back-to-top></back-to-top>

    </div>
</template>

<script>

import ChartList from "@/components/goodbadcharts/GoodBadChartList";
import BackToTop from "@/components/BackToTop";

export default {
  components: {BackToTop, ChartList}
};
</script>
<i18n>
{
  "en": {
    "title": "Best/Worst",
    "top_fail": "Organizations with the most issues",
    "top_win": "Organizations with very few to zero issues"
  },
  "nl": {
    "title": "Beste/Slechtste",
    "top_fail": "Organisaties met de meeste risico's",
    "top_win": "Organisaties met de minste tot geen risico"
  }
}
</i18n>
