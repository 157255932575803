<template>
<div class="pt-4">
    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
      <h2>
        <b-icon icon="emoji-sunglasses"/>
        {{ $t("title") }}
      </h2>
    <v-select v-model="local_map_filter" :reduce="country => country.code" :options="options"
              :placeholder="$t('select_metric')">
    </v-select>

      <div class="m-4" v-if="previous_map_dates.length > 6">
      {{$t('showing_months', [previous_map_dates.length])}} <br>
        <b-button @click="reduce_map_dates(6)" variant="primary">{{$t('showing_months_reduce', [6])}}</b-button>
      </div>

    <b-row class="mb-4 mt-4" v-if="Object.keys(this.issuel).length > 0">
      <b-col v-for="previous_map_date in previous_map_dates" :key="previous_map_date.toString()" sm="12" md="12" lg="6" xl="6">
        <div class="m-2">
        <h4>{{humanize_date(previous_map_date.toISOString().substr(0,10))}}</h4>

        <web-sec-map
          :country="$store.state.country" :layer="$store.state.layer"
          :at_when="previous_map_date.toISOString().substr(0,10)"
          :controls="false"
          :always_show_entire_map="true"
          :content-padding="5"
          :map_filter="local_map_filter"
          :border-size="0.5" :border-color="'white'"
          :height="'500px'"></web-sec-map>
          </div>
      </b-col>
    </b-row>

    <div class="m-4">
      <b-button @click="add_more" variant="primary">{{ $t('add_maps') }}</b-button>
    </div>


  </b-container>
  <b-container>
    <BackToTop/>
      </b-container>
</div>

</template>

<style>
.display-3 {
  font-size: 3.7rem;
}

</style>
<i18n>
{
  "en": {
    "title": "Progress",
    "intro": "This page (hopefully) shows the progress of a specific test.",
    "everything": "Show all tests",
    "select_metric": "Select a test",
    "add_maps": "Add half a year of maps",
    "showing_months": "Currently showing {0} months.",
    "showing_months_reduce": "Reduce to {0} months"
  },
  "nl": {
    "title": "Voortgang",
    "intro": "Deze pagina toont (hopelijk) de voortgang van een specifieke test",
    "everything": "Toon alle tests",
    "select_metric": "Selecteer een meting",
    "add_maps": "Voeg een half jaar aan kaarten toe",
    "showing_months": "Er worden nu {0} kaarten getoond.",
    "showing_months_reduce": "Terugbrengen naar {0} kaarten"
  }
}
</i18n>

<script>
import BackToTop from "@/components/BackToTop";
import { subMonths } from 'date-fns'
import WebSecMap from "@/components/map/WebSecMap";

export default {
  name: "MetricsProgressPage",
  components: {WebSecMap, BackToTop},
  data: function () {
    return {
      issuel: {},
      previous_map_dates: [],
      options: [],
      local_map_filter: "",
    }
  },
  mounted: function () {
    this.issuel = this.$store.state.issues;
    this.init();
  },
  methods: {
    set_filter(filter_name){
      if (!this.is_valid_filter(filter_name))
        return
      this.local_map_filter = filter_name;
    },
    is_valid_filter(filter_name){
      return this.issuel[filter_name] !== undefined
    },
    init(){
      this.create_v_select_options();

      let currentTime = new Date()
      // hours on 14 due to a mixup with dailight savings time in NL. All stored dates are UTC, so saying 00:00
      // will mean the previous day technically.
      let earliest_date = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1, 14)
      this.previous_map_dates.push(earliest_date)
      this.more_months(5)
    },
    more_months(months=3){
      for (let i=0; i<months; i++){
        let earliest_date = subMonths(this.previous_map_dates[this.previous_map_dates.length -1], 1)
        // hope it's a copy :)
        this.previous_map_dates.push(earliest_date)
      }
    },
    add_more(){
      this.more_months(6);
    },
    reduce_map_dates(amount){
      this.previous_map_dates = this.previous_map_dates.slice(0,amount);
    },
    create_v_select_options(){
      this.options = [];
      this.options.push({'code': '', 'label': this.$t("everything")})
      Object.keys(this.issuel).forEach(issue => {
        if (this.$store.state.config.show.issues[this.issuel[issue].name]) {
          this.options.push({'code': this.issuel[issue].name, 'label': `${this.translate_issue_name(this.issuel[issue].name)} - ${this.categories(this.issuel[issue].name)}`})
        }
      })
    }
  },

  watch: {
    local_map_filter(new_value, old_value) {
      console.log("setting local map filter")
      if (old_value === new_value) {
        console.log("old value")
        return
      }

      if (!this.is_valid_filter(new_value)) {
        console.log("filter not valid")
        return
      }

      this.$router.push(`/${this.$router.currentRoute.meta.basename}/${this.$store.state.country}/${this.$store.state.layer}/${this.local_map_filter}`);
    },
    // apparently it will not automatically update on boot
    '$store.state.issues': {
      deep: false,
      handler() {
        this.issuel = this.$store.state.issues;
        this.create_v_select_options();
        this.set_filter(this.$route.params.filter);
      }
    }
  }
}
</script>
