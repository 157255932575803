<template>
  <div>
    <b-row>
      <b-col>
        <b-alert show variant="info" v-if="top3.length === 0">{{ $t("no_data_found") }}</b-alert>
      </b-col>
    </b-row>

    <loading-spinner :loading="loading"></loading-spinner>

    <template v-if="top3.length > 0">
      <b-row v-for='rank in top3' :key='rank.rank'>
        <b-col style="text-align: center;">
          <span style="font-size: 60px">#{{ rank['rank'] }}</span>
          <h3>{{ rank['organization_name'] }}</h3>
          <p>
            <b>{{ $t("urls") }}:</b> {{ rank['total_urls'] }},
            <b>{{ $t("services") }}:</b> {{ rank['total_endpoints'] }}
          </p>

          <b-button class="whitelink" :to="'/report/'+rank['organization_id']" variant="primary">🔍 {{ $t("view_report") }}</b-button>

        </b-col>
        <b-col v-if="$store.state.config.app.relevant_for_ui.high">
          <point-score :value="rank['high']" subject="high"></point-score>
        </b-col>
        <b-col v-if="$store.state.config.app.relevant_for_ui.medium">
          <point-score :value="rank['medium']" subject="medium"></point-score>
        </b-col>
      </b-row>
    </template>

    <b-row v-if="fulldata.length > 2">


      <b-input-group size="lg" prepend="Filter" class="pt-4" v-if="fulldata.length > 9">

        <b-form-input v-model="domain_name_filter" debounce="100"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" text="Button" variant="primary" @click="domain_name_filter = ''">{{ $t('clear') }}</b-button>
        </b-input-group-append>
      </b-input-group>


      <b-table :items="fulldata" :fields="fields" hover striped responsive class="mt-3"        :sort-by.sync="sortBy"
       :sort-desc.sync="sortDesc" sort-direction="desc" sticky-header="80vh" :filter="domain_name_filter">
        <template #cell(organization_name)="data">
          <router-link :to="'/report/'+data.item.organization_id">🔍 {{ data.item.organization_name }}</router-link>
        </template>

        <template #cell(high)="data">
          <b-progress class="mt-2 mb-2" show-value height="1rem">
            <b-progress-bar :value="data.item.high / (highest_high) * 100" variant="danger">{{data.item.high}}</b-progress-bar>
          </b-progress>
        </template>

        <template #cell(medium)="data">
          <b-progress class="mt-2 mb-2" show-value height="1rem">
            <b-progress-bar :value="data.item.medium / (highest_medium) * 100" variant="warning">{{data.item.medium}}</b-progress-bar>
          </b-progress>
        </template>

        <template #cell(low)="data">
          <b-progress class="mt-2 mb-2" show-value height="1rem">
            <b-progress-bar :value="data.item.low / (highest_low) * 100" variant="secondary">{{data.item.low}}</b-progress-bar>
          </b-progress>
        </template>
      </b-table>
    </b-row>
  </div>
</template>
<style scoped>
.whitelink{
  color: var(--light);
}

</style>

<script>
import http from "@/httpclient";
import country_layer_switch_mixin from "@/CountryLayerSwitchMixin";
import PointScore from "@/components/PointScore";
import TimeMachineMixin from "@/components/TimeMachineMixin";

export default {
  components: {PointScore},
  mixins: [country_layer_switch_mixin, TimeMachineMixin],
  data: function () {
    return {
      sortBy: 'high',
      sortDesc: true,

      data: Array, // a short list of 10 items.
      top3: Array,
      fulldata: Array, // a much larger list.
      loading: false,

      domain_name_filter: '',
    }
  },
  mounted: function () {
    this.load()
  },
  props: {
    data_url: String,
  },
  computed: {
    highest_high() {return Math.max(...this.highs)},
    highest_medium() {return Math.max(...this.mediums)},
    highest_low() {return Math.max(...this.lows)},
    highs() {return this.data.map(item => parseInt(item.high))},
    mediums() {return this.data.map(item => parseInt(item.medium))},
    lows() {return this.data.map(item => parseInt(item.low))},
    fields() {
      let visible_fields = [
        {key: "rank", sortable: true, label: this.$i18n.t('rank')},
        {key: "organization_name", sortable: true, label: this.$i18n.t('organization')},
        {key: "total_urls", sortable: true, label: this.$i18n.t('urls')},
        {key: "total_endpoints", sortable: true, label: this.$i18n.t(this.$store.state.config.grading_policy + '_services')},
      ]

      if (this.$store.state.config.app.relevant_for_ui.high)
        visible_fields.push({key: "high", sortable: true, label: this.$i18n.t(this.$store.state.config.grading_policy + '_score_high')})

      if (this.$store.state.config.app.relevant_for_ui.medium)
        visible_fields.push({key: "medium", sortable: true, label: this.$i18n.t(this.$store.state.config.grading_policy + '_score_medium')})

      if (this.$store.state.config.app.relevant_for_ui.low)
        visible_fields.push({key: "low", sortable: true, label: this.$i18n.t(this.$store.state.config.grading_policy + '_score_low')})

      return visible_fields
    }
  },
  methods: {
    load: function () {
      this.loading = true;

      let data_url = ""
      if (this.$store.state.time_machine_date) {
        data_url = `${this.data_url}${this.$store.state.country}/${this.$store.state.layer}/${this.$store.state.time_machine_date}/`;
      } else {
        data_url = `${this.data_url}${this.$store.state.country}/${this.$store.state.layer}/0/`;
      }

      http.get(data_url).then(data => {
        this.data = data.data.ranking.slice(3, 10);
        this.top3 = data.data.ranking.slice(0, 3);
        this.fulldata = data.data.ranking.slice(3, data.data.ranking.length);
        this.loading = false;
      });
    },
  },
};
</script>
<i18n>
{
  "en": {
    "data_from": "Data from: ",
    "expand_list": "View entire list",
    "rank": "#",
    "organization": "Organization",
    "urls": "Domains",
    "connectivity": "connectivity",
    "no_data_found": "No data found, statistics are probably not ready or there really is no data.",
    "clear": "Clear"
  },
  "nl": {
    "data_from": "Gegevens van: ",
    "expand_list": "Bekijk hele lijst",
    "rank": "#",
    "organization": "Organisatie",
    "urls": "Domeinen",
    "connectivity": "Diensten",
    "no_data_found": "Geen gegevens gevonden, statistieken zijn waarschijnlijk nog niet gemaakt of er zijn echt geen gegevens.",
    "clear": "Wissen"
  }
}
</i18n>
