<style>
#map-switch, #map-switch .nav-link, #map-switch .navbar-expand {
  padding: 0 !important;
}
</style>

<template>
    <div style="max-width: 300px; z-index: 1000;" class="info table-light" v-if="simplestats[state.country]">
      <!-- Only if there are stats -->
      <template v-if="simplestats[state.country][state.layer] !== undefined">
        <!-- if there are multiple countries -->
        <template v-if="Object.keys(simplestats).length > 2">
          <h4><img
            :src="simplestats[state.country][state.layer].country_flag"/>{{
              simplestats[state.country][state.layer].country_name
            }},
            <b-navbar id="map-switch"><b-navbar-nav><menu-country-layer></menu-country-layer></b-navbar-nav></b-navbar></h4>
        </template>
        <template v-else>
          <h4><b-navbar id="map-switch"><b-navbar-nav><menu-country-layer></menu-country-layer></b-navbar-nav></b-navbar></h4>
        </template>
        {{ simplestats[state.country][state.layer].organizations }}
        {{ $t('organizations') }}<br>
        {{ simplestats[state.country][state.layer].urls }} {{ $t('internet_adresses') }}<br>
        {{ simplestats[state.country][state.layer].services }} {{ $t($store.state.config.grading_policy + '_services') }}<br>
      </template>
    </div>
</template>

<script>
import http from "@/httpclient";
import MenuCountryLayer from "@/components/menu/MenuCountryLayer";
import TimeMachineMixin from "@/components/TimeMachineMixin";

export default {
  mixins: [TimeMachineMixin],
  components: {MenuCountryLayer},

  mounted: function () {
    this.load();
    this.state = this.$store.state;
  },

  data: function () {
    return {
      simplestats: {},
      state: {},
    }
  },
  methods: {
    load: function () {
      if (this.state.layer && this.state.country) {

        let url = ""
        if (this.$store.state.time_machine_date) {
          url = `/data/short_and_simple_stats/${this.$store.state.time_machine_date}/`
        } else {
          url = `/data/short_and_simple_stats/${this.$store.state.week}/`
        }

        http.get(url).then((data) => {
          this.simplestats = data.data;
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
