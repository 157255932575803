import { render, staticRenderFns } from "./AnnouncementMessage.vue?vue&type=template&id=5ea04040&v-if=%24store.state.config.show.announcement&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports