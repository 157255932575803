<template>
  <div v-if="$store.state.config.show.time_machine">
    <b-button id="popover-target-1" variant="primary" :class="$store.state.time_machine_date ? 'time_machine_button': ''">

      <template v-if="!$store.state.time_machine_date">
        <b-icon icon="clock-history" /> {{ $t('disabled') }}
      </template>
      <template v-else>
        <b-icon icon="clock" animation="spin" /> {{humanize_date($store.state.time_machine_date)}}
      </template>

    </b-button>

    <b-popover
          target="popover-target-1"
          title="Tijdmachine"
          placement="bottom"
          triggers="hover focus"
          content="Embedding content using properties is easy"
        >

      <b-badge
        style="font-size: 1.2em;"
        variant="danger"
        v-if="$store.state.time_machine_date"
        @click="clearDate"
      >
        <b-icon icon="alarm" />  {{ $t('disable') }}
      </b-badge>

      <b-calendar class="mt-2"
        v-model="$store.state.time_machine_date"
        selected-variant="success"
        today-variant="info"
        nav-button-variant="primary"
        :date-disabled-fn="dateDisabled"
        :hide-header="false"
        :locale="$i18n.locale"
        :calendarLocale="$i18n.locale"
        :label-help="''"
      ></b-calendar>

    </b-popover>

  </div>
</template>

<script>
export default {
  name: "TimeMachine",
    methods: {
    dateDisabled(ymd, date) {

        // No dates in the future
        return date > new Date()
    },
      clearDate(){
        this.$store.commit('change', {time_machine_date: null});
      },
  },
  data() {
    return {
      clock: null
    }
  }}
</script>

<style scoped>

button {
  width: 100%;
   white-space: nowrap;
}

div {
  text-align: center;
}


.time_machine_button{
  background-image: url('/public/time_machine/stars.gif');
  background-size: cover;
  background-position: center center;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
}
</style>
<i18n>
{
  "en": {
    "disabled": "Time Machine",
    "enabled": "Time Machine Enabled!",
    "disable": "Disable Time Machine",
    "today": "Today is: "
  },
  "nl": {
    "disabled": "Tijdmachine",
    "enabled": "Tijdmachine ingeschakeld!",
    "disable": "Tijdmachine uitschakelen",
    "today": "Vandaag: "
  }
}

</i18n>
