<template>
  <b-alert variant="info" show v-if="$store.state.config.show.plus_info">
    <span v-html='`${$t(random_announcement())} ${$t("foundation_link")}`'></span>
  </b-alert>
</template>
<script>
export default {
  methods: {
    random_announcement: function () {
      let announcements = ['insight', 'volunteer', 'alerts', 'rescan', 'biweekly'];
      return announcements[Math.floor(Math.random() * announcements.length)]
    }
  }
};
</script>
<i18n>
{
  "en": {
    "tip": "Tip",

    "insight": "🦉 Need more insights? We're stronger together! Become a participant of the Internet Cleanup Foundation, the security community for a safe online world.",
    "volunteer": "🎉 Want to help secure the internet? The Internet Cleanup Foundation is the volunteer organization behind this website. Join every tuesday evening via Discord, the link is on the participant page!",
    "alerts": "🚨 Want to receive an e-mail on regressions? That's also something you get when participating in the Internet Cleanup Foundation. (You can also get that for free via RSS on this site).",
    "rescan": "🕵️ We re-scan and measure often... yet if you want to instantly measure again after you've fixed something? Join the Internet Cleanup Foundation and also get access to Basisbeveiliging+",
    "biweekly": "🎉 Every two weeks we meet up on Discord with the community. Want to join? Look for the link on the foundation page.",

    "foundation_link": "<a style='text-decoration: underline' href='https://internetcleanup.foundation/deelnemerschap/' target='_blank' rel='nofollow'>↗️ Read more about the Internet Cleanup Foundation, volunteering and membership (Dutch)</a>"
  },
  "nl": {
    "tip": "Tip",

    "insight": "🦉 Meer inzichten nodig? Samen staan we sterk! Word deelnemer van de Internet Cleanup Foundation, de security community voor een veilige online wereld.",
    "volunteer": "🎉 Meehelpen aan een veilige online wereld? De Internet Cleanup Foundation is de vrijwilligersorganisatie achter deze site. Haak iedere dinsdag aan via discord, kijk op de deelnemerspagina voor de link!",
    "alerts": "🚨 Direct meldingen bij verslechteringen, ook dat krijg je bij deelnemerschap van de Internet Cleanup Foundation. (Het kan ook gratis via rss op deze site)",
    "rescan": "🕵️ Wij meten vaak opnieuw... maar wil je toch meteen zelf opnieuw meten nadat je iets hebt gewijzigd? Dat kan met Basisbeveiliging+, dat is onderdeel van deelnemerschap van de Internet Cleanup Foundation.",
    "biweekly": "🎉 Iedere twee weken komen we bij elkaar op discord, wil je daar bij zijn? Kijk voor de link op de deelnemerschap pagina.",

    "foundation_link": "<a style='text-decoration: underline' href='https://internetcleanup.foundation/deelnemerschap/' target='_blank' rel='nofollow'>↗️ Lees hier meer over onze stichting, vrijwilligen en deelnemerschap</a>"
  }
}
</i18n>
