<style>
body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 !important;
  min-height: calc(100vh - 230px);
}

h2, h3 {
  padding-bottom: 0.3em;
}

/* Report printing in chrome / safari, so it looks nice */
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    print-color-adjust: exact !important; /*Firefox*/
  }

  .do-not-print {
    display: none;
  }

  /* Support page numbers */
  @page {
    @bottom-right {
      content: counter(page) " / " counter(pages);
    }
  }

}

</style>

<template>
  <div id="app">
    <header class="do-not-print">
      <announcement v-if="$store.state.config.show.announcement === true"></announcement>
      <!-- <main-menu/> -->

      <template v-if="$store.state.config.app.menu === 'dual'">
        <main-menu-dual-bar />
        <sub-menu-app v-if="this.$router.currentRoute.meta.menu === 'app'" />
        <sub-menu-organization v-if="this.$router.currentRoute.meta.menu === 'organization'" />
      </template>
      <template v-else>
        <main-menu-single-bar />
      </template>

      <management-menu v-if="$store.state.config.admin === true"/>
    </header>

    <main style="background-color: var(--light);">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </main>


    <footer class="footer p-2 pt-4 pb-1 do-not-print" style="background-color: var(--dark);">
      <disclaimer v-if="$store.state.config.show.disclaimer === true"></disclaimer>
      <footer-content></footer-content>
    </footer>
    <comply-or-explain-guidelines />
    <Particles
      id="snow_particles" v-if="$store.state.config.show.snow"
      :particlesInit="particlesInit"
      :options='{
          "fullScreen": {
              "zIndex": 1
            },

            particles: {
                move: {
                    direction: "bottom",
                    enable: true,
                    random: false,
                    straight: false,
                },
                opacity: {
                    value: { min: 0.6, max: 0.8 },
                },
                size: {
                    value: { min: 1, max: 10 },
                },
                wobble: {
                    distance: 20,
                    enable: true,
                    speed: {
                        min: -5,
                        max: 5,
                    },
                },
            }}'
    />

    <Particles v-if="$store.state.show_confetti"
               id="tsparticles"
               :particlesInit="particlesInit"


               :options='{
  "fullScreen": {
    "zIndex": 1
  },
  "emitters": {
    "position": {
      "x": 50,
      "y": 100
    },
    "rate": {
      "quantity": 5,
      "delay": 0.15
    }
  },
  "particles": {
    "color": {
      "value": [
        "#1E00FF",
        "#FF0061",
        "#E1FF00",
        "#00FF9E"
      ]
    },
    "move": {
      "decay": 0.05,
      "direction": "top",
      "enable": true,
      "gravity": {
        "enable": true
      },
      "outModes": {
        "top": "none",
        "default": "destroy"
      },
      "speed": {
        "min": 50,
        "max": 100
      }
    },
    "number": {
      "value": 0
    },
    "opacity": {
      "value": 1
    },
    "rotate": {
      "value": {
        "min": 0,
        "max": 360
      },
      "direction": "random",
      "animation": {
        "enable": true,
        "speed": 30
      }
    },
    "tilt": {
      "direction": "random",
      "enable": true,
      "value": {
        "min": 0,
        "max": 360
      },
      "animation": {
        "enable": true,
        "speed": 30
      }
    },
    "size": {
      "value": 3,
      "animation": {
        "enable": true,
        "startValue": "min",
        "count": 1,
        "speed": 16,
        "sync": true
      }
    },
    "roll": {
      "darken": {
        "enable": true,
        "value": 25
      },
      "enlighten": {
        "enable": true,
        "value": 25
      },
      "enable": true,
      "speed": {
        "min": 5,
        "max": 15
      }
    },
    "wobble": {
      "distance": 30,
      "enable": true,
      "speed": {
        "min": -7,
        "max": 7
      }
    },
    "shape": {
      "type": [
        "circle",
        "square"
      ],
      "options": {}
    }
  },
  "responsive": [
    {
      "maxWidth": 1024,
      "options": {
        "particles": {
          "move": {
            "speed": {
              "min": 33,
              "max": 66
            }
          }
        }
      }
    }
  ]
}'
    />


  </div>
</template>

<script>

import FooterContent from '@/components/FooterContent'
import Announcement from '@/components/AnnouncementMessage'
import Disclaimer from '@/components/DisclaimerMessage'
import ManagementMenu from "@/components/menu/ManagementMenu";

import {loadFull} from "tsparticles";
import SubMenuOrganization from "@/components/menu/SubMenuOrganization";
import MainMenuDualBar from "@/components/menu/MainMenuDualBar";
import MainMenuSingleBar from "@/components/menu/MainMenuSingleBar";
import SubMenuApp from "@/components/menu/SubMenuApp";
import ComplyOrExplainGuidelines from "@/components/report/ComplyOrExplainGuidelines";

export default {

  i18n: {
    locale: 'en',
    fallbackLocale: 'en',
    silentFallbackWarn: true,
  },
  name: 'App',
  data: function () {
    return {
      // server-side config:
      heartbeat_last_attempt: null,
      particlesInit: async (engine) => {
        await loadFull(engine);
      },
    }
  },
  methods: {
    // Used as a callout from the map popup.
    direct_navigation_to_report: function (organization_id) {
      this.$store.commit('change', {
        reported_organization: {
          id: organization_id,
          name: null,
        }
      });

      this.$router.push({path: '/report'});
    },
  },
  components: {
    ComplyOrExplainGuidelines,
    SubMenuOrganization,
    MainMenuDualBar,
    MainMenuSingleBar,
    SubMenuApp,
    ManagementMenu,
    FooterContent, Announcement, Disclaimer
  },

}
</script>
