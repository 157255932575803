<template>
  <a :href="endpoint_link(url, endpoint)"
     target="_blank"
     class="site_screenshot"
     :title="$t('visit', [endpoint_link(url, endpoint)])"
  >
    <div>
      <b-img-lazy
        :src="endpoint_image_src(url, endpoint)"
        class="img-fluid rounded clippedimage"
        :alt="$t('missing', [endpoint_link(url, endpoint)])"
      />
    </div>
  </a>
</template>

<script>
export default {
  name: "EndpointScreenshot",
  props: {
    endpoint: {type: Object, required: true},
    url: {type: Object, required: true}
  },
  methods: {
    endpoint_image_src(url, endpoint) {
      return this.$store.state.websecmap_endpoint + '/screenshots/' + this.screenshot_directory(endpoint.id) + '/' + endpoint.id + '_latest.png'
    },
    endpoint_link(url, endpoint) {
      return endpoint.protocol === 'dns_a_aaaa' ? 'https' : endpoint.protocol + '://' + url.url + ':' + (endpoint.port === 0 ? '' : endpoint.port)
    },
    screenshot_directory(endpoint_id) {
      // 0_latest.png to 999_latest.png
      if (endpoint_id < 1000)
        return "000"
      return endpoint_id.toString().substring(0, 3)
    }
  }
}
</script>

<style scoped>


.clippedimage {
  min-width: 320px; /* placeholder */
  min-height: 200px; /* placeholder */
  position: absolute;
  clip: rect(0, 320px, 200px, 0);
  border: 1px solid gray;

  /* If we can't find the image, would this then show? */
  background: gainsboro repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255, 255, 255, .5) 35px, rgba(255, 255, 255, .5) 70px);

  /* Not setting the image width and height explicitly will make the image 100% width on ios / safari. nov 2018 */
  width: 320px;
  height: 200px;
  margin-left: -160px;
}

.site_screenshot {
  display: block;
  min-width: 320px;
  min-height: 200px;

}
</style>
<i18n>
{
  "en": {
    "visit": "Visit {0}",
    "missing": "Missing screenshot of {0}"
  },
  "nl": {
    "visit": "Bezoek {0}",
    "missing": "Ontbrekende schermafbeelding van {0}"
  }
}
</i18n>
