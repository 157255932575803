<template>
  <b-modal size="xl" id="move-organization" scrollable title="Move Organization" cancel-variant="danger"
           cancel-title="Stop" ok-text="Update" ok-variant="success" @ok="update_coordinate" @cancel="stop"
           @show="sync_current_data">
    <server-response :response="server_response"/>

    <GoogleLocationSearch class="mb-4" :showAddress="false" :name-disabled="true" :initialName="name"
                          :initialLongitude="longitude" :initialLatitude="latitude"
                          @coordinates_updated="update_latlong"/>
    <b-button variant="danger" @click="delete_coordinate">
      <b-icon icon="trash"></b-icon>
      Delete Coordinate
    </b-button>

    <server-response :response="delete_server_response"/>

  </b-modal>
</template>

<script>
import GoogleLocationSearch from "@/components/management/GoogleLocationSearch";
import http from "@/httpclient";

export default {
  name: "MoveOrganization",
  components: {GoogleLocationSearch},

  data() {
    return {
      name: null,
      latitude: 0,
      longitude: 0,
      coordinate_id: 0,

      server_response: {},
      delete_server_response: {}
    }
  },

  methods: {

    update_latlong(new_data) {
      this.latitude = new_data.latitude;
      this.longitude = new_data.longitude;

      // todo: send to the server
    },

    stop: function () {

    },


    update_coordinate(event) {

      event.preventDefault();

      let data = {coordinate_id: this.coordinate_id, latitude: this.latitude, longitude: this.longitude}

      http.post(`data/admin/organization/move_coordinate/`, data).then((server_response) => {
          if(server_response.data)
            this.server_response = server_response.data;
        }
      );
    },

    delete_coordinate() {
      let answer = confirm("Do you want to delete this coordinate?");
      if (answer !== true) return;

      let data = {coordinate_id: this.coordinate_id, latitude: this.latitude, longitude: this.longitude}

      http.post(`data/admin/organization/delete_coordinate/`, data).then((server_response) => {
        if(server_response.data)
          this.delete_server_response = server_response.data;
        }
      );
    },

    sync_current_data() {

      this.coordinate_id = this.$store.state.selected_point.geometry.coordinate_id;
      this.latitude = this.$store.state.selected_point.geometry.coordinates[0];
      this.longitude = this.$store.state.selected_point.geometry.coordinates[1];
      this.name = this.$store.state.selected_point.properties.organization_name;

    }
  },
}
</script>

<style scoped>

</style>
