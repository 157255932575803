<template>

  <div class="pt-4 pb-4">
    <b-container class="stats_part p-3 mb-3 mt-2 rounded bg-white">
      <h2>
        <b-icon icon="mailbox"/>
        Responsible Disclosure
      </h2>

      <p>At {{$store.state.config.responsible_organization.name}}, we consider the security
        of our systems a top priority. But no matter how
        much effort we put into system security, there can
        still be vulnerabilities present.</p>

      <p>If you discover a vulnerability, we would like to
        know about it so we can take steps to address it as
        quickly as possible. We would like to ask you to
        help us better protect our clients and our systems.</p>

      <h2>Please do the following</h2>
      <ul>
        <li>
          E-mail your findings to
          <a :href="`mailto:${$store.state.config.responsible_disclosure_page.email_address}`">info@internetcleanup.foundation</a>.
          Encrypt your findings using our
          PGP key <pre>{{$store.state.config.responsible_disclosure_page.pgp_key}}</pre> to
          prevent this critical information from falling
          into the wrong hands, <div v-if="$store.state.config.responsible_disclosure_page.salsa_key">or put everything in a file and use our salsa key: <pre>{{$store.state.config.responsible_disclosure_page.salsa_key}}</pre></div>
        </li>
        <li>
          Do not take advantage of the vulnerability
          or problem you have discovered, for example
          by downloading more data than necessary to
          demonstrate the vulnerability or deleting or
          modifying other people's data,
        </li>
        <li>
          Do not reveal the problem to others until it
          has been resolved,
        </li>
        <li>
          Do not use attacks on physical security, social
          engineering, distributed denial of service,
          spam or applications of third parties, and
        </li>
        <li>
          Do provide sufficient information to
          reproduce the problem, so we will be able to
          resolve it as quickly as possible. Usually,
          the IP address or the URL of the affected
          system and a description of the
          vulnerability will be sufficient, but
          complex vulnerabilities may require further
          explanation.
        </li>
      </ul>

      <h2>What we promise</h2>
      <ul>
        <li>
          We will respond to your report within 5
          business days with our evaluation of the
          report and an expected resolution date,
        </li>
        <li>
          If you have followed the instructions above,
          we will not take any legal action against
          you in regard to the report,
        </li>
        <li>
          We will handle your report with strict
          confidentiality, and not pass on your
          personal details to third parties without
          your permission,
        </li>
        <li>
          We will keep you informed of the progress
          towards resolving the problem,
        </li>
        <li>
          In the public information concerning the
          problem reported, we will give your name as
          the discoverer of the problem (unless you
          desire otherwise), and
        </li>
        <li>
          As a token of our gratitude for your
          assistance, we offer a reward for every
          report of a security problem that was not
          yet known to us. The amount of the reward
          will be determined based on the severity of
          the leak and the quality of the report. The
          minimum reward will be a &euro;25 gift
          certificate.
        </li>
      </ul>


      <p>We strive to resolve all problems as quickly as
        possible, and we would like to play an active role
        in the ultimate publication on the problem after it
        is resolved.</p>

      <p>Text and policy inspired by / copied in verbatim from responsibledisclosure.nl.</p>
    </b-container>
  </div>
</template>
<script>

export default {

  methods: {}
}
</script>
