<script>

import chart_mixin from "@/components/charts/ChartMixin";
import {Chart, LineController, CategoryScale, LineElement, PointElement, LinearScale, Title, Legend, TimeScale, Tooltip, Filler} from 'chart.js';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend, TimeScale, Tooltip, Filler);

export default {
  mixins: [chart_mixin],

  methods: {
    // let's see if we can do it even better.
    buildChart: function () {
      let context = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(context, {
        type: 'line',
        data: {
          datasets: []
        },
        options: {
          elements: {
            point: {
              radius: 0,
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: this.display_title,
            text: this.translate('title')
          },
          plugins: {
            tooltip: {
              mode: 'index',
              intersect: false,
            },
            legend: {
              display: this.display_legend,
            }
          },
          scales: {
            x: {
              adapters: {
                date: {
                  locale: this.locale_text_data
                }
              },
              display: this.display_x_scale,
              // todo: change date source format
              type: 'time',
              time: {
                unit: 'month'
              },
              distribution: 'linear',
              title: {
                display: false,
                text: this.translate('xAxis_label'),
              }
            },
            y: {
              display: this.display_y_scale,
              stacked: false,
              suggestedMin: 0,
              min: 0,
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
              title: {
                display: false,
                text: this.translate('yAxis_label'),
              }
            }
          }
        }
      });
    },

    // applyProps: function() {
    //   this.chart.options.scales.x.display = self.display_x_scale
    // },

    renderData: function () {
      let data = this.data;

      if (data === undefined)
        return;

      let possible_axis = ['ok', 'good', 'low', 'medium', 'high'];

      let ax_data = {'labels': [], 'high': [], 'medium': [], 'low': [], 'good': [], 'ok': []};

      for (let i = 0; i < this.data.length; i++) {

        if (data[i].date < this.from_date_when_number_of_days)
          continue;

        ax_data['labels'].push(data[i].date);
        ax_data['good'].push(data[i].good);
        ax_data['ok'].push(data[i].ok);
        ax_data['low'].push(data[i].low);
        ax_data['medium'].push(data[i].medium);
        ax_data['high'].push(data[i].high);
      }

      this.chart.data.fill = true;
      this.chart.data.labels = ax_data['labels'];

      this.chart.data.datasets = [];
      possible_axis.forEach((ax) => {
          if (this.axis.includes(ax)) {
            this.chart.data.datasets.push(
              {
                label: this.$t(`${this.$store.state.config.grading_policy}_score_${ax}`),
                data: ax_data[ax],
                backgroundColor: this.color_scheme[`${ax}_background`],
                borderColor: this.color_scheme[`${ax}_border`],
                borderWidth: 3,
                lineTension: 0,
                hidden: !this.axis.includes(ax),
                // fill: 'start',
              }
            )
          }
        }
      );

      // this.applyProps();
      this.chart.update();
      // this.applyProps();
    }
  }
};
</script>
