<template>
<b-form-group
            label="Map Country / Layer"
            label-for="input-countrylayer"
            :description="`Select any layer on the map. Currently selected: country code: ${this.countrycodeandlayer.country}, layer: ${this.countrycodeandlayer.layer}.`"
            class="mb-2"
          >
            <b-form-select id="input-countrylayer" v-model="countrycodeandlayer" @change="handleCountryLayerChange">
              <b-form-select-option-group :label="`${country.name} (${country.code})`"
                                          v-for="country in this.$store.state.config.country_and_layers"
                                          :key="country.code">
                <b-form-select-option :value="{'country': country.code, 'layer': layer}" v-for="layer in country.layers"
                                      :key="`${country}${layer}`">{{ country.name }} - {{ $t(layer) }}
                </b-form-select-option>
              </b-form-select-option-group>
            </b-form-select>

          </b-form-group>
</template>

<script>
export default {
  name: "CountryLayerSelection",

  data() {
    return {
      countrycodeandlayer: {'country': this.$store.state.country, 'layer': this.$store.state.layer},
    }
  },

  // todo: is there a shorthand for bubbling op events?
  methods: {
    handleCountryLayerChange() {
      this.$emit('change', this.countrycodeandlayer)
    }
  }

}
</script>

<style scoped>

</style>
