<template>
  <div>
    <div v-if="!$store.state.current_geolocation">
      <b-button @click="updateLocation" size="sm">🙋 {{ $t('share') }} <b-icon v-if="loading" icon="arrow-repeat" animation="spin" /></b-button><br>
    </div>
    <div v-else>
      <b-button @click="updateLocation" variant="success" class="mr-2" size="sm">🌍 {{ $t('update') }} <b-icon v-if="loading" icon="arrow-repeat" animation="spin" />
      <!-- connecting though your phone etc makes you show up elsewhere.
      <br><small>{{this.roundTo($store.state.current_geolocation.coords.latitude,5)}} {{this.roundTo($store.state.current_geolocation.coords.longitude,5)}}</small>-->
      </b-button>
      <!-- <b-button @click="clearLocation" size="sm">Forget my location</b-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "AskGeolocationPermission",
  data() {
    return {
      currentLocation: undefined,
      loading: false,
    }
  },
  methods: {
    updateLocation(){
      // it's also possible to use watchposition, which automatically updates.
      // that might be a bit too much.
      this.loading = true;
      navigator.geolocation.getCurrentPosition((position) => {
        // we need a sensible default of things that are nearby. You would care less about nearby hospitals
        // than nearby schools. But it's nice to compare them. I think about 5 makes sense as a start of this value.
        this.$store.state.current_geolocation = position;
        this.loading = false;
      }, () => {
        this.$store.state.current_geolocation = undefined;
        this.loading = false;
      });
    },

    clearLocation(){
      this.$store.state.current_geolocation = undefined;
    }

  }
}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "share": "Share my current location",
    "update": "Update location"
  },
  "nl": {
    "share": "Deel mijn huidige locatie",
    "update": "Locatie bijwerken"
  }
}
</i18n>
