<template>
  <div v-if="show">
    <h5>&nbsp; {{$t('test')}}: {{$t(`friendly_issue_translations.${rating.type}.title`)}}</h5>

    <div class="pl-3 pb-3">

      <template v-if="explained">
        <span class="awarded_points awarded_points_explained mr-1">{{$t(`${$store.state.config.grading_policy}_score_${grade}`) }}</span>
        {{ $t(rating.comply_or_explain_explanation) }}<br/>
        {{ $t("explained_on") }}: {{ humanize_relative_date(rating.comply_or_explain_explained_on) }},
        {{ $t("explanation_expires") }}: {{ humanize_relative_date(rating.comply_or_explain_explanation_valid_until) }}<br>
      </template>

      <div :class="explained ? 'strikethrough' : ''">
        <span :class="'awarded_points mr-1 awarded_points_' + grade">{{ $t(`${$store.state.config.grading_policy}_score_${grade}`) }}</span>&nbsp;
        <span v-html="create_rating_translation(rating)"></span> <br/>
        <span v-html="create_rating_explanation(rating)" v-if="create_rating_explanation(rating) && grade !== 'good'" class="mt-2 mb-2 pl-2" style="font-size: 0.9em"></span>


        <div class="evidence" v-if="rating.evidence">
          <b-badge style="font-size: 0.9em" v-b-toggle="`${id}-${rating.type}`" class="m-1"><b-icon icon="chevron-down" />&nbsp;<b-icon icon="camera-fill" />&nbsp;{{$t('technical information')}}</b-badge>
          <b-collapse :id="`${id}-${rating.type}`">
            <pre>{{perdypurnt(rating.evidence, rating.type)}}</pre>
          </b-collapse>
        </div>

        <template v-if="what_does_this_mean_available">
          <b-badge style="font-size: 0.9em" v-b-toggle="`${id}-${rating.type}-explanation`" class="m-1"  variant="info"><b-icon icon="chevron-down" />&nbsp;<b-icon icon="info-circle" /> {{$t('what does this mean')}}</b-badge>
          <b-collapse :id="`${id}-${rating.type}-explanation`">
            <b-alert show variant="info">
              <h6><b-icon icon="book" />&nbsp;{{$t('meaning')}}</h6>
              <p class='explanation_text' v-html="$t(`friendly_issue_translations.${rating.type}.explanation`)"></p>
            <p class='technical_text' >{{$t('technical_test_name')}}: {{ $t(`friendly_issue_translations.${rating.type}.technology`) }}</p>
          </b-alert>
          </b-collapse><br>
        </template>

        <span style="font-size: 0.9em;">{{ $t("since") }}: {{ humanize_relative_date(rating.since) }},
          {{ $t("last_check") }}: {{ humanize_relative_date(rating.last_scan) }}</span>
      </div>


      <div class="finding_references" v-if="grade !== 'good'">
        <second-opinion-links :rating="rating" :url="url.url"/> <!-- $store.state.config.comply_or_explain.email_address, rating, url -->
        <template v-if="!explained && $store.state.config.show.comply_or_explain && $store.state.config.comply_or_explain.email_address">
          <a :href="`mailto:${$store.state.config.comply_or_explain.email_address}?subject=${encodeURIComponent($store.state.config.comply_or_explain.email_subject)}&body=${encodeURIComponent(`${$store.state.config.comply_or_explain.email_body}`.format(url.url, rating.type, rating.scan, rating.high, rating.medium, rating.low))}`" class='btn-sm'>💬 {{$t("explain")}}</a>
          <a v-if='$store.state.config.show.comply_or_explain_guidelines' @click="$bvModal.show('comply-or-explain-guidelines')">⛱️ {{$t('Explanation Guidelines')}}</a>
        </template>
        <explain v-if="!explained && $store.state.config.authenticated && endpoint" :endpoint="endpoint" :rating="rating"></explain>
        <explain v-if="!explained && $store.state.config.authenticated && !endpoint" :url="url" :rating="rating"></explain>
      </div>

    </div>
  </div>
  <div v-else>
    <span size="sm" class="mb-2" :class="'awarded_points mr-1  awarded_points_' + grade">{{$t('hidden')}}: {{$t(`friendly_issue_translations.${rating.type}.title`)}}: {{ $t(`${$store.state.config.grading_policy}_score_${grade}`) }}</span>
  </div>
</template>
<i18n>{
  "en": {
    "since": "Since",
    "explained_on": "Explained on",
    "last_check": "Last measured",
    "explained": "Explained",
    "explanation_expires": "Explanation expired on",
    "explain": "Explain",
    "test": "Test",
    "technical information": "Show measured data",
    "what does this mean": "Read more about what this means",
    "technical_test_name": "Technical name of this technology",
    "meaning": "Extended explanation of this test",
    "hidden": "Hidden",
    "Explanation Guidelines": "Guidance for a good explanation"
  },
  "nl": {
    "since": "Sinds",
    "explained_on": "Verklaard op",
    "last_check": "Laatste meting",
    "explained": "Verklaard",
    "explanation_expires": "Verklaring vervalt op",
    "explain": "Verklaar",
    "test": "Test",
    "technical information": "Toon de gemeten gegevens",
    "what does this mean": "Lees meer over wat dit betekent",
    "technical_test_name": "Technische naam van deze technologie",
    "meaning": "Uitgebreide uitleg over deze test",
    "hidden": "Verborgen",
    "Explanation Guidelines": "Handleiding voor een goede verklaring"
  }
}
</i18n>


<script>
import SecondOpinionLinks from "@/components/report/SecondOpinionLinks";

export default {
  name: "ReportFinding",
  components: {SecondOpinionLinks},
  props: {
    rating: {type: Object, required: true},
    endpoint: {type: Object, required: false},
    url: {type: Object, required: false}
  },
  methods: {
    perdypurnt(data, rating_type) {

      if (rating_type === "web_privacy_tracking") {
        // this somehow gets stored in single qoutes, and this translation might just go wrong, but usually not...
        if (data.startsWith("[") || data.startsWith("{"))
          data = this.replaceAll(data, "'", '"')
          return JSON.stringify(JSON.parse(data),null,2);
      }

      if (rating_type === "whois_domain_ownership") {
        // this somehow gets stored in single qoutes, and this translation might just go wrong, but usually not...
        if (data.startsWith("[") || data.startsWith("{"))
          // todo: this will go wrong with apostrophes in the data, which is likely to sometimes happen...
          data = this.replaceAll(data, "'", '"')
          return JSON.stringify(JSON.parse(data),null,2);
      }

      return data
    },
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    },
    create_rating_translation: function (rating) {
      if (rating.type.startsWith('internet_nl')) {

        if (rating.not_testable) {
          return this.translate('internet_nl_not_tested')
        }

        // This is unusual, probably test data
        if (!rating.translation)
          return this.translate('undefined')

        // has various translations, in the translation key:
        let test_verdict_key = rating.type + "_verdict_" + rating.translation.replace("-", "_")
        // console.log(test_verdict_key)

        let translation_attempt = this.translate(test_verdict_key)
        // console.log(translation_attempt)
        if (translation_attempt !== test_verdict_key)
          return translation_attempt

        let category_description_key = rating.type + "_" + rating.translation.replace("-", "_") + "_description"
        // console.log(category_description_key)
        return this.translate(category_description_key)
      }

      if (rating.explanation === "repeated_finding")
        return this.translate('repeated_finding')

      // include the scanner name to easily group translations and prevent ambiguitiy in translations,
      // such as various scanners can have the same result, but should be translated differently.
      if (rating.explanation)
        return this.translate(`${rating.type} ${rating.explanation}`)

      return this.translate(`${rating.type} ${rating.test_result}`)
    },
    create_rating_explanation: function (rating) {
      if (rating.type.startsWith('internet_nl')) {
        // internet.nl categories do not have a translations (yet), this will look a bit off...
        let translation = this.translate(rating.type + "_exp")

          return rating.type + "_exp" !== translation ? translation : ""
      }
    },
  },

  computed: {
    what_does_this_mean_available() {
      return this.$t(`friendly_issue_translations.${this.rating.type}.explanation`) !== `friendly_issue_translations.${this.rating.type}.explanation`
    },
    id(){
      return this.endpoint ? `e_${this.endpoint.id}` : `u_${this.url.id}`
    },
    grade() {
      if (this.rating.not_testable) return "not_tested";
      if (this.rating.error_in_test) return "error_in_test";
      if (this.rating.high > 0) return "high";
      if (this.rating.medium > 0) return "medium";
      if (this.rating.low > 0) return "low";
      return "good";
    },
    explained() {
      return this.rating.comply_or_explain_valid_at_time_of_report;
    },
    show(){
      if (this.grade === "good" && !this.$store.state.report.show_good_in_report)
        return false

      if (this.grade === "low" && !this.$store.state.report.show_low_in_report)
        return false

      if (this.grade === "medium" && !this.$store.state.report.show_medium_in_report)
        return false

      if (this.grade === "high" && !this.$store.state.report.show_high_in_report)
        return false



      return true
    }
  }


}
</script>

<style scoped>
.awarded_points {
  padding: 0 3px;
  font-weight: bold;
  font-size: 13px;
  border-radius: 4px;
}
.strikethrough {
  text-decoration: line-through;
  font-size: 0.8em;
}

pre {
  display: block;
  overflow-y: auto;
  padding: 0.5em;
  background-color: var(--light);
  border-radius: 4px;
  font-weight: normal;

}

.evidence{
  font-weight: 500;
}

.explanation_header{
  font-size: 1.2em;
}

.explanation_text{
  font-size: 0.9em;
  margin-top: 0.3em;
  font-style: italic;
  columns: 100px 2;
}
.human_background{
  padding-left: 1em;
}
</style>

