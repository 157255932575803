<template>
  <div>

    <b-container class="p-3 mb-3 mt-2 rounded bg-white" fluid="xl">

      <b-input-group size="lg" prepend="Filter" class="pb-4" v-if="panels.length > 8">

        <b-form-input v-model="panel_filter" debounce="100"
                      :placeholder="$t('search_panels', [panels.length])"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" text="Button" variant="primary" @click="panel_filter = ''">{{ $t('clear') }}</b-button>
        </b-input-group-append>
      </b-input-group>

      <template>
        <loading-spinner :loading="loading"></loading-spinner>
      </template>

      <b-badge v-if="panel_filter">
        {{ $t('results') }}: {{filtered_panels.length}}<br>
      </b-badge>

      <b-tabs content-class="mt-3">
        <b-tab :title="$t('packages')" active>

          <b-pagination :total-rows="filtered_panels.length" :per-page="perPage" v-model="current_page" :limit="10"
                  v-if="filtered_panels.length>perPage"></b-pagination>

          <b-row v-if="!loading">

            <b-col v-for="panel in paginate(filtered_panels, perPage, current_page)" :key="`${panel.scan_id} ${panel.portal_url}`" class="mb-4 software"
                   style="text-align: center">

              <template v-if="panel.tech.length > 0">

                <tech-info :id="panel.tech[0].product" :version="panel.tech[0].version" :link="panel.portal_url" :show-name="true" />

              </template>
              <template v-else>
                <b-avatar size="250px" class="mb-2"
                        :href="panel.portal_url"
                        target="_blank"
                        :style="`background-color: transparent; border: 1px solid silver; background-image: url('${image_link(panel)}'); background-size: cover; background-position: center center; `"
                        rel="nofollow noreferrer"
                >
                  <!-- background-color: #${value_color(panel.portal_name)}; b-->

                  <!-- <div>{{ panel.portal_name.replace("Login Panel", "") }}</div> --> &nbsp;

                </b-avatar>
                <br><b>{{ nicename(panel.portal_name) }}</b><br>

              </template>

              <router-link style="font-size: 1.2em" :to="`/report/${panel.organization_id}`">{{ panel.organization_name }}</router-link>
              <br>

              {{$t('last_seen')}}: {{ humanize_relative_date(panel.last_scan_moment) }}<br>

              <!--<template v-if="panel.tech.length > 0">

                <b-alert show v-if="panel.tech.length > 1" variant="light" class="p-1 mb-0 mt-2 mb-4">
                  <b-badge  variant="light" class="mb-2">{{$t('also_here')}}</b-badge><br>
                  <tech-info :id="tech.product" :version="tech.version" :border="tech.version !== 'unknown' ? '2px solid red' : '1px solid silver'" v-for="tech in panel.tech.slice(1)" :size="30" :showCve="false" :showVersion="false" :showName="false" :key="`${tech.product}-${uuidv4()}`" badge_position="right" style="display: inline-block" class="m-1" />
                </b-alert>

              </template>-->

            </b-col>
          </b-row>

          <b-pagination :total-rows="filtered_panels.length" :per-page="perPage" v-model="current_page" :limit="10"
                  v-if="filtered_panels.length>perPage"></b-pagination>

        </b-tab>
        <b-tab :title="$t('organizations')">
          <div  v-if="!loading">

            <b-pagination :total-rows="filtered_panels.length" :per-page="perPage" v-model="current_page" :limit="10"
                  v-if="filtered_panels.length>perPage"></b-pagination>

            <b-table striped bordered hover small responsive="sm" :items="filtered_panels" :fields="fields" :filter="panel_filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
                     :current-page="current_page"
           :total-rows="panels.length"
            :per-page="perPage"
            >

              <template #cell(portal_name)="data">
                <span style="white-space: nowrap;">
                <b-avatar size="20px" class="mb-2"
                          :href="data.item.portal_url"
                          target="_blank"
                          :style="`background-color: transparent; border: 1px solid silver; background-image: url('${image_link(data.item)}'); background-size: cover; background-position: center center; `"
                          rel="nofollow noreferrer"
                >
                  &nbsp;
                </b-avatar>
                {{ nicename(data.item.portal_name) }}
                </span>
              </template>

              <template #cell(portal_url)="data">
                <a :href="data.value" target="_blank" rel="nofollow">
                  <div style="width: 300px; overflow-x: hidden; white-space: nowrap;"><b-icon icon="box-arrow-up-right" /> {{data.value}}</div>
                </a>
              </template>

            </b-table>

            <b-pagination :total-rows="filtered_panels.length" :per-page="perPage" v-model="current_page" :limit="10"
                  v-if="filtered_panels.length>perPage"></b-pagination>

          </div>
        </b-tab>

      </b-tabs>

      <template v-if="!panels.length && !loading">
        <b>🎉 {{$t('empty')}}</b>
      </template>

    </b-container>

  </div>
</template>

<script>
import http from "@/httpclient";
import country_layer_switch_mixin from "@/CountryLayerSwitchMixin";
import TechInfo from "@/components/techInfo";

export default {
  components: {TechInfo},
  mixins: [country_layer_switch_mixin],
  name: "LoginPlaza",

  data() {
    return {
      panels: [],
      filtered_panels: [],
      panel_filter: "",
      loading: false,

      // pagination:
      perPage: 100,
      current_page: 1,


      // table view:
      sortBy: 'organization_name',
      sortDesc: false,
      fields: [
        {
          key: 'organization_name',
          sortable: true,
          label: this.$t("Organization")
        },
        {
          key: 'portal_name',
          sortable: true,
          label: this.$t("software")
        },
        {
          key: 'portal_url',
          sortable: false,
          label: this.$t("address")
        },
      ]
    }
  },

  mounted() {
    this.load()
    if (this.$route.params.search_query) {
      this.panel_filter = this.$route.params.search_query;
      this.apply_panel_filter()
    }
  },

  watch: {
    panel_filter() {
      this.apply_panel_filter()
      this.current_page = 1;
    },

  },

  methods: {
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },

    retrieve_and_cache_products() {
      // don't retrieve twice...
      if (this.$store.state.technologies.length > 0)
        return

      http.get(`/data/login_plaza/products/`).then(data => {
        this.$store.state.technologies = data.data;
      })
    },

    apply_panel_filter() {

      if (this.panel_filter === "") {
        this.filtered_panels = this.panels;
        return
      }

      let results = []
      this.panels.forEach(panel => {
        if (panel.portal_name.toLowerCase().includes(this.panel_filter.toLowerCase()) || panel.organization_name.toLowerCase().includes(this.panel_filter.toLowerCase())) {
          results.push(panel)
        }
      })
      this.filtered_panels = results;

    },

    paginate(array, page_size, page_number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },



    load() {
      this.loading = true;

      this.retrieve_and_cache_products();

      http.get(`/data/login_plaza/${this.$store.state.country}/${this.$store.state.layer}/`).then(data => {
        this.panels = data.data;
        this.filtered_panels = data.data;
        this.loading = false;

        // keep searching even after switching layers
        this.apply_panel_filter()
      })
    },
    image_link(panel) {
      return `/login_plaza_logos/${this.nicename(panel.portal_name).toLowerCase()}.png`
    },
    nicename(name){
      const to_replace = ['Login Panel', ' Admin', 'and Desktop Connections - Web Access', 'Exposed Docs',
        'CMS Detection', 'Dasboard Exposure', 'Login Detected', 'exposed docs', 'Panel', 'Welcome Page - Tech Detect',
        'CMS Documentation', 'Login -  Detect', 'CRM  Login', 'UEM Airwatch'
      ]

      for (let i = 0; i < to_replace.length; i++) {
        name = name.replace(to_replace[i], '')
      }

      return name.trim();
    },

    crc32(r) {
      for (var a, o = [], c = 0; c < 256; c++) {
        a = c;
        for (var f = 0; f < 8; f++) a = 1 & a ? 3988292384 ^ a >>> 1 : a >>> 1;
        o[c] = a
      }
      for (var n = -1, t = 0; t < r.length; t++) n = n >>> 8 ^ o[255 & (n ^ r.charCodeAt(t))];
      return (-1 ^ n) >>> 0
    },
    value_color(data) {
      return this.crc32(data).toString(16)
    },
  }


}
</script>

<style scoped>

.software a, .software a:hover, .software a:visited, .software a:active {
  color: var(--dark);
  text-decoration: none;
  font-weight: bold;
  font-size: 1.3em;
}
</style>
<i18n>
{
  "en": {
    "search_panels": "Search {0} places to log-in",
    "last_seen": "Last seen",
    "empty": "No data found. There might be no panels discovered yet, or everything is cleaned up.",
    "results": "Results",
    "packages": "Packages",
    "organizations": "Organizations",
    "organization": "Organization",
    "software": "Software",
    "address": "Address",
    "also_here": "Other stuff found here..."
  },
  "nl": {
    "search_panels": "Doorzoek {0} plaatsen om in te loggen",
    "last_seen": "Laatst gezien",
    "empty": "Geen gegevens gevonden. Mogelijk zijn er nog geen panelen gevonden of alles is opgeruimd.",
    "results": "Resultaten",
    "packages": "Pakketten",
    "organizations": "Organisaties",
    "organization": "Organisatie",
    "software": "Software",
    "address": "Adres",
    "also_here": "Andere gevonden informatie..."
  }
}

</i18n>
