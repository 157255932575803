<template>
  <l-control position="bottomright" class="hide_on_small_screens">
    <div class="info legend table-light">
      <span class='legend_title'>{{ $t("title") }}</span><br>
      <div style="height: 20px" v-if="$store.state.config.app.relevant_for_ui.good"><i class="map_polygon_good"></i> {{ $t(this.$store.state.config.grading_policy + "_score_good") }}</div>
      <div style="height: 20px" v-if="$store.state.config.app.relevant_for_ui.medium"><i class="map_polygon_medium"></i> {{ $t(this.$store.state.config.grading_policy + "_score_medium") }}</div>
      <div style="height: 20px" v-if="$store.state.config.app.relevant_for_ui.high"><i class="map_polygon_high"></i> {{ $t(this.$store.state.config.grading_policy + "_score_high") }}</div>
      <div style="height: 20px" v-if="$store.state.config.app.relevant_for_ui.high"><i class="map_polygon_unknown"></i> {{ $t(this.$store.state.config.grading_policy + "_score_unknown") }}</div>
    </div>
  </l-control>
</template>

<script>
import {LControl} from 'vue2-leaflet';

export default {
  components: {LControl},
}
</script>

<style scoped>

.legend {
    text-align: left;
    line-height: 18px;
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    /* opacity: 0.7; causes glitch after fullscreen was added. */
}
.legend_title {
    display: block;
    margin-bottom: -12px;
    font-weight: bold;
}
</style>
<i18n>
{
  "en": {
    "title": "Legend"
  },
  "nl": {
    "title": "Legenda"
  }
}

</i18n>
