<style scoped>
li a:any-link {
  color: white;
  text-decoration: none
}

.router-link-exact-active {
  font-weight: normal !important;
  border-bottom: none;
}

</style>

<template>
  <b-container>
    <div class="title_logo" v-if="'app' in $store.state.config && $store.state.config.app.logo_image">
      <router-link to="/maps">
        <div v-html="$store.state.config.app.logo_image" class="title_logo_image"></div>
      </router-link>
    </div>
    <b-navbar toggleable="lg" type="dark" variant="dark">

      <b-navbar-brand to="/maps">
        <b-icon icon="compass"/>
        {{ $t("menu.maps") }}
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse-content"></b-navbar-toggle>

      <b-collapse id="nav-collapse-content" is-nav>

        <b-navbar-nav>

          <menu-country-layer :showTitle="false" :showIfEmptyOrSingle="false"></menu-country-layer>

          <b-nav-item to="/map" accesskey="m" exact exact-active-class="active">
            <b-icon icon="map"/>
            {{ $t('menu.map') }}
          </b-nav-item>

          <b-nav-item to="/statistics" accesskey="s" exact exact-active-class="active"
                      v-if="$store.state.config.show.statistics">
            <b-icon icon="bar-chart-line"/>
            {{ $t("menu.statistics") }}
          </b-nav-item>

          <b-nav-item to="/charts" accesskey="c" exact exact-active-class="active"
                      v-if="$store.state.config.show.charts">
            <b-icon icon="trophy"/>
            {{ $t("menu.charts") }}
          </b-nav-item>

          <b-nav-item to="/report" accesskey="r" exact exact-active-class="active">
            <b-icon icon="file-bar-graph"/>
            {{ $t("menu.reports") }}
          </b-nav-item>


          <template
            v-if="$store.state.config.show.comply_or_explain || $store.state.config.show.changes || $store.state.config.show.scan_schedule || $store.state.config.show.datasets">

            <b-nav-item-dropdown
              id="my-nav-dropdown"
              toggle-class="nav-link-custom"
              right
            >
              <template #text>
                <b-icon icon="plus-square"/>
                {{ $t('menu.more') }}
              </template>

              <b-dropdown-item to="/comply-or-explain" accesskey="o" exact exact-active-class="active"
                               v-if="$store.state.config.show.comply_or_explain">
                <b-icon icon="chat-left-dots"/>
                {{ $t("menu.comply-or-explain") }}
              </b-dropdown-item>


              <b-dropdown-item to="/scan-monitor" accesskey="n" exact exact-active-class="active"
                               v-if="$store.state.config.show.changes">
                <b-icon icon="newspaper"/>
                {{ $t("menu.scan-monitor") }}
              </b-dropdown-item>
              <b-dropdown-item to="/scan-schedule" accesskey="h" exact exact-active-class="active"
                               v-if="$store.state.config.show.scan_schedule">
                <b-icon icon="calendar2-date"/>
                {{ $t("menu.scan-schedule") }}
              </b-dropdown-item>
              <b-dropdown-item to="/datasets" accesskey="t" exact exact-active-class="active"
                               v-if="$store.state.config.show.datasets">
                <b-icon icon="cloud-download"/>
                {{ $t("menu.datasets") }}
              </b-dropdown-item>

            </b-nav-item-dropdown>
          </template>

        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <switch-locale></switch-locale>

          <b-nav-item to="/about" accesskey="a" exact exact-active-class="active" v-if="$store.state.config.show.intro">
            <b-icon icon="info-circle"/> {{ $t("menu.info") }}
          </b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
  </b-container>
</template>


<script>

import {mapState} from 'vuex'
import SwitchLocale from "@/components/SwitchLocale";
import MenuCountryLayer from "@/components/menu/MenuCountryLayer";

export default {
  components: {SwitchLocale, MenuCountryLayer},
  computed: mapState(['user']),
}

</script>
