<style scoped>
.card-group .card {
  min-width: 40%;
}

.dropbox {
  box-shadow: 3px 7px 14px -2px rgba(0, 0, 0, 0.61);
}

.widecard {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.view-map-button {
  margin-bottom: -20em;
}

.card-body {
  padding: 0;
}

.card-body-text {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

h3 {
  white-space: nowrap;
  font-size: x-large;
}

.card-header{
  overflow: hidden;
}

</style>

<template>
  <b-container>

    <b-row >
      <b-col class="p-3 mb-4 rounded bg-white mr-3 ml-3">
        <h2 v-if="translations.landing">{{translations.landing.side_wide_search}}</h2>
        <system-wide-search class="mb-4"  />

        <nearby-organizations v-if="$store.state.config.show.geolocation_results" />
      </b-col>
    </b-row>


    <div v-for="country in Object.keys($store.state.config.country_and_layers)" :key="country">

      <h2 v-if="Object.keys($store.state.config.country_and_layers) > 1">{{ $t(country) }}</h2>

      <b-card-group deck>

        <b-col sm="12" md="12" lg="6" xl="6" v-for="layer in $store.state.config.country_and_layers[country]['layers']" :key="layer">
          <b-card class="mb-4 widecard">
            <template #header>

              <h3 style="cursor: pointer;" @click="navigateToMap(country, layer)">
                {{ $i18n.t(layer) }}</h3>

            </template>

            <b-card-text>
              <b-row class="card-body-text" v-if="simplestats">
                <b-col>
                  {{ simplestats[country][layer]['organizations'] }} {{ $t('organizations') }}
                  <b-progress class="mt-2 mb-2" show-value height="1rem">
                    <b-progress-bar :value="simplestats[country][layer]['good percentage']"
                                    variant="success">{{ simplestats[country][layer]['good percentage'].toFixed(0) }}%
                    </b-progress-bar>
                    <b-progress-bar :value="simplestats[country][layer]['medium percentage']"
                                    variant="warning">{{ simplestats[country][layer]['medium percentage'].toFixed(0) }}%
                    </b-progress-bar>
                    <b-progress-bar :value="simplestats[country][layer]['high percentage']"
                                    variant="danger"
                    >{{ simplestats[country][layer]['high percentage'].toFixed(0) }}%
                    </b-progress-bar>
                  </b-progress>
                </b-col>
                <b-col> {{ simplestats[country][layer]['urls'] }} {{ $t('domains') }}
                  <b-progress class="mt-2 mb-2" show-value height="1rem">
                    <b-progress-bar :value="simplestats[country][layer]['good url percentage']"
                                    variant="success">{{
                        simplestats[country][layer]['good url percentage'].toFixed(0)
                      }}%
                    </b-progress-bar>
                    <b-progress-bar :value="simplestats[country][layer]['medium url percentage']"
                                    variant="warning">{{
                        simplestats[country][layer]['medium url percentage'].toFixed(0)
                      }}%
                    </b-progress-bar>
                    <b-progress-bar :value="simplestats[country][layer]['high url percentage']"
                                    variant="danger">{{
                        simplestats[country][layer]['high url percentage'].toFixed(0)
                      }}%
                    </b-progress-bar>
                  </b-progress>
                </b-col>
              </b-row>
              <b-overlay style="cursor: pointer;" @click="navigateToMap(country, layer)" :show="'show'"
                         variant="primary" spinner-type="grow"
                         spinner-variant="grow" :z-index="1000" :opacity="0.10">
                <web-sec-map :country="country" :layer="layer" :week="0" :controls="false"
                             :always_show_entire_map="true" :content-padding="5"
                             :height="'500px'"></web-sec-map>
                <template #overlay>
                  <b-button class="dropbox view-map-button" size="lg" variant="primary">{{ $t('view') }}</b-button>
                </template>

              </b-overlay>
            </b-card-text>
            <OrganizationMicroMenu :country="country" :layer="layer" />

          </b-card>

        </b-col>

      </b-card-group>

    </div>

    <simple-stats-table :simplestats="simplestats" v-if="$store.state.config.show.multi_map_summary_table === true" />

  </b-container>

</template>
<script>

import WebSecMap from "@/components/map/WebSecMap";
import http from "@/httpclient";
import SimpleStatsTable from "@/components/stats/SimpleStatsTable";
import TimeMachineMixin from "@/components/TimeMachineMixin";
import SystemWideSearch from "@/components/SystemWideSearch";
import OrganizationMicroMenu from "@/components/menu/OrganizationMicroMenu";
import TranslationsFromConfigMixin from "@/components/TranslationsFromConfigMixin";
import NearbyOrganizations from "@/components/map/geolocation/NearbyOrganizations";

export default {
  components: {NearbyOrganizations, OrganizationMicroMenu, SystemWideSearch, SimpleStatsTable, WebSecMap},
  mixins: [TimeMachineMixin, TranslationsFromConfigMixin],
  data: function () {
    return {
      simplestats: null,
      countries_: [],

      // from conf instead of simplestats
      conf_countries: {},
    }
  },
  mounted: function () {
    this.load();
    this.conf_countries = this.$store.state.config.country_and_layers;
  },
  methods: {
    load: function () {
      let url = ""
      if (this.$store.state.time_machine_date) {
        url = `/data/short_and_simple_stats/${this.$store.state.time_machine_date}/`
      } else {
        url = `/data/short_and_simple_stats/${this.$store.state.week}/`
      }

      http.get(url).then((data) => {
        this.simplestats = data.data;
        this.countries_ = Object.keys(data.data);
      });
    },
  },
}
</script>
<i18n>
{
  "en": {
    "view": "View this map",
    "organizations": "organizations",
    "domains": "domain names"
  },
  "nl": {
    "view": "Bekijk deze kaart",
    "organizations": "organisaties",
    "domains": "domeinen"
  }
}
</i18n>
