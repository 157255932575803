<style scoped>
li a:any-link {
  color: white;
  text-decoration: none
}

.router-link-exact-active {
  font-weight: normal !important;
  border-bottom: none;
}

</style>

<template>
  <b-container>
    <div class="title_logo" v-if="'app' in $store.state.config && $store.state.config.app.logo_image">
      <router-link to="/maps">
        <div v-html="$store.state.config.app.logo_image" class="title_logo_image"></div>
      </router-link>
    </div>
    <b-navbar toggleable="lg" type="dark" variant="dark">

      <b-navbar-brand to="/maps">
        <b-icon icon="house"/>
        {{ $t("menu.overview") }}
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse-content"></b-navbar-toggle>

      <b-collapse id="nav-collapse-content" is-nav>

        <b-navbar-nav>
          <menu-country-layer style="font-size: 1.2em" :showTitle="false" :showIfEmptyOrSingle="false"></menu-country-layer>
        </b-navbar-nav>


        <b-navbar-nav class="ml-auto">
            <time-machine />
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
  </b-container>
</template>


<script>

import {mapState} from 'vuex'
import MenuCountryLayer from "@/components/menu/MenuCountryLayer";
import TimeMachine from "@/components/TimeMachine";

export default {
  components: {TimeMachine, MenuCountryLayer},
  computed: mapState(['user']),
}

</script>
