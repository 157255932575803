<template>
  <div>

    <landing-header />

    <!-- At least make it clear you are looking at old data when the time machine is still enabled at a next visit etc -->
    <!-- <template v-if="$store.state.time_machine_date">
      <b-container>
        <time-machine class="mt-3"/>
      </b-container>
    </template> -->

    <multi_map class="pt-3"/>

    <b-container>
    <BackToTop/>
      </b-container>
  </div>
</template>

<style>
.display-3 {
  font-size: 3.7rem;
}

</style>

<script>
import multi_map from "@/components/map/MultiMapView";
import BackToTop from "@/components/BackToTop";
import LandingHeader from "@/components/landing/LandingHeader";

export default {
  name: "MultiMapLanding",
  components: {LandingHeader, BackToTop, multi_map}
}
</script>
