<template>
  <div>
    <loading-spinner v-if="charts_loading"/>

    <StatisticsSelectRange class="mb-2"/>

    <line-chart-with-subcharts :axis="axis_for_issue_graphs" :chart_data="timeline" class="mb-4" :locale="$i18n.locale"
                               :amount_of_days="parseInt($store.state.statistics_amount_of_days)"
    ></line-chart-with-subcharts>

    <b-row class="mb-4" v-if='$store.state.config.show.report_charts_network'>
      <b-col>
        <div class="chart-container" style="position: relative; height:200px; width:100%">
          <connectivity-graph :color_scheme="$store.state.color_scheme" :data="timeline" :locale="$i18n.locale"
                              :amount_of_days="parseInt($store.state.statistics_amount_of_days)"
          ></connectivity-graph>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import http from "@/httpclient";
import LineChartWithSubcharts from "@/components/charts/LineChartWithSubcharts";
import ConnectivityGraph from "@/components/charts/ConnectivityLineChart";
import StatisticsSelectRange from "@/components/StatisticsSelectRange";

export default {
  name: "ReportOrganizationChart",
  components: {StatisticsSelectRange, ConnectivityGraph, LineChartWithSubcharts},
  data: function () {
    return {
      charts_loading: false,
      timeline: [],
    }
  },
  props: {
    organization_id: {type: Number, required: true},
    days_back: {type: Number, required: false, default: 365}
  },
  mounted() {
    this.charts_loading = true;

    http.get(`/data/organization_vulnerability_timeline/${this.organization_id}/${this.days_back}/`).then(data => {
      this.timeline = data.data;
      this.charts_loading = false;
    })

  }
}
</script>
