<template>
  <div class="indent">

  <span class="second_opinion_links" v-if="second_opinion_links">
    <svg data-v-e8467c34="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="arrow repeat" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-arrow-repeat b-icon bi"><g data-v-e8467c34=""><path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"></path><path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"></path></g></svg>
    {{ $t(`${$store.state.config.grading_policy}_second_opinion`) }}:
    <a v-for="link in second_opinion_links" :key="link.url"
       :href="`${link.url.replace('${url.url}', url)}`">
       {{ link.name }}</a>
  </span><br>
  <span class="documentation_links" v-if="documentation_links">
    <svg data-v-e8467c34="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="book" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-book b-icon bi"><g data-v-e8467c34=""><path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"></path></g></svg>
    &nbsp;{{ $t("documentation") }}:
    <a v-for="link in documentation_links" :key="link.url"
       :href="link.url">{{ link.name }}</a>
  </span>
  </div>
</template>

<script>
export default {
  name: "SecondOpinionLinks",
  props: {
    rating: {type: Object, required: true},
    url: {type: String, required: true},
  },
  computed: {
    second_opinion_links() {
      if (this.$store.state.issues[this.rating.type]){
        return this.$store.state.issues[this.rating.type]['second opinion links'];
      }
      return []
    },
    documentation_links() {
      if (this.$store.state.issues[this.rating.type]){
        return this.$store.state.issues[this.rating.type]['documentation links'];
      }
      return []
    }
  }
}
</script>

<style scoped>
div {
  font-size: 0.9em;
}

span {
  padding-left: 0.4em;
}

a ~ a::before {
  content: ', ';
}
</style>
<i18n>
{
  "en": {
    "_second_opinion": "Second opinion",
    "ipv6_second_opinion": "Perform Retest",
    "documentation": "Documentation"
  },
  "nl": {
    "_second_opinion": "Second opinion",
    "ipv6_second_opinion": "Opnieuw testen",
    "documentation": "Documentatie"
  }
}
</i18n>
