<style scoped>

.container {
  color: var(--light);
  background-color: transparent !important;
}

.container a, .container a:hover, .container a:visited, .footlink {
  color: var(--light);
  text-decoration: none;

}


.container >>> .wrap hr {; height: 1px; background: var(--light); border:0 }
.container >>> .wrap ul {
  list-style: none;
}

.container >>> .wrap a {
  color: var(--light);
  text-decoration: none;
}

.container >>> .wrap ul li:first-child {
  padding-left: 0;
}

.container >>> .wrap ul li {
  display: inline-block;
  padding: .5em .5em 1em .5em;
  line-height: 1.5;
}

.container >>> .wrap ul {
  padding-left: 0;
}

.container >>> .follow-us {
  float: right;
}
</style>
<template>
  <b-container class="mt-2 mb-0">
    <template v-if="translations.footer && translations.footer.content">
      <div v-html="translations.footer.content" ></div>
    </template>

    <template v-else-if="$conf.project.name.length > 1 || $conf.project.tagline.length > 1 || $conf.project.mail.length > 1 || $conf.responsible_organization.name.length > 1 || $conf.responsible_organization.website.length > 1 || $conf.responsible_organization.mail.length > 1">
      <b-row>
        <b-col>
          <a v-if="$conf.project.name.length > 1" target="_blank"><b>{{ $conf.project.name }}</b></a><br/>
          <i v-if="$conf.project.tagline.length > 1"><a target="_blank">{{ $conf.project.tagline }}</a></i><br/>
          <b v-if="$conf.project.mail.length > 1"><a :href="'mailto:'+$conf.project.mail" target="_blank">✉️
            {{ $conf.project.mail }}</a></b>
        </b-col>

        <b-col>
          <b v-if="$conf.responsible_organization.name.length > 1">{{ $conf.responsible_organization.name }}</b><br/>
          <i v-if="$conf.responsible_organization.website.length > 1"><a
            :href="$conf.responsible_organization.website" target="_blank"
            rel="noopener">{{ $conf.responsible_organization.website }}</a></i><br/>
          <b v-if="$conf.responsible_organization.mail.length > 1"><a
            :href="'mailto:' + $conf.responsible_organization.mail" target="_blank"
            rel="noopener">✉️ {{ $conf.responsible_organization.mail }}</a></b>
        </b-col>

        <b-col>
          <br>
          <WsmBanner />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <!-- Simple banner in the center -->
      <div class="col-md-12 info mt-4" style="text-align: center;">
        <WsmBanner />
      </div>
    </template>
  </b-container>
</template>

<script>
import WsmBanner from "@/components/WsmBanner";
import TranslationsFromConfigMixin from "@/components/TranslationsFromConfigMixin";
export default {
  components: {WsmBanner},
  mixins: [TranslationsFromConfigMixin],
};
</script>
<i18n>
{
  "en": {
    "banner": "Made with Web Security Map"
  },
  "nl": {
    "banner": "Gemaakt met Web Security Map"
  }
}
</i18n>
