<style scoped>
li a:any-link {
  color: white;
  text-decoration: none
}

.router-link-exact-active {
  font-weight: normal !important;
  border-bottom: none;
}

</style>

<template>
  <b-container>
    <div class="title_logo" v-if="'app' in $store.state.config && $store.state.config.app.logo_image">
      <router-link to="/maps">
        <div v-html="$store.state.config.app.logo_image" class="title_logo_image"></div>
      </router-link>
    </div>
    <b-navbar toggleable="lg" type="dark" variant="dark">

      <b-navbar-brand to="/maps">
        <b-icon icon="compass"/>
        {{ $t("menu.maps") }}
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse-content"></b-navbar-toggle>

      <b-collapse id="nav-collapse-content" is-nav>

        <b-navbar-nav>

          <b-nav-item to="/scan-monitor" accesskey="n" exact exact-active-class="active"
                      v-if="$store.state.config.show.changes">
            <b-icon icon="newspaper"/>
            {{ $t("menu.scan-monitor") }}
          </b-nav-item>
          <b-nav-item to="/scan-schedule" accesskey="h" exact exact-active-class="active"
                      v-if="$store.state.config.show.scan_schedule">
            <b-icon icon="calendar2-date"/>
            {{ $t("menu.scan-schedule") }}
          </b-nav-item>
          <b-nav-item to="/datasets" accesskey="t" exact exact-active-class="active"
                      v-if="$store.state.config.show.datasets">
            <b-icon icon="cloud-download"/>
            {{ $t("menu.datasets") }}
          </b-nav-item>

        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

          <b-nav-item to="/responsible-disclosure" accesskey="t" exact exact-active-class="active"
                      v-if="$store.state.config.responsible_disclosure_page.show">
            <b-icon icon="mailbox"/>
            {{ $t("menu.responsible-disclosure") }}
          </b-nav-item>

          <SwitchLocale/>
          <b-nav-item to="/about" accesskey="a" exact exact-active-class="active" v-if="$store.state.config.show.intro">
            <b-icon icon="info-circle"/>
            {{ $t("menu.info") }}
          </b-nav-item>
        </b-navbar-nav>


      </b-collapse>
    </b-navbar>
  </b-container>
</template>


<script>

import {mapState} from 'vuex'
import SwitchLocale from "@/components/SwitchLocale";

export default {
  components: {SwitchLocale},
  computed: mapState(['user']),
}
</script>
